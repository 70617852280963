import { Route, Routes as Switch } from 'react-router-dom';
import DashboardLayout from '../../pages/layouts/DashboardLayout';
import Dashboard from '../../pages/Dashboard';
import { Login } from '../../pages/Login';
import NotFound from '../../pages/errors/page404';
import CustomersList from '../../pages/Customers';
import NewClient from '../../pages/Customers/new';
import Customers from '../../pages/Customers/Customers';
import CustomerDetails from '../../pages/Customers/details';
import Products from '../../pages/Products/list';
import ProductsLists from '../../pages/Products/ProductsList';
import Details from '../../pages/Products/Details';
import Appointments from '../../pages/Appointment';
import AppointmentsList from '../../pages/Appointment/List';
import NewAppointment from '../../pages/Appointment/New';
import Employees from '../../pages/Employees';
import EmployeesList from '../../pages/Employees/List';
import NewEmployee from '../../pages/Employees/New';
import EmployeeDetails from '../../pages/Employees/Details';
import Invoices from '../../pages/Invoices';
import InvoiceList from '../../pages/Invoices/List';
import NewInvoice from '../../pages/Invoices/New';
import InvoiceDetails from '../../pages/Invoices/Details';
import Finances from '../../pages/Finances';
import CheckoutList from '../../pages/Finances/Checkouts/List';
import FinanceToday from '../../pages/Finances/Today';
import Connfigurations from '../../pages/Configurations';
import Agencies from '../../pages/Configurations/Agencies';
import MyAppointments from '../../pages/Appointment/My';
import Suppliers from '../../pages/Configurations/Suppliers';
import SupplyLists from '../../pages/Products/Supply';
import OutStockLists from '../../pages/Products/OutBox';
import Coupons from '../../pages/Configurations/Coupons';
import Inventory from '../../pages/Products/Inventory';
import PrincipalCheckout from '../../pages/Finances/Checkouts/Principal';
import TransactionsList from '../../pages/Invoices/Transactions';
import PaymentsList from '../../pages/Finances/Payments';
import PendingInvoices from '../../pages/Invoices/Pending';
import AppointmentsListCalendar from '../../pages/Appointment/ListCalendar';
import Categories from '../../pages/Products/Categories';
import Places from '../../pages/Products/Places';
import Notifications from '../../pages/Notifications';
import Repports from '../../pages/Repports';
import DailyReport from '../../pages/Repports/Daily';
import GlobalReport from '../../pages/Repports/Global';
import PerfReport from '../../pages/Repports/Perf';
import Skocks from '../../pages/Stocks';
import DailyCalendarReport from '../../pages/Repports/DailyCalendar';
import GlobalCalendarReport from '../../pages/Repports/GlobalCalendar';
import PerfGlobalReport from '../../pages/Repports/GlobalPerf';
import Dashboard2 from '../../pages/Dashboard/Dashboard2';
import CustomersManagement from '../../pages/CustomersManagement';
import CustomersList2 from '../../pages/CustomersManagement/List';
import CustomersGifts from '../../pages/CustomersManagement/Gifts';
import OfferedInvoices from '../../pages/Invoices/Offered';
import IndebtedCustomers from '../../pages/CustomersManagement/EdebtedCustomers';
import PaymentGateway from '../../pages/PaymentGateway';




const Routes = () => {

    return(
        <Switch>
            {/* <Route path="/" element={<Home />}/>
            <Route path="/shop" element={<Shop />}/>
            <Route path="/cart" element={<Cart />}/> */}
            <Route  path='/' element={<DashboardLayout />}>
                <Route path='' index element={<Dashboard2/>}/>
                <Route path='ppp' index element={<Dashboard/>}/>
                <Route path='customers' element={<Customers/>}>
                    <Route path='list' element={<CustomersList/>}/>
                    <Route path='add' element={<NewClient/>}/>
                    <Route path=':customerId' element={<CustomerDetails/>}/>
                </Route>
                <Route path='products' element={<Products />}>
                    <Route path='list' element={<ProductsLists type={"PRODUCTS"}/>}/>
                    <Route path='supply' element={<SupplyLists type={"PRODUCTS"}/>}/>
                    <Route path='clearance' element={<OutStockLists type={"PRODUCTS"}/>}/>
                    <Route path='add' element={<NewClient/>}/>
                    <Route path='inventory' element={<Inventory/>}/>
                    <Route path='places' element={<Places/>}/>
                    <Route path='categories' element={<Categories/>}/>
                    <Route path=':productId' element={<Details/>}/>
                </Route>
                <Route path='services' element={<Products />}>
                    <Route path='list' element={<ProductsLists type={"SERVICES"}/>}/>
                    <Route path='add' element={<NewClient/>}/>
                    <Route path=':productId' element={<Details/>}/>
                </Route>
                <Route path='appointments' element={<Appointments />}>
                    <Route path='list' element={<AppointmentsList type={"SERVICES"}/>}/>
                    <Route path='list/calendar' element={<AppointmentsListCalendar type={"SERVICES"}/>}/>
                    <Route path='add' element={<NewAppointment/>}/>
                    <Route path='my' element={<MyAppointments/>}/>
                    <Route path=':appointmentId' element={<Details/>}/>
                </Route>
                <Route path='employees' element={<Employees />}>
                    <Route path='list' element={<EmployeesList/>}/>
                    <Route path='add' element={<NewEmployee/>}/>
                    <Route path=':employeeId' element={<EmployeeDetails/>}/>
                </Route>
                <Route path='invoices' element={<Invoices />}>
                    <Route path='list' element={<InvoiceList/>}/>
                    <Route path='add' element={<NewInvoice/>}/>
                    <Route path='gifts' element={<OfferedInvoices/>}/>
                    <Route path='pending' element={<PendingInvoices/>}/>
                    <Route path=':invoiceId' element={<InvoiceDetails/>}/>
                </Route>
                <Route path='finances' element={<Finances />}>
                    <Route path='checkouts' element={<CheckoutList />} />
                    <Route path='checkout' element={<FinanceToday />} />
                    <Route path='list' element={<InvoiceList/>}/>
                    <Route path='expenses' element={<TransactionsList sortie_caisse={true} />}/>
                    <Route path='transactions' element={<TransactionsList/>}/>
                    <Route path='add' element={<NewInvoice/>}/>
                    <Route path='daily' element={<FinanceToday/>}/>
                    <Route path='payments' element={<PaymentsList/>}/>
                    <Route path=':invoiceId' element={<InvoiceDetails/>}/>
                </Route>
                <Route path='configurations' element={<Connfigurations />}>
                    <Route path='agencies' element={<Agencies />} />
                    <Route path='suppliers' element={<Suppliers />} />
                    <Route path='coupons' element={<Coupons />} />
                </Route>
                <Route path='customers-management' element={<CustomersManagement />}>
                    <Route path='list' element={<CustomersList2 />} />
                    <Route path='gifts' element={<CustomersGifts />} />
                    <Route path='indebted-customers' element={<IndebtedCustomers />} />
                </Route>
                <Route path='repports' element={<Repports />}>
                    <Route path='daily' element={<DailyReport />} />
                    <Route path='perf-global' element={<PerfGlobalReport />} />
                    <Route path='daily/calendar' element={<DailyCalendarReport />} />
                    <Route path='global/calendar' element={<GlobalCalendarReport />} />
                    <Route path='global' element={<GlobalReport />} />
                    <Route path='perf' element={<PerfReport />} />
                </Route>
                <Route path='/notifications' element={<Notifications/>}/>
                <Route path='/stocks' element={<Skocks/>}/>
            </Route>
            <Route path='/login' element={<Login/>}/>
            <Route path='/customer/payment/:sigle' element={<PaymentGateway/>}/>
            <Route path='*' element={<NotFound/>}/>
        </Switch>
    )
}

export default Routes;