import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../services/urls';
import useSWR from 'swr';
import LoaderPage from '../../components/LoaderPage';
import moment from 'moment';
import NewAppointmentForm from '../Appointment/components/NewAppointmentForm';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewInvoiceForm from '../Invoices/components/NewInvoiceForm';
import DepositForm from '../Finances/Checkouts/DepositForm';
import { useSelector } from 'react-redux';
import LatestInvoices from './components/LatestInvoices';
import Avatar from 'react-avatar';

const CustomerDetails = ({ customerId2, hideCreationForms, handleEdit, handleDeleteCustomer, hadleSetSelectedCustomer }) => {
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const { customerId } = useParams();
  const { active_agency } = useSelector((state) => state.agencies);
  const {
    data: customer,
    isLoading,
    mutate,
  } = useSWR(URLS.CUSTOMERS.details(customerId ?? customerId2));


  const {
    data: transactions,
    isLoading: loadingTransactions,
    mutate: mutateTransactions,
  } = useSWR(
    URLS.TRANSFERT.list(active_agency?.id, '', page, `&wallet__id=${customer?.wallet?.id ?? ''}`)
  );

  const { t } = useTranslation();

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();

    setToEdit();
    mutateTransactions();
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  // const handleEdit = (obj) => {
  //   setToEdit(obj);
  //   window.$('.new-appointment-modal')?.modal('show');
  // };

  const handleDeposit = (obj) => {
    window.$('.new-deposit-modal')?.modal('show');
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.AP.delete(obj.id), t, mutate);
  };

  const hasRigths = true;

  useEffect(() => {
  //   window.$(".new-invoice-modal").on('show.bs.modal', function (e) {
  //     console.log("lllll")
  //     window.$(".customer-details-modal").modal("hide");
  // });

  // console.log("customer -------------> ", customer);
  if (customer && hadleSetSelectedCustomer) {
    hadleSetSelectedCustomer(customer);
  }

  }, [customer]);

  return (
    <div className="row">
      {isLoading && <LoaderPage />}
      <div className="col-xl-12">
        <div className="card overflow-hidden">
          <div className="card-body">
            <div className="">
              <div className="row">
                <div className="col-md-2 col-12">
                  <div>
                    <div className="text-center">
                      <Avatar size='50' textSizeRatio={5} name={`${customer?.first_name} ${customer?.last_name}`} round />
                      {/* <img
                        src="/assets/images/avatar.jpg"
                        alt=""
                        className="avatar-sm rounded-circle img-thumbnail"
                      /> */}
                      <h5 className="mt-3 mb-1">
                        {customer?.first_name} {customer?.last_name}
                      </h5>
                      <p className="text-muted">{customer?.reference}</p>
                      <hr />
                <div className="button-items  d-inline-flex justify-content-center">
                  <button
                    className="d-flex btn btn-info rounded-0 mb-2 text-uppercase"
                    data-bs-toggle="modal"
                    data-bs-target=".new-client-modal"
                    onClick={() => handleEdit(customer)}
                  >
                    Modifier
                  </button>
                  <button
                    className="d-flex btn btn-danger rounded-0 mb-2 text-uppercase"
                    onClick={handleDeleteCustomer}
                  >
                  Supprimer
                  </button>
                </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 p-0">
                  <div>
                    <div class="table-responsive">
                      <table class="table table-bordered mb-0">
                          <tbody>
                              <tr>
                                  <td><span className="">Contact:</span></td>
                                  <td><strong>{customer?.phone_number}</strong></td>
                              </tr>
                              <tr>
                                  <td><span className="">Adresse Mail:</span></td>
                                  <td><strong>{customer?.email}</strong></td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-5 p-0">
                  <div>
                  <div class="table-responsive">
                      <table class="table table-bordered mb-0">
                          <tbody>
                              <tr>
                                  <td><span className="">Date de Naissance:</span></td>
                                  <td> <strong>{moment(customer?.birth_date).format('DD/MM/YYYY')}</strong></td>
                              </tr>
                              <tr>
                                  <td><span className="">Compte Créé Le:</span></td>
                                  <td><strong> {moment(customer?.created_at).format('DD/MM/YYYY')} <span className='ms-3'>{moment(customer?.created_at).format('HH:MM')}</span></strong></td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="text-muted mb-1">
                It will be as simple as occidental in fact, it will be Occidental. To an English
                person, it will seem like simplified English, as a skeptical Cambridge friend of
                mine told me what Occidental is. The European languages are members of the same
                family. Their separate existence is a myth. For science, music, sport, etc, Europe
                uses the same vocabulary. The languages only differ in their grammar, their
                pronunciation and their most common words.
              </p> */}

              {/* <div>
                <button type="button" className="btn btn-link link-primary p-0">
                  Read more
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-12">
            <div>
              <div className={`card mb-4 border-1 wallet-card`}>
                <Link className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-md flex-shrink-0">
                      <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                        <span className="uim-svg">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                            <path
                              className="uim-quaternary"
                              d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                            ></path>
                            <path
                              className="uim-tertiary"
                              d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                            ></path>
                            <path
                              className="uim-primary"
                              d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-4">
                      <p className="text-muted text-truncate font-size-15 mb-2">
                        Nombre de prestations
                      </p>
                      <h3 className="fs-4 flex-grow-1 mb-3">{customer?.realized_count}</h3>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div>
              <div className={`card mb-4 border-1 wallet-card`}>
                <Link className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="avatar-md flex-shrink-0">
                      <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                        <span className="uim-svg">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                            <path
                              className="uim-quaternary"
                              d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                            ></path>
                            <path
                              className="uim-tertiary"
                              d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                            ></path>
                            <path
                              className="uim-primary"
                              d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                            ></path>
                          </svg>
                        </span>
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden ms-4">
                      <p className="text-muted text-truncate font-size-15 mb-2"> Solde en compte</p>
                      <h3 className="fs-4 flex-grow-1 mb-3">
                        {customer?.wallet?.amount}{' '}
                        <span className="text-muted font-size-16">FCFA</span>
                      </h3>
                      {/* <p className="text-muted mb-0 text-truncate">
                              <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                                <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                              </span>{' '}
                              vs last month
                            </p> */}
                    </div>
                    <div className="flex-shrink-0 align-self-start">
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle btn-icon border rounded-circle"
                          href="#"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="ri-more-2-fill text-muted font-size-16"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <Link
                            className="dropdown-item"
                            // data-bs-toggle="modal"
                            // data-bs-target=".new-deposit-modal"
                            to="#"
                            onClick={() => handleDeposit(customer?.wallet)}
                          >
                            Approvisionner le compte
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div className="">
              <LatestInvoices customer={customer} />
            </div>
          </div>
          <div className="col-md-4 col-12">
            <div>
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column">
                  <button
                  className="d-flex btn btn-primary m-auto rounded-0 mb-2 text-uppercase"
                  data-bs-toggle="modal"
                  data-bs-target=".new-invoice-modal"
                  
                  // data-bs-dismiss="customer-details-modal"
                >
                  Facturation directe
                </button>
                <button
                  className="d-flex btn btn-primary m-auto rounded-0 mb-2 text-uppercase"
                  data-bs-toggle="modal"
                  data-bs-target=".new-appointment-modal"
                >
                  Prise de rendez-vous
                </button>
                
                {!hideCreationForms && <div
                  className="modal fade new-invoice-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel2"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel2">
                          {toEdit ? `Facture ${toEdit?.reference}` : 'Nouvelle facture ' + customer?.reference}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewInvoiceForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                          customerId2={customerId2}
                        />
                      </div>
                    </div>
                  </div>
                </div>}
                  </div>
                  {!hideCreationForms && <div
                  className="modal fade new-appointment-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit ? 'Modifitation du rendez-vous ' : 'Nouvelle Prise de RDV Pour le Client ' + customer?.first_name + " " + + customer?.last_name}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewAppointmentForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                          customerId2={customerId2}
                        />
                      </div>
                    </div>
                  </div>
                </div>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card p-3">
          <div className="card-body">
            <h4>Historique des transactions</h4>
            <div className="pt-2">
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th style={{ width: '20px' }}>
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="customCheck1" />
                          <label className="form-check-label" for="customCheck1">
                            &nbsp;
                          </label>
                        </div>
                      </th>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Montant</th>
                      <th>Motif</th>
                      <th>Bénéficiaire</th>
                      {/* <th>Mode de paiement</th> *
                      <th>Date</th>
                      <th>Statut</th>
                      {/* <th>Action</th> *
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.results?.map((transfert) => (
                      <tr key={'tr-' + transfert.id}>
                        <td>
                          <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck2" />
                            <label className="form-check-label" for="customCheck2">
                              &nbsp;
                            </label>
                          </div>
                        </td>
                        <td>
                          <a href="javascript: void(0);" className="text-body">
                            #{transfert.reference}
                          </a>{' '}
                        </td>
                        <td>
                          {transfert?.type === 'DEPOSIT' ? (
                            <span className="badge rounded badge-soft-success font-size-12">
                              Crédit
                            </span>
                          ) : (
                            <span className="badge rounded badge-soft-danger font-size-12">
                              Sortie
                            </span>
                          )}
                        </td>
                        <td>
                          <p className="mb-0">
                            {transfert?.amount + parseFloat(transfert.trannsport_amount ?? '0')}{' '}
                            FCFA
                          </p>
                        </td>
                        <td>{String(transfert.reason).replaceAll('_', ' ')}</td>
                        <td>{`${customer?.first_name} ${customer?.last_name}`}</td>
                        {/* <td>{transfert.payment_method}</td> *
                        <td>{moment(transfert?.created_at).format('lll')}</td>
                        {/* <td>
                            <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                          </td> *
                        <td>
                          <span className="badge rounded badge-soft-success font-size-12">
                            Complété
                          </span>
                        </td>
                        {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> *
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div>
                {transactions?.count > 0 && (
                  <div className="paginations pt-3">
                    {/* <nav aria-label="...">
                                                    <ul class="pagination pagination-rounded">
                                                        <li class="page-item disabled">
                                                            <span class="page-link"><i class="mdi mdi-chevron-left"></i></span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item active">
                                                            <span class="page-link">
                                                                2
                                                                <span class="sr-only">(current)</span>
                                                            </span>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#"><i class="mdi mdi-chevron-right"></i></a>
                                                        </li>
                                                    </ul>
                                                </nav> *
                    <ul className="pagination pagination-rounded">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                      {new Array(Math.ceil(transactions.count / DEFAULT_PAGE_COUNT))
                        .fill(3)
                        .map((pag, index) => (
                          <li
                            key={'page' + index}
                            className={`page-item ${page === index + 1 ? 'active' : ''}`}
                            aria-current="page"
                          >
                            <Link
                              className={`pager-number ${
                                page * DEFAULT_PAGE_COUNT === Math.ceil(transactions.count)
                                  ? 'disabled'
                                  : ''
                              } ${page === index + 1 ? 'active' : ''}`}
                              to="#"
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, index + 1)}
                            >
                              <span className="page-link">{index + 1}</span>
                            </Link>
                          </li>
                        ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="col-xl-4">
        
        {/*<div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">10 Dernières Prestations</h5>
          </div>
          <div className="card-body pt-2">
            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <tbody>
                  {customer?.latest_prestations?.map((presta) => (
                    <tr key={presta.id}>
                      <td style={{ width: '50px' }}>
                        <img
                          src="/assets/images/invoice-icon.jpg"
                          className="rounded-circle avatar-sm"
                          alt=""
                        />
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          <Link to={`/invoices/${presta.id}`} className="text-dark">
                            {presta.reference}
                          </Link>
                          <span>{presta.amount}</span>
                        </h5>
                      </td>
                      <td>
                        <div>
                          <Link
                            href="#"
                            className={`badge bg-${
                              presta.status === 'SHEDULED'
                                ? 'warning'
                                : presta.status === 'PENDING'
                                ? 'warning'
                                : presta.status === 'CANCELLED'
                                ? 'danger'
                                : presta.status === 'MISSED'
                                ? 'danger'
                                : 'success'
                            } font-size-11`}
                          >
                            {presta.status}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <Link to={`/invoices/${presta.id}`} className="btn btn-warning btn-sm">
                          <i className="mdi mdi-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="py-4 d-flex justify-content-center">
                <button
                  className="d-flex btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target=".new-invoice-modal"
                >
                  Facturation directe
                </button>
                
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Derniers rendez-vous</h5>
          </div>
          <div className="card-body pt-2">
            <div className="table-responsive">
              <table className="table align-middle table-nowrap mb-0">
                <tbody>
                  {customer?.latest_appointments?.map((presta) => (
                    <tr key={presta.id}>
                      <td style={{ width: '50px' }}>
                        <img
                          src="/assets/images/invoice-icon.jpg"
                          className="rounded-circle avatar-sm"
                          alt=""
                        />
                      </td>
                      <td>
                        <h5 className="font-size-14 m-0">
                          <Link to="#" className="text-dark">
                            {moment(presta.start).format('lll')}
                          </Link>
                        </h5>
                      </td>
                      <td>
                        <div>
                          <Link
                            href="#"
                            className={`badge bg-${
                              presta.status === 'SHEDULED'
                                ? 'warning'
                                : presta.status === 'PENDING'
                                ? 'warning'
                                : presta.status === 'CANCELLED'
                                ? 'danger'
                                : presta.status === 'MISSED'
                                ? 'danger'
                                : 'success'
                            } font-size-11`}
                          >
                            {presta.status}
                          </Link>
                        </div>
                      </td>
                      <td>
                        <Link
                          to={`#`}
                          onClick={() => handleEdit(presta)}
                          className="btn btn-warning btn-sm"
                        >
                          <i className="mdi mdi-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="py-4 d-flex justify-content-center">
                <button
                  className="d-flex btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target=".new-appointment-modal"
                >
                  Prise de RDV
                </button>
                
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Attachments</h5>
          </div>
          <div className="card-body pt-1">
            <div className="border-bottom px-2 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-secondary rounded font-size-20">
                      <i className="ri-folder-zip-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 mb-1">
                    <a href="#" className="text-body text-truncate d-block">
                      App-pages.zip
                    </a>
                  </h5>
                  <div className="text-muted">2.2MB</div>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <button type="button" className="btn btn-icon text-muted btn-sm font-size-18">
                    <i className="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="border-bottom px-2 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-secondary rounded font-size-20">
                      <i className="ri-file-ppt-2-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 mb-1">
                    <a href="#" className="text-body text-truncate d-block">
                      Velzon-admin.ppt
                    </a>
                  </h5>
                  <div className="text-muted">2.4MB</div>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <button type="button" className="btn btn-icon text-muted btn-sm font-size-18">
                    <i className="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="border-bottom px-2 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-secondary rounded font-size-20">
                      <i className="ri-folder-zip-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 mb-1">
                    <a href="#" className="text-body text-truncate d-block">
                      Images.zip
                    </a>
                  </h5>
                  <div className="text-muted">1.2MB</div>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <button type="button" className="btn btn-icon text-muted btn-sm font-size-18">
                    <i className="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="border-bottom px-2 py-3">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light text-secondary rounded font-size-20">
                      <i className="ri-image-2-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 mb-1">
                    <a href="#" className="text-body text-truncate d-block">
                      bg-pattern.png
                    </a>
                  </h5>
                  <div className="text-muted">1.1MB</div>
                </div>
                <div className="flex-shrink-0 ms-2">
                  <button type="button" className="btn btn-icon text-muted btn-sm font-size-18">
                    <i className="ri-download-2-line"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {!hideCreationForms && <div
      // {!hideCreationForms && <div
        className="modal fade new-deposit-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myLargeModalLabel2">
                {'Approvisionnement du compte client'}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {customer && <DepositForm
                handleClose={handleClose}
                onSuccess={handleSuccess}
                wallet={customer?.wallet}
              />}
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default CustomerDetails;
