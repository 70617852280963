
import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DEFAULT_PAGE_COUNT } from '../../../components/utils/consts';
import { Link } from 'react-router-dom';
import NewInvoiceForm from '../../Invoices/components/NewInvoiceForm';




const LatestInvoices = ({customer}) => {

    const [toEdit, setToEdit] = useState();
    const [wallet, setWallet] = useState();
    const [page, setPage] = useState(1);

    const { active_agency } = useSelector((state) => state.agencies);
    // const { data: invoices, isLoading, mutate } = useSWR(URLS.INVOICES.customer(customer?.id, '', page, `&limit=10`));
    const { data: invoices, isLoading, mutate } = useSWR(URLS.SEARCH.invoicesByCustomer(customer?.id));

    const handleChangePage = (event, pg) => {
      event.preventDefault();
      setPage(pg);
    };
    
    const handleSuccess = () => {
      setToEdit();
      mutate();
  
      setToEdit();
    };
    
    const handleClose = () => {
      window.$('.btn-close')?.click();
    };
  
    const handleEdit = (obj) => {
      setToEdit(obj);
      window.$('.new-recent-modal')?.modal('show');
    };
  
    const handleDeposit = (obj) => {
      window.$('.new-recent-modal')?.modal('show');
    };

    return (
        <div className="card p-3">
              <div className="card-body">
                <div className="d-flex">

                <h4>Dix (10) Dernières Factures</h4>
                <div className=" d-flex ms-auto justify-content-center">
                {/* <button
                  className="d-flex btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target=".new-recent-invoice-modal"
                >
                  Facturation directe
                </button> */}
                <div
                  className="modal fade new-recent-invoice-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabelLatestInvoices"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabelLatestInvoices">
                          {toEdit ? `Facture ${toEdit?.reference}` : 'Nouvelle facture'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewInvoiceForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                          productType="ALL"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </div>
                <div className="pt-2">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheck1"
                              />
                              <label className="form-check-label" for="customCheck1">
                                &nbsp;
                              </label>
                            </div>
                          </th>
              
                          <th>Code Facture</th>
                          <th>Montant</th>
                          <th>Mode de règlement</th>
                          <th>Statut</th>
                          {/* {<th>Désignation</th>} */}
                          {/* <th>Mode de paiement</th> */}
                          <th>Date de création</th>
                          {/* <th>Actions</th> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {invoices?.results?.map((transfert) => (
                          <tr key={'tr-' + transfert.id}>
                            <td>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheck2"
                                />
                                <label className="form-check-label" for="customCheck2">
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>
                              <Link to={`/invoices/${transfert.id}`} className="text-body">
                                {transfert.reference}
                              </Link>{' '}
                            </td>
                            <td>
                              {
                                <span className="badge rounded badge-soft-success font-size-12">
                                  {transfert.amount + " FCFA" ?? '0'}
                                </span>}
                            </td>
                            <td>
                              <p className="mb-0">
                              {/* {String(transfert.payment_method).replace("_", " ")} */}
                              {
                                transfert?.payments
                              }
                              </p>
                            </td>
                            <td><span className={`badge badge-soft-${transfert.status === "PENDING" ? 'warning' : transfert.status === "COMPLETED" ? 'success' : 'danger'} p-2`}>{transfert.status === "PENDING" ? 'En attente' : transfert.status === "COMPLETED" ? 'Payée' : 'Annulée'}</span></td>
                            {/* <td>
                              {transfert.order?.items?.map((itm) => itm?.item?.title + ", ")}
                            </td> */}
                            {/* <td>{transfert.payment_method}</td> */}
                            <td>
                            <> {moment(transfert?.created_at).format('DD/MM/YYYY')} <span className='ms-3'>{moment(transfert?.created_at).format('HH:MM')}</span></>
                              </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    {/* {invoices?.count > 0 && (
                      <div className="paginations pt-3">
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(invoices.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(invoices.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
    )
}

export default LatestInvoices;