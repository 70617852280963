import React, { useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import ManageStockForm from './components/ManageStockForm';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import { formatDate, formatNumber, sumArray } from '../../components/utils/functions';
import BonDeSortie from './components/BonDeSortie';
import _ from 'lodash';
import { DatePicker } from 'react-responsive-calendar-picker';
import SupplyTicket from './components/SupplyTocket';
import PaginationComponent from '../../components/Pagination';

const SellsHistory = ({ type, title, isSelledHistory }) => {
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [open, setOpen] = useState(false);
  const [toEditStock, setToEditStock] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });
  const [page, setPage] = useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.STOCKS.lists(
      active_agency?.id,
      search,
      page,
      `OUT${isSelledHistory ? '&for_sale=true' : ''}`,
      dates?.checkout
        ? `&created_at__gte=${formatDate(dates.checkin) + 'T00:00'}&created_at__lte=${
            formatDate(dates.checkout) + 'T00:00'
          }`
        : `&created_at=${formatDate(dates.checkin) + 'T00:00'}`
    )
  );

  const { t } = useTranslation();

  const handleShow = (item, obj) => {
    let toShow = {};
    toShow[item] = obj;
    setToEdit(toShow);
  };

  const handleChangePage = (event, page) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="=">
          <div className="card-body">
            <div className="row justify-content-between flex-row">
              <div className="col-md-6">
                <div>
                  <p className="text-uppercas h4">{title ?? 'Sorties de stock'}</p>
                  {/* <h3 className="text-uppercase">Historique des sorties de stock </h3> */}
                  {/* <p>Les sorties de stock representent les produits sortis pour utilisation.</p> */}
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  {!isSelledHistory && (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light rounded-0 px-3"
                      data-bs-toggle="modal"
                      data-bs-target=".new-stock-modal-out"
                    >
                      {' '}
                      + Déstockage
                    </button>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Ref produit, Designation, etc ..."
                        />
                      </div>
                    </div>
                    <div className="col-auto">
                      <div>
                        <div className=" position-relative" style={{ zIndex: '1' }}>
                          <DatePicker
                            dates={dates}
                            setDates={setDates}
                            open={open}
                            setOpen={setOpen}
                            normal={true}
                          >
                            <button
                              className="btn btn-primary rounded-"
                              onClick={() => setOpen(!open)}
                            >
                              Date du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                              {dates.checkout && 'au'}{' '}
                              {dates.checkout && dates.checkout.toLocaleDateString()}
                            </button>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            {!isSelledHistory && (
              <div
                className="modal fade new-stock-modal-out"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="myLargeModalLabel">
                        {'Nouvelle sortie de stock'}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <ManageStockForm
                        handleClose={handleClose}
                        onSuccess={handleSuccess}
                        toEdit={toEditStock}
                        side={'OUT'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div>
                <div
                  className="modal fade sell-ticket-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby={'ticketSell'}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id={'ticketSell'}>
                          {'Détails de la vente'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <SupplyTicket
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          invoice={toEdit}
                          isSelledHistory={true}
                          side="IN"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-0" />
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      {/* <th className="text-uppercas">Référence</th> */}
                      <th className="text-uppercas">Date </th>
                      <th className="text-uppercas">Désignation</th>
                      {type !== 'SERVICES' ? (
                        <>
                          <th className="text-uppercas">
                            {isSelledHistory ? 'Qtés vendues' : 'Quantité'}
                          </th>
                          <th className="text-uppercas">{isSelledHistory ? 'CMUP' : 'CMUP'}</th>
                          <th className="text-uppercas">
                            {isSelledHistory ? 'Montant Total' : 'Prix de vente'}
                          </th>
                          <th className="text-uppercas">Marge</th>
                          {/* <th className="text-uppercas">Stock</th> */}
                          {/* <th className="text-uppercas">Emplacement</th> */}
                        </>
                      ) : (
                        <>
                          <th className="text-uppercas">Prix</th>
                        </>
                      )}
                      <th className="text-uppercas">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {
                        JSON.stringify(_.groupBy(products?.results ?? [], 'designation' ))
                    } */}
                    {Object.keys(_.groupBy(products?.results ?? [], 'designation'))?.map(
                      (item, index) => (
                        <tr key={'itmo-' + index}>
                          <td>
                            {moment(
                              _.groupBy(products?.results ?? [], 'designation')[item][0]?.created_at
                            )?.format('DD/MM/YYYY')}{' '}
                            <span className="ms-3">
                              {moment(
                                _.groupBy(products?.results ?? [], 'designation')[item][0]
                                  ?.created_at
                              ).format('HH:MM')}
                            </span>{' '}
                          </td>
                          <td>{item}</td>
                          <td>
                            <span className="badge badge-soft-success">
                              {sumArray(
                                _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                  (i) => i.quantity
                                )
                              )}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-success">
                              {formatNumber(
                                Number(
                                  sumArray(
                                    _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.buy_price
                                    )
                                  ) *
                                    sumArray(
                                      _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                        (i) => i.quantity
                                      )
                                    )
                                ).toFixed(2)
                              )}{' '}
                              FCFA
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-success">
                              {formatNumber(
                                Number(
                                  sumArray(
                                    _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.sell_price
                                    )
                                  ) *
                                    sumArray(
                                      _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                        (i) => i.quantity
                                      )
                                    )
                                ).toFixed(2)
                              )}{' '}
                              FCFA{' '}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-success">
                              
                              {formatNumber(
                                Number(
                                  (Number(_.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                    (i) => i.sell_price
                                  )) -
                                  Number(_.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.buy_price
                                    ))) *
                                    _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.quantity
                                    )
                                ).toFixed(2)
                              )}{' '}
                              FCFA{' '}
                            </span>
                          </td>
                          <td>
                            <div className="d-flex gap-2 justify-content-center">
                              <Link
                                to="#"
                                //   onClick={() => setSelectedInvoice(item)}
                                onClick={() =>
                                  handleShow(
                                    item,
                                    _.groupBy(products?.results ?? [], 'designation')[item]
                                  )
                                }
                                title="Imprimer"
                                data-bs-toggle="modal"
                                data-bs-target=".sell-ticket-modal"
                                className="btn btn-primary btn-sm btn-colapsed"
                              >
                                <i className="ri-printer-line"></i>
                                <span>Imprimer</span>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div>
                  <div
                    className="modal fade bon-sortie-modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeInvoicdseModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="myLargeInvoicdseModalLabel">
                            {'Facture ' + selectedInvoice?.reference}
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <BonDeSortie invoice={selectedInvoice} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>{isLoading && <LoaderPage />}</div>
              </div>
              <div className="d-flex">
                <div className="ms-auto">
                  <div>
                  <div className="pt-3">
                  {
                    products?.count > 0 && (
                      <PaginationComponent currentPage={page} handlePageChange={handleChangePage} totalPages={Math.ceil(products.count / DEFAULT_PAGE_COUNT) ?? 0} />
                    )
                  }
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellsHistory;
