import * as React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import NewAppointmentForm from './components/NewAppointmentForm';
import _ from 'lodash';
import AppointmentsListDate from './components/AppointmentsList';
import moment from 'moment';
import { truncate } from 'fs';
import PageTitle from '../../components/PageTitle';

const AppointmentsListCalendar = () => {
  const [toEdit, setToEdit] = React.useState();
  const [showCalendar, setShowCalendar] = React.useState(true);
  const [search, setSearch] = React.useState('');
  const [unnique, setUnique] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState('');
  const [page, setPage] = React.useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: appointments,
    isLoading,
    mutate,
  } = useSWR(URLS.APPOINTMENTS.list(active_agency?.id, search, page, '&limit=1000'));

  const navigate = useNavigate();

  const calendarRef = React.useRef();

  const handleNext = () => {
    calendarRef.current.getApi().next(); // Call the 'next' method to go to the next view
  };

  const handlePrev = () => {
    calendarRef.current.getApi().prev(); // Call the 'prev' method to go to the previous view
  };


  const handleTakeAppointment = () => {
    window.$('#newAppointmentMOdal')?.modal('show');
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
    setUnique(_.uniqueId());
    console.log(_.uniqueId());
  };

  const handleSuccess = (appointment) => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj, toedit) => {
    const { id } = obj?.event;
    const edit = toedit ??  _.filter(appointments?.results, { id: id })[0];
    if (edit) {
      setToEdit(edit);
      window.$('#newAppointmentDetailModal')?.modal('hide');
      window.$('#newAppointmentMOdal')?.modal('show');
    }
  };


  const handleChangeDate = (date) => {

  }

  const handleShowAppointments = (date) => {
    console.log(date);
    setSelectedDate(date);
    window.$('#newAppointmentDetailModal')?.modal('show');
    
  }

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i className="ms-1">{eventInfo.event.title}</i>
      </>
    );
  };
  const handleScroll = () => {
    alert("PP")
    const calendarApi = calendarRef.current.getApi();
    const height = calendarApi.el.offsetHeight;
    const scrollHeight = calendarApi.el.scrollHeight;
    const scrollTop = calendarApi.el.scrollTop;

    // Check if user has scrolled to the bottom of the calendar
    if (scrollTop + height === scrollHeight) {
      // Change the date view to the next week
      calendarApi.next();
    }
  };

  // React.useEffect(() => {
  //   const calendarApi = calendarRef.current.getApi();
  //   const scrollListener = (event) => {
  //     const scrollDirection = event.deltaY > 0 ? 'next' : 'prev';
  //     calendarApi[scrollDirection]();
  //   };

  //   const element = calendarApi.el;
  //   element.addEventListener('wheel', scrollListener);

  //   return () => {
  //     element.removeEventListener('wheel', scrollListener);
  //   };
  // }, []);

  return (
    <div className="row">
      
      <div className="col-md-6">
      <PageTitle
          title={'Calendrier des RDV'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/appointments/list">Planning RDV</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Planning
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
          <div className="me-auto">
                {!showCalendar ? <button onClick={() => navigate("/appointments/list/calendar")} className="btn btn-primary waves-effect waves-light ">Voir le Calendrier</button>
                : <button onClick={() => navigate("/appointments/list")} className="btn btn-primary waves-effect waves-light mb-3">Voir la Liste</button>}
              </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body">
          <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">Calendrier des RDV </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                
              </div>
            </div>
          </div>
          
          <div className="p-3">
            {/* <div className="d-flex">
              <div className="me-auto">
                {!showCalendar ? <button onClick={() => navigate("/appointments/list/calendar")} className="btn rounded-0 btn-primary waves-effect waves-light ">Voir le Calendrier</button>
                : <button onClick={() => navigate("/appointments/list")} className="btn btn-primary rounded-0 waves-effect waves-light mb-3">Voir la Liste</button>}
              </div>
            </div> */}
            <div className='app-calendar-wrappe'>
              {
                showCalendar && 
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  right: '',
                  center: 'title,prev,next',
                  // right: 'dayGridMonth,timeGridWeek,timeGridDay',
                  left: "today"
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                buttonText={
                  {
                    today: "Aujourd'hui"
                  }
                }
                
                eventContent={renderEventContent}
                events={appointments?.results?.map((evnt) => {
                  return {
                    // title: `${evnt?.order?.customer?.first_name} ${evnt?.order?.customer?.last_name}`,
                    title:"",
                    date: evnt.start,
                    classNames: [
                      evnt.status === 'SCHEDULED'
                        ? 'bg-warning m-0'
                        : evnt.status === 'PENDING'
                        ? 'bg-info m-0'
                        : evnt.status === 'COMPLETED'
                        ? 'bg-success m-0'
                        : 'bg-danger m-0',
                    ],
                    id: evnt.id,
                    display: 'background',
                  };
                })}
                
                eventClick={handleEdit}
                dateClick={handleShowAppointments}
                ref={calendarRef}
                
                // eventChange={}
              />
              }
            </div>
          </div>
          <div className="p-3">
            <div className="d-flex">
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-warning d-inline-block me-2`}></span>
                <span className="">Rendez-vous pris</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-success d-inline-block me-2`}></span>
                <span className="">Rendez-vous terminé</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-info d-inline-block me-2`}></span>
                <span className="">Prestation en cours d'exécution</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-danger d-inline-block me-2`}></span>
                <span className="">Rendez-vous annulé ou manqué</span>
              </div>
            </div>
          </div>
          <div
            className="modal fade new-appointment-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="newAppointmentMOdal"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="myLargeModalLabel">
                    {toEdit
                      ? 'Modifitation du rendez-vous <<' +
                        (toEdit?.order?.customer?.first_name ?? '') +
                        ' ' +
                        (toEdit?.order?.customer?.last_name ?? '') +
                        '>>'
                      : 'Nouveau rendez-vous'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <NewAppointmentForm
                    choisirCustomer={false}
                    handleClose={handleClose}
                    onSuccess={handleSuccess}
                    toEdit={toEdit}
                    customerId2={toEdit?.order?.customer?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Appointment for date */}
          <div
            className="modal fade app-details-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="appointmentsLabel"
            aria-hidden="true"
            id="newAppointmentDetailModal"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-uppercase" id="appointmentsLabel">
                    {'Liste des rendez-vous du ' } {moment(selectedDate?.dateStr ?? '').format("YYYY-MM-DD")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <AppointmentsListDate selectedDate={selectedDate} handleEdit={handleEdit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsListCalendar;
