import Appointments from "./components/Appointments";
import LatestTransactions from "./components/LatestTransactions";
import SellsStat from "./components/Sells";
import SellsByAgency from "./components/SellsByAgencies";
import CaCard from "./components/cards/CaCard";
import ClientsCard from "./components/cards/ClientsCard";
import PrestatonsCard from "./components/cards/PrestationsCard";
import ProductsCard from "./components/cards/ProductsCard";


const Dashboard2 = () => {


    return(
        <div>
            <div className="row">
                <div className="col-md-8 mb-3">
                    <div className="row">
                        <ClientsCard />
                        <CaCard />
                        <ProductsCard />
                        <PrestatonsCard />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div>
                        <SellsByAgency />
                    </div>
                </div>
                <div className="col-md-8 mb-3">
                    <div className="c-card p-0"> 
                        <LatestTransactions />
                    </div>
                </div>
                <div className="col-md-4 mb-3">
                    <div>
                        <Appointments />
                    </div>
                </div>
                <div className="col-12">
                    <SellsStat />
                </div>
                
            </div>
        </div>
    )
}

export default Dashboard2;