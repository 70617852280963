

const CLoader = ({show, size}) => {

    return(
        show && <span className="loader" style={{width: `${size ?? 25}px`, height: `${size ?? 25}px`}}></span>
    )
}


export default CLoader;