import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts"; 
import { URLS } from "../../../services/urls";
import useSWR from "swr";
import moment from "moment";
import { useSelector } from "react-redux";
import Select from 'react-select'
import { useTranslation } from "react-i18next";
import LoaderPage from "../../../components/LoaderPage";



const GREEN_COLOR = '#71B444';
const RED_COLOR = '#FF6F66';

const PERIODS = ['WEEK', 'MONTH', 'YEAR'];
const AGENCIES = ['CURRENT', 'ALL'];
const TYPES = ['PRODUCTS', 'SERVICES'];

const dateFromPeriod = (period) => {
  var currentDate = moment();

  switch (period) {
    case 'WEEK':
      var weekStart = currentDate.clone().startOf('isoWeek');
      var weekEnd = currentDate.clone().endOf('isoWeek');

      return [weekStart.format('YYYY-MM-DD'), weekEnd.format('YYYY-MM-DD')];
    case 'MONTH':
      var monthStart = currentDate.clone().startOf('month');
      var monthEnd = currentDate.clone().endOf('month');

      return [monthStart.format('YYYY-MM-DD'), monthEnd.format('YYYY-MM-DD')];
    case 'YEAR':
      var yearStart = currentDate.clone().startOf('year');
      var yearEnd = currentDate.clone().endOf('year');

      return [yearStart.format('YYYY-MM-DD'), yearEnd.format('YYYY-MM-DD')];

    default:
      var weekStart2 = currentDate.clone().startOf('isoWeek');
      var weekEnd2 = currentDate.clone().endOf('isoWeek');

      return [weekStart2.format('YYYY-MM-DD'), weekEnd2.format('YYYY-MM-DD')];
  }
};


const SellsStat = () => {
    const { t } = useTranslation();
    const [type, setType] = useState({value: "SERVICES", label: "Tous les services"})
    const [activePeriod, setActivePeriod] = useState({value: "MONTH", label: t('THIS_MONTH')})
    const { active_agency } = useSelector((state) => state.agencies);

    const { data: products, isLoading, mutate: refetchStats } = useSWR(
        type.value === "PRODUCTS" ? URLS.DASHBOARD.productsSells(dateFromPeriod(activePeriod.value)[0], dateFromPeriod(activePeriod.value)[1], active_agency?.id):
        URLS.DASHBOARD.servicesSells(dateFromPeriod(activePeriod.value)[0], dateFromPeriod(activePeriod.value)[1], active_agency?.id)
    );

    const [options, setOptions] = useState(
        {
            series: [],
            chart: {
            id: 'odif',
            height: 350,
            type: 'area'
          },
          yaxis: { 
            axisBorder: { show: false },
            axisTicks: { show: true },
            lines: {
                show: true,
                style: 'dashed'
              }
          },
          grid: {
            borderColor: "#ff0000",
            xaxis: {
              show: true,
              lines: {
                show: false,
              },
              axisTicks: {
                show: true,
                offsetX: 0,
                offsetY: 0,
              },
              axisBorder: {
                show: true,
              },
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 2,
          },
          xaxis: {
            type: "datetime",
            categories: [],
            axisBorder: { show: false },
            
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          },
          legend: {
            itemMargin: {
              vertical: 40
            },
            horizontalAlign: 'left',
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'light',
              type: 'vertical',
              shadeIntensity: 0,
              opacityFrom: 0.5,
              opacityTo: 0.2,
            },
          },
          
        }
    )

    const handleChangePeriod = (period) => {
        setActivePeriod(period);
        refetchStats();
      };
      const handleChangeType = (type) => {
        setType(type);
      };


    useEffect(() => {
        if(products) {
            setOptions((prev) => {
                return {
                    ...prev,
                    xaxis: {
                        ...prev.xaxis,
                        categories: products[0]?.data_categories
                    },
                    series: products?.map((i) => {
                        return {
                            type: 'area',
                            name: i.name,
                            data: i.data,
                        }
                    }),
                    grid: {
                        show: true,
                        borderColor: '#eee',
                        strokeDashArray: 8, // Length of dash followed by the length of the gap
                        position: 'back', // Places the grid behind the chart
                      },
                }
            });
        }
    }, [products, activePeriod, type])


    return (
        <div className="c-card sells-charts">
            <div className="row">
                <div className="col-md-6">
                    <p className="c-title">Ventes journalières</p>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-4">
                        </div>
                        <div className="col-4">
                            <div>
                                <Select value={activePeriod} onChange={handleChangePeriod} placeholder={"Choisir"} options={PERIODS.map((i) => {return {label: t(`THIS_${i}`), value: i}})} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div>
                                <Select 
                                    value={type}
                                    onChange={handleChangeType}
                                    placeholder={"Choisir"} 
                                    options={
                                        [
                                            {
                                                label: 'Tous les produits',
                                                value: 'PRODUCTS',
                                            },
                                            {
                                                label: 'Tous les services',
                                                value: 'SERVICES',
                                            }
                                        ]
                                    } 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && <LoaderPage />}
            {!isLoading && <ReactApexChart
                options={options}
                series={options.series}
                height={400}
                type="area"
                
            />}
        </div>
    )
}

export default SellsStat;