


const EmptyDataToDisplay = () => {

    return(
        <div className="d-flex py-3">
            <div className="m-auto">
                Aucune donnée à afficher.
            </div>
        </div>
    )
}

export default EmptyDataToDisplay;
