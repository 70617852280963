import moment from 'moment';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import './print.css';
import { useSelector } from 'react-redux';
import { formatNumber, sumArray } from '../../components/utils/functions';
import DailySummary from './components/DailySummary';
import { DatePicker } from 'react-responsive-calendar-picker';
import DailySummaries from './components/DailyPerfSummaries';
import { useReactToPrint } from 'react-to-print';
import LoaderPage from '../../components/LoaderPage';
import PageTitle from '../../components/PageTitle';

const formatDate = (date) => {
  if (date) {
    return String(date.toLocaleDateString()).split('/').reverse().join('-');
  } else {
    return '9999-01-01';
  }
};

const RenderReport = ({ report, dates, isMen }) => {
  return (
    isMen
      ? report?.filter((i) => String(i?.employee?.role).toUpperCase() === 'COIFFEUR')
      : report?.filter((i) => String(i?.employee?.role).toUpperCase() != 'COIFFEUR')
  )?.map((rep, index) => (
    <div className=" mb-3" key={'rep-' + index}>
      <div className="border border-primary p-3">
        <div className="border border-primary">
          <div className="mb-2 ">
            <table className="table">
              <tbody>
                <tr className="text-uppercase">
                  <td className="bg-light">Nom complet</td>
                  <td>
                    {rep?.employee?.first_name} <br />
                    {rep?.employee?.last_name}
                    {/* {rep?.employee?.role} */}
                  </td>
                  <td className="bg-light">Téléphone</td>
                  <td>{rep?.employee?.phone_number}</td>
                </tr>
                <tr className="text-uppercase">
                  <td className="bg-light">Matricule Interne</td>
                  <td>{rep?.employee?.reference}</td>
                  <td className="bg-light">Periode</td>
                  <td>
                    {`Du ${moment(dates.checkin).format('DD/MM/YYYY')} au ${moment(
                      dates.checkout
                    ).format('DD/MM/YYYY')}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="bg-light p-3 mb-3">
            <p className="text-uppercase mb-0 text-center">Performances</p>
          </div> */}
        <div className="table-responsive">
          <table className="table">
            <thead style={{ position: 'sticky', top: 0 }}>
              <tr className="text-uppercase bg-light border-primary border-bottom-1 ">
                <th className="border-primary border-end border-start"></th>
                <th colSpan={2} className="border-primary border-end">
                  Produits vendus
                </th>
                <th
                  colSpan={
                    isMen
                      ? 2
                      : 1 +
                        rep?.stats[0]?.report?.filter(
                          (i) => String(i?.category?.name).toUpperCase() != 'COIFFURES'
                        )?.length
                  }
                  className="border-primary border-end"
                >
                  {' '}
                  Prestations
                </th>
                <th className="border-primary border-end border-start"></th>
              </tr>
              <tr className="text-uppercase bg-light">
                <th className="whitespace-nowrap border-primary border-end border-start">Date</th>
                <th className="border-primary border-end">Qtés</th>
                <th className="whitespace-nowrap border-primary border-end">Valeur</th>
                {/* Other services */}
                <th className="border-primary border-end">Qtés</th>
                {isMen ? (
                  <th className="border-primary border-end">COIFFURES</th>
                ) : (
                  rep?.stats[0]?.report
                    ?.filter((i) => String(i?.category?.name).toUpperCase() != 'COIFFURES')
                    ?.map((re, idx3) => (
                      <th className=" border-primary border-end" key={'th-serv-' + idx3}>
                        {re?.category?.name}
                      </th>
                    ))
                )}
                <th className="whitespace-nowrap border-primary border-end">CA Jour</th>
              </tr>
            </thead>
            <tbody>
              {rep?.stats?.map((stat, idx2) => (
                <tr key={'perf-day-' + idx2}>
                  <td>{moment(stat?.date).format('DD/MM/YYYY')}</td>
                  <td>{sumArray(stat?.report?.map((r) => r.quantity_products))}</td>
                  <td>{formatNumber(sumArray(stat?.report?.map((r) => r.amount_products)))}</td>
                  <td>{formatNumber(sumArray(stat?.report?.map((r) => r.quantity_services)))}</td>
                  {isMen
                    ? stat?.report?.map((re, idx3) =>
                        String(re?.category?.name).toUpperCase() === 'COIFFURES' ? (
                          <td key={'th-serv-3-' + idx3}>{re?.amount_services}</td>
                        ) : null
                      )
                    : stat?.report
                        ?.filter((i) => String(i?.category?.name).toUpperCase() != 'COIFFURES')
                        ?.map((re, idx3) => (
                          <td key={'th-serv-3-' + idx3}>{re?.amount_services}</td>
                        ))}
                  <td>{formatNumber(sumArray(stat?.report?.map((r) => r.price)))}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {<DailySummary dates={dates} employee={rep.employee} />}
      </div>
    </div>
  ));
};

const PerfReport = () => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);
  const reportRef = useRef();

  const {
    data: report,
    isLoading: loadingCa,
    mutate: mutateCa,
  } = useSWR(
    URLS.STATS.REPPORT.perf(
      formatDate(dates.checkin),
      formatDate(dates.checkout),
      active_agency?.id
    )
  );

  const handlePrint = useReactToPrint({
    content: () => reportRef?.current,
    documentTitle: 'Rapport de performance ' + moment().format('DD/MM/YYYY'),
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });

  return (
    <div className="perf-report">
      <div className="row">
        <div className="col-md-6">
          <PageTitle
            title={'Rapport de performance Global'}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Rapport de performance
                </li>
              </ol>
            }
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="py-">
            <h3 className="text-uppercas">Rapport de performance</h3>
          </div>
          <div className="col-12">
            <div className="d-flex">
              <div className="">
                <div>
                  <div className="py-3 position-relative" style={{ zIndex: '1' }}>
                    <DatePicker
                      dates={dates}
                      setDates={setDates}
                      open={open}
                      setOpen={setOpen}
                      normal={true}
                    >
                      <button className="btn btn-primary rounded-0" onClick={() => setOpen(!open)}>
                        Rapport du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                        {dates.checkout && 'au'}{' '}
                        {dates.checkout && dates.checkout.toLocaleDateString()}
                      </button>
                    </DatePicker>
                  </div>
                </div>
              </div>
              <div className="ms-auto">
                {
                  <button
                    onClick={handlePrint}
                    type="button"
                    className="px-3 rounded-0 btn btn-primary waves-effect waves-light"
                  >
                    {' '}
                    Imprimer
                  </button>
                }
              </div>
            </div>
          </div>
          <div className="row" ref={reportRef}>
            <div className="col-12 mb-5">
              {<DailySummaries dates={dates} agency={active_agency?.id} />}
            </div>
            <div className="mt-5"></div>
            {/* Coiffeurs / Hommes */}
            <div className="col-lg-4 col-sm-12 mb-3 report-page">
              <h3 className='text-uppercas' style={{textTransform: 'uppercase'}}>Coiffeurs / Hommes</h3>
              <RenderReport dates={dates} report={report} isMen={true} />
            </div>
            <div className="col-lg-8 col-sm-12 mb-3 report-page">
              {/* Estheticiennes / Femmes */}
              <h3 className='text-uppercas' style={{textTransform: 'uppercase'}}>Estheticiennes / Femmes</h3>
              <RenderReport dates={dates} report={report} isMen={false} />
            </div>
          </div>
          {
            loadingCa && <LoaderPage label={"Génération du rapport..."} />
          }
        </div>
      </div>
    </div>
  );
};

export default PerfReport;
