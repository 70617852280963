import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import { URLS } from '../../../services/urls';
import useSWR from 'swr';
import moment from 'moment';

const Appointments = () => {
  const { agencies, active_agency } = useSelector((state) => state.agencies);
  // 
  const { data: appointments, isLoading: loadingTransactions } = useSWR(
    URLS.APPOINTMENTS.list(active_agency?.id, '', 1, `&status=SCHEDULED&limit=5&ordering=-created_at`),
  );

  return (
    <div className="sells-by-agency appointments-dashboard">
      <div className="d-flex">
        <p className="title my-auto d-flex">
            <span className="my-auto">Rendez-vous </span>
          <span className="app-count ms-3 d-flex">
            <span className='m-auto'>{appointments?.count}</span>
          </span>
        </p>
        <div className="ms-auto">
          <Link to={'/appointments/list'} className="link">
            Voir plus
          </Link>
        </div>
      </div>
      <hr />
      <div className="table-responsive">
        <table className="table mb-0">
          <tbody>
            {appointments?.results?.slice(0, 3).map((appointment) => (
              <tr key={'rsp-' + appointment?.id}>
                <td className="text-start" style={{ width: '40px' }}>
                  <Avatar
                    size="40"
                    name={
                      appointment?.order?.customer?.first_name +
                      ' ' +
                      appointment?.order?.customer?.last_name
                    }
                    round
                  />
                </td>
                <td className=" text-start">
                  {appointment?.order?.customer?.first_name +
                    ' ' +
                    appointment?.order?.customer?.last_name}
                  <br />
                  <span className="sub-text">
                  {appointment?.order?.items?.map((item, index) => item?.item?.title + (index+1 === appointment?.order?.items?.length ? "" : ", "))}
                  </span>
                </td>
                <td className=" text-end">
                  {moment(`${appointment?.start}T${appointment?.start_time}`).fromNow()}
                  <br />
                  <span className="sub-text">
                    {moment('2023-12-07T' + appointment?.start_time).format('HH:mm')}-
                    {moment(appointment?.end).format('HH:mm')}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Appointments;
