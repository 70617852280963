
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { URLS } from '../../../services/urls';
import moment from 'moment';


const AppointmentsListDate = ({selectedDate, me, handleEdit, higlingth}) => {
    const { active_agency } = useSelector((state) => state.agencies);

    const {
        data: appointments,
        isLoading,
        mutate,
      } = useSWR(URLS.APPOINTMENTS.list(active_agency?.id, "", 1, `&start=${moment(selectedDate?.dateStr ?? '').format("YYYY-MM-DD")}`));

    return(
        <div className="">
        {/* {moment(selectedDate?.dateStr ?? '').format("YYYY-MM-DD")} */}
        <div>
        <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-dark">
                    <tr>
                      <th>#</th>
                      <th>Heure Début</th>
                      <th>Heure Fin</th>
                      <th>Client</th>
                      <th>Contact</th>
                      <th>Prestations</th>
                      <th>Prestatiare</th> 
                      {/* {!me && <th>Actions</th>} */}
                    </tr>
                  </thead>
                  <tbody>
                    {appointments?.results?.map((appointment, index) => {
                      return (
                        <tr onClick={() => handleEdit( {event: {"id": ""}}, appointment)} key={'stock' + appointment?.id} className={`appointment-line table-${appointment.status == 'SCHEDULED'
                        ? 'warning'
                        : appointment.status == 'PENDING'
                        ? 'primary'
                        : appointment.status == 'COMPLETED'
                        ? 'success'
                        : 'danger'}`}
                        style={higlingth===appointment?.id ? {scale: 1.1, background: "var(--primary)"} :{} }
                        >
                          <th>{index + 1}</th>
                          <th>{String(appointment.start_time).slice(0, 5)}</th>
                          <th>{String(appointment.end).slice(11, 16)}</th>
                          <td>{appointment?.order?.customer?.first_name} {appointment?.order?.customer?.last_name}</td>
                          <td>{appointment?.order?.customer?.phone_number}</td>
                          <td>{appointment?.order?.items?.map((item, index) => item?.item?.title + (index + 1 == appointment?.order?.items?.length ? "" : ", "))}</td>
                          <td>{appointment?.order?.items[0]?.executor?.first_name} {appointment?.order?.items[0]?.executor?.last_name} </td>
                          {/* {!me && <td>
                            <div className="">
                            {<button onClick={() => handleEdit( {event: {"id": ""}}, appointment)} type="button" className="btn btn-primary waves-effect waves-light">
                                {' '}
                                Voir
                                </button>}
                            </div>
                          </td>} */}
                        </tr>
                      );
                    })}
                  </tbody>
                  {
                    (!isLoading && appointments?.results?.length === 0) &&
                    <caption className='text-center'>Aucun rendez-vous</caption>
                  }
                </table>
              </div>
        </div>
        </div>
    )
}

export default AppointmentsListDate;