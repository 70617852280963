import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import CLoader from '../../../components/Loader';
import useSWR from 'swr';
import _ from 'lodash';
import Select from 'react-select';
import { handleFocus, sumArray } from '../../../components/utils/functions';

const ManageSupplyForm = ({ toEdit, onSuccess, handleClose, side }) => {
  // if toEdit is undefined it mean that we have to choose a product
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [searchProducts, setSearchProducts] = useState('');
  // Emplacement du stock
  const [selectedPlace, setSelectedPlace] = useState();
  const [searchPlace, setSearchPlace] = useState('');

  // Addig products
  const [productBuyPrice, setProductBuyPrice] = useState();
  const [productQuantity, setProductQuantity] = useState();
  const [stockProducts, setStockProducts] = useState([]);


  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);

  const { data: products, isLoading } = useSWR(
    URLS.PRODUCTS.list(active_agency?.id, searchProducts, 1, 'PRODUCT')
  );
  const { data: places, isLoading: loadingPlaces } = useSWR(
    URLS.STOCKS.PLACES.list(active_agency?.id, searchPlace, 1, '')
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleSelectProduct = (pro) => {
    console.log(pro);
    setSelectedProduct(pro);
  };

  const handleAddproductItem = () => {

    if(selectedProduct && productQuantity && productBuyPrice) {

        const items = {
            id: _.uniqueId(),
            product: selectedProduct,
            quantity: productQuantity,
            buyPrice: productBuyPrice,
            extraProduct: _.filter(products?.results, {id: selectedProduct.value})[0]
        }
        setStockProducts((prev) => [...prev, items])

        // Reiitiate others fields
        setProductQuantity("");
        setProductBuyPrice("");
        setSelectedProduct();
    }
  }

  const handleRemoveItem = (id) => {

    setStockProducts((prev) => prev.filter(item => item.id != id))
  }
  const handleUpdateQuantity = (id, value) => {

    setStockProducts((prev) => prev.map((item) => {
        if(item.id === id) {
            item.quantity = value;
        }
        return item;
    }))
  }
  const handleUpdatePrice = (id, value) => {

    setStockProducts((prev) => prev.map((item) => {
        if(item.id === id) {
            item.buyPrice = value;
        }
        return item;
    }))
  }

  const navigate = useNavigate();
  const onSubmit = (data) => {
    stockProducts.forEach((product) => {
        
        let toSend = {
        quantity: parseFloat(product.quantity),
        buy_price: parseFloat(side === 'IN' ? product.buyPrice : 0),
        sell_price: product?.extraProduct?.price ?? 0,
        side: side ?? 'IN',
        product: product?.product?.value,
        location: selectedPlace?.value,
        agency: active_agency?.id,
        document_number: data?.documentNumber,
        date: data?.date,
        supplier: data?.supplier,
        };

        console.log(selectedProduct, toSend);
        if (1 === "2") {
        toast.error('Veuiller sélectionner un produit');
        } else {
        setLoading(true);
        API.post(URLS.STOCKS.create, toSend)
            .then((resp) => {
            reset();
            if (onSuccess) {
                onSuccess(resp.data);
            }
            toast.success(
                side === 'IN' ? 'Le stock a bien été Approvisionné' : 'Le produit a été déstocké'
            );
            setLoading(false);
            if (handleClose) {
                handleClose();
            }
            setSearchPlace('');
            setSelectedPlace();
            setSearchProducts('');
            setSelectedProduct();
            })
            .catch((error) => {
            toast.error(
                error?.response?.data?.detail ??
                error?.response?.data ??
                (side === 'IN'
                    ? "Une erreur s'est produite lors de l'approvisionnement du Stock, veuiller reésayer !"
                    : "Une erreur s'est produite lors de la sortie de Stock, veuiller reésayer !")
            );
            setLoading(false);
            });
        }

    })
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window.$('#submitCustomerForm1')?.click();
  };

  useEffect(() => {
    if (toEdit) {
      reset({
        name: toEdit.name,
        email: toEdit.email,
        phoneNumber: toEdit.phone_number,
        address: toEdit.address,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    console.log('xx', selectedProduct);
  }, [selectedProduct]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">

        {side === 'IN' && (
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="floatingLastnameInputDate">
                  Date <span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="floatingLastnameInputDate"
                  {...register('date')}
                  placeholder="Date"
                />
              </div>
            </div>
          )}
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingSupplierInputDate">
                Fournisseur <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="floatingSupplierInputDate"
                {...register('supplier')}
                placeholder="Fournisseur"
              />
            </div>
          </div>
          {side === 'IN' && (
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="floatingLastnameInputDocument">
                  N Facture <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="floatingLastnameInputDocument"
                  {...register('documentNumber')}
                  placeholder="N Facture"
                />
              </div>
            </div>
          )}
          {
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="floatingPlaceInput">
                  Emplacement <span className="text-danger">*</span>
                </label>
                <div>
                  <Select
                    className="basic-single-place"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    value={selectedPlace}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(newValue) => setSelectedPlace(newValue)}
                    onInputChange={(value) => setSearchPlace(value)}
                    name="place"
                    options={places?.results?.map((place) => {
                      return { value: place.id, label: `${place?.name}` };
                    })}
                  />
                </div>
              </div>
            </div>
          }

          <div className="col-12">
            <div>
              <hr />
              <p className="h4 text-uppercase">Ajouter les produits</p>
            </div>
          </div>
          {!toEdit && (
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="floatingProductInput">
                  Désignation <span className="text-danger">*</span>
                </label>
                <div>
                  <Select
                    className="basic-single-product"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    value={selectedProduct}
                    // isClearable={true}
                    // isSearchable={true}
                    onChange={(newValue) => handleSelectProduct(newValue)}
                    onInputChange={(value) => setSearchProducts(value)}
                    name="customer"
                    options={products?.results?.map((product) => {
                      return { value: product.id, label: `${product?.title}` };
                    })}
                  />
                </div>
                {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
              </div>
            </div>
          )}

          {side === 'IN' && (
            <div className="col-md-3">
              <div className="mb-3">
                <label htmlFor="floatingFirstnameInput">
                  Prix d'achat <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="floatingFirstnameInput"
                  value={productBuyPrice}
                  onChange={(e) => setProductBuyPrice(e.target.value)}
                  placeholder="Prix d'achat"
                />
                {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
              </div>
            </div>
          )}
          {/* {side === "IN" && <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingLastnameInput">Prix de vente <span className="text-danger">*</span></label>
              <input
                type="number"
                className="form-control"
                id="floatingLastnameInput"
                {...register('sellPrice')}
                placeholder="1000"
              />
            </div>
          </div>} */}
          <div className="col-md-3">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">
                Quantité <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                value={productQuantity}
                onChange={(e) => setProductQuantity(e.target.value)}
                placeholder="Quantité"
                onE
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="mb-3">
                <label htmlFor=""> {" "}</label>
                <button
                    type="button"
                    onClick={() => handleAddproductItem()}
                    className="btn btn-primary w-md waves-effect waves-light d-flex mt-2"
                >
                    <span className="m-auto">Ajouter</span>{' '}
                    
                </button>
            </div>
          </div>
        </div>
        <div>
            <hr />
            <div>
                {stockProducts.length > 0 &&<div className="table-responsive">
                  <table className="table mb-0 table-bordered">
                    <thead className="table-light text-uppercase">
                      <tr>
                        <th>Désignation</th>
                        <th>Quantité</th>
                        <th>Prix D'achat</th>
                        <th>Montant</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stockProducts?.map((item) => (
                        <tr key={'k' + item?.id}>
                          <td className='py-1'>{item?.extraProduct?.title}</td>
                          <td className='py-1'>
                            <input type="number" onChange={(e) => handleUpdateQuantity(item.id, e.target.value)} className='m-0 p-0 border-0' style={{width: '30px'}} defaultValue={item?.quantity} onFocus={handleFocus} />
                          </td>
                          <td className='py-1'>
                            <input type="number" onChange={(e) => handleUpdatePrice(item.id, e.target.value)} className='m-0 p-0 border-0' style={{width: '50px'}} defaultValue={item?.buyPrice} onFocus={handleFocus} />
                          </td>
                          <td className='py-1'>{item.quantity * item?.buyPrice}</td>
                          <td className='py-1'>
                          <Link
                              to="#"
                              className="btn btn-sm text-danger btn-colapsed p-0"
                              onClick={() => handleRemoveItem(item.id)}
                            >
                              {<i className="mdi mdi-delete"></i>}
                            </Link>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={3}></td>
                        <td colSpan={2}>
                          <span>
                            <strong>TOTAL: </strong> {" "}
                            {sumArray(stockProducts.map((item) => item.buyPrice * item.quantity))}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>}
              </div>
        </div>

        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm1" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default ManageSupplyForm;
