import { Outlet } from "react-router-dom"



const Connfigurations = () => {


    return <div>
        <Outlet/>
    </div>
}

export default Connfigurations;