import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { loadAgencies } from '../../store/actions/agencies';
import { URLS } from '../../services/urls';
import useSWR from 'swr';
import moment from 'moment';
import { DEFAULT_PAGE_COUNT, MOBILE_PAYMENT_METHODS } from '../../components/utils/consts';
import { DatePicker } from 'react-responsive-calendar-picker';
import ManageMobileCheckoutForm from './components/NewCheckout';
import TransactionTicket from './components/TransactionTicket';
import { API } from '../../services/axios';
import { toast } from 'react-toastify';
import { formatNumber, formateDate } from '../../components/utils/functions';
import TransfertToMainForm from './Checkouts/TransfertToMain';
import PageTitle from '../../components/PageTitle';
import _ from 'lodash';
import LoaderPage from '../../components/LoaderPage';

const formateDate2 = (date) => {
  if (date) {
    return String(date.toLocaleDateString()).split('/').reverse().join('-');
  } else {
    return '9999-01-01';
  }
};

const PaymentsList = () => {
  const [activeWallet, setActiveWallet] = useState();
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [selectedtTransaction, setSelectedtTransaction] = useState();

  const [open, setOpen] = useState(false);

  const { agencies, active_agency } = useSelector((state) => state.agencies);

  const {
    data: wallets,
    isLoading: loadingWallets,
    mutate: mutateMP,
  } = useSWR(URLS.WALLET.MOBILE.list(active_agency?.id));
  const { data: transferts, isLoading: loadingTransferts } = useSWR(
    URLS.TRANSFERT.list(active_agency?.id, '', page, `&wallet__id=${activeWallet?.id ?? ''}`)
  );

  const {
    data: reports,
    isLoading: loadingReports,
    mutate,
  } = useSWR(
    URLS.STATS.CA.CLOSE_CHECKOUT.report(
      active_agency?.id,
      formateDate2(dates.checkin),
      formateDate2(dates.checkout)
    )
  );

  const handleClose = () => {
    window.$('.btn-close')?.click();
    mutate()
  };

  const navigate = useNavigate()

  const handleShowInvoiceDetails = (event, ref) => {
    event.preventDefault();
    API.get(URLS.INVOICES.list(active_agency?.id, "", 1, `&reference=${ref}`)).then((resp) => {
      if(resp.data.count > 0){
        navigate(`/invoices/${resp.data.results[0].id}`)
      }
    }).catch((error) => {
      toast.error("La facture est introuvable")
    });
  };

  const dispatch = useDispatch();

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const hasRigths = true;

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
  };

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleSuccess = () => {
    setToEdit();
    if (hasRigths) {
      mutate();
      mutateMP()
    }
    dispatch(loadAgencies());
  };

  // mp for mobile payment

  const handleSuccessMP = () => {
    mutateMP();
  };

  const handleWithdraw = (wallet) => {
    setActiveWallet(wallet);
    window.$('.new-invoice-modal')?.modal('show');
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Autres caisses'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Autres caisses
            </li>
          </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
          <DatePicker dates={dates} setDates={setDates} open={open} setOpen={setOpen} normal={true}>
            <button className="btn btn-primary" onClick={() => setOpen(!open)}>
              Stats du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
              {dates.checkout && 'au'} {dates.checkout && dates.checkout.toLocaleDateString()}
            </button>
          </DatePicker>
          </div>
        </div>
      </div>
      
      <div className="col-12">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="card p-3">
              <div className="card-body">
                <h4 className="text-uppercas">Autres caisses</h4>
                <div className="pt-2">
                  {
                    _.filter(wallets?.results, {mobile: "OM"})?.map((report) => (
                      <div
                        key={'l' + report.id}
                        className={`card mb-2 border-1 wallet-card ${
                          activeWallet?.id === report?.id && 'active'
                        }`}
                      >
                        <Link
                          className="card-body"
                          onClick={() => {
                            setPage(1);
                            setActiveWallet(report);
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div className="avatar-md flex-shrink-0">
                              <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                <span className="uim-svg">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="1em"
                                  >
                                    <path
                                      className="uim-quaternary"
                                      d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                    ></path>
                                    <path
                                      className="uim-tertiary"
                                      d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                    ></path>
                                    <path
                                      className="uim-primary"
                                      d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                    ></path>
                                  </svg>
                                </span>
                              </span>
                            </div>
                            <div className="flex-grow-1 overflow-hidden ms-4">
                              <p className="text-muted text-truncate font-size-15 mb-2">
                                {' '}
                                {String(report?.mobile).replaceAll('_', ' ')}
                              </p>
                              <h3 className="fs-4 flex-grow-1 mb-3">
                                {report?.amount} <span className="text-muted font-size-16">FCFA</span>
                              </h3>
                              {/* <p className="text-muted mb-0 text-truncate">
                                <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                                  <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                                </span>{' '}
                                vs last month
                              </p> */}
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  }
                  {wallets?.results?.filter(item => item?.mobile !== "OM")?.map((report) => (
                    <div
                      key={'l' + report.id}
                      className={`card mb-2 border-1 wallet-card ${
                        activeWallet?.id === report?.id && 'active'
                      }`}
                    >
                      <Link
                        className="card-body"
                        onClick={() => {
                          setPage(1);
                          setActiveWallet(report);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="avatar-md flex-shrink-0">
                            <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                              <span className="uim-svg">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="1em"
                                >
                                  <path
                                    className="uim-quaternary"
                                    d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                  ></path>
                                  <path
                                    className="uim-tertiary"
                                    d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                  <path
                                    className="uim-primary"
                                    d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                  ></path>
                                </svg>
                              </span>
                            </span>
                          </div>
                          <div className="flex-grow-1 overflow-hidden ms-4">
                            <p className="text-muted text-truncate font-size-15 mb-2">
                              {' '}
                              {String(report?.mobile).replaceAll('_', ' ')}
                            </p>
                            <h3 className="fs-4 flex-grow-1 mb-3">
                              {report?.amount} <span className="text-muted font-size-16">FCFA</span>
                            </h3>
                            {/* <p className="text-muted mb-0 text-truncate">
                              <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                                <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                              </span>{' '}
                              vs last month
                            </p> */}
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className="modal fade new-invoice-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabel">
                      {toEdit ? 'Modifitation de la sortie de caisse' : 'Sortie de caisse'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <TransfertToMainForm
                      handleClose={handleClose}
                      onSuccess={handleSuccess}
                      toEdit={toEdit}
                      wallet={activeWallet}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade new-checkout-modal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="newCheckoutLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="newCheckoutLabel">
                      {'Nouvelle Caisse Mobile'}
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <ManageMobileCheckoutForm
                      handleClose={handleClose}
                      onSuccess={handleSuccessMP}
                      wallets={wallets?.results ?? []}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div className="card p-3">
              <div className="card-body">
                <div className="d-flex">
                  <div>
                    <h4 className="text-uppercas">Suivi de Caisse</h4>
                  </div>
                  <div className="ms-auto">
                    {activeWallet && <button 
                    className="d-flex btn btn-primary m-auto rounded- mb-2 text-uppercas"
                    data-bs-toggle="modal"
                    data-bs-target=".new-invoice-modal"
                    >
                      Transfert en caisse
                      {/* {`${active_agency?.sigle}-BS-${String(sorties_caisse.count+1).padStart(4, '0')}`} */}
                    </button>}
                  </div>
                </div>
                <div className="pt-2">
                  <div>
                  {
                          loadingTransferts && <LoaderPage />
                        }
                    <div className="table-responsive">
                      <table className="table mb-0 table-bordered">
                        <thead className="table-light text-uppercas">
                          <tr>
                            <th>Date</th>
                            <th>Référence</th>
                            <th>Entrée</th>
                            <th>{'Sortie'}</th>
                            <th>Solde</th>
                          </tr>
                        </thead>
                        {activeWallet && (
                          <tbody>
                            <tr>
                              <td></td>
                              <td >Solde Initial</td>
                              <td ></td>
                              <td ></td>
                              <td>{formatNumber(activeWallet?.initial_sold)} FCFA</td>
                            </tr>
                            {transferts?.results?.map((item) => (
                              <tr key={'transf-' + item?.id} className={`badge-soft-${item?.type === "DEPOSIT" ? 'success' : 'danger'}`}>
                                <td>{formateDate(item?.created_at)}</td>
                                <th scope="row">
                                {item?.designation ? <Link to={`#`} onClick={(e) => handleShowInvoiceDetails(e, item?.designation)} className='color-inherit'>{item?.designation}</Link> : <Link to="#" data-bs-toggle="modal"
                              data-bs-target=".details-trans-modal"
                             onClick={() => setSelectedtTransaction((item))} className='color-inherit'>{"#" + item?.reference}</Link>} 
                            {item?.beneficiary_name && ` (${(item?.beneficiary_name ?? "")})`}
                            </th>

                                <td>{item?.type === 'DEPOSIT' && formatNumber(item?.amount) + ' FCFA'}</td>
                                <td>{item?.type !== 'DEPOSIT' && formatNumber(item?.amount) + ' FCFA'}</td>
                                <td>{formatNumber(item?.new_sold)} FCFA</td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="d-flex">
                      <div className="ms-auto">
                        {(transferts?.count > 0 && activeWallet) && (
                          <div className="pt-2">
                            <ul className="pagination pagination-rounded">
                              <li>
                                <Link
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, page - 1)}
                                  className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                                  to="#"
                                ></Link>
                              </li>
                              {new Array(Math.ceil((transferts?.count ?? 0) / DEFAULT_PAGE_COUNT))
                                .fill(3)
                                .map((pag, index) => (
                                  <li
                                    key={'page' + index}
                                    className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                    aria-current="page"
                                  >
                                    <Link
                                      className={`pager-number ${
                                        page * DEFAULT_PAGE_COUNT === Math.ceil(transferts?.count)
                                          ? 'disabled'
                                          : ''
                                      } ${page === index + 1 ? 'active' : ''}`}
                                      to="#"
                                      tabIndex={-1}
                                      onClick={(event) => handleChangePage(event, index + 1)}
                                    >
                                      <span className="page-link">{index + 1}</span>
                                    </Link>
                                  </li>
                                ))}
                              <li>
                                <Link
                                  tabIndex={-1}
                                  className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                                  to="#"
                                ></Link>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={transferts?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={loadingReports}
                  paginationServer
                  paginationTotalRows={transferts?.results?.length ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  responsive
                /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">

      <div
                  className="modal fade details-trans-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeTransModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeTransModalLabel">
                          {'Bon de caisse N° ' + selectedtTransaction?.reference}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <TransactionTicket
                          invoice={selectedtTransaction}
                        />
                      </div>
                    </div>
                  </div>
                </div>
      </div>
    </div>
  );
};

export default PaymentsList;
