



const LoaderPage = ({label}) => {


    return (
        <div className="h-100-vh">
            <div id="statusl" className="h-100 d-flex">
                <div className="spinner m-auto text-center">
                    <i className="ri-loader-line spin-icon"></i>
                    <h4>{label ?? "Chargement..."}</h4>
                </div>
            </div>
        </div>
    )
}

export default LoaderPage;