import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import useSWR from 'swr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ManageAgenciesForm from './components/ManageAgencies';
import { URLS } from '../../../services/urls';
import { confirmDeletion } from '../../../components/dletion';
import PageTitle from '../../../components/PageTitle';



const paginationComponentOptions = {
  // noRowsPerPage: true,
};

const Agencies = ({ type }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: invoices,
    isLoading,
    mutate,
  } = useSWR(
    URLS.AGENCIES.lists(search, page, `&status=${status}&payment_method=${paymentMethod}`)
  );

  const { t } = useTranslation();

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    setSelected(selectedRows);
  };

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (event, obj) => {
    event.preventDefault();
    confirmDeletion(URLS.AGENCIES.delete(obj.id), t, mutate);
  };

  let columns = [
    {
      name: "Nom",
      selector: (row) => `${row.name ?? ''}`,
      sortable: true,
    },
    {
      name: 'Adresse',
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Téléphone",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email ?? "",
      sortable: true,
    },
    {
      name: 'Solde en caisse',
      selector: (row) => `${row.wallet?.amount ?? 0.0} FCFA`,
      sortable: true,
    },
    {
      name: 'Sigle',
      selector: (row) => `${row.sigle ?? ""}`,
      sortable: true,
    },
    {
      name: 'Code OM',
      selector: (row) => `${row.merchand_code_om ?? "#"}`,
      sortable: true,
    },
    {
      name: 'Code MOMO',
      selector: (row) => `${row.merchand_code_momo ?? "#"}`,
      sortable: true,
    },
    {
      name: "Date de création",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    {
      name: 'Actions',
      // grow: 2,
      selector: (row) => (
        <div className="d-flex gap-2">
          {/* <Link to="#" title="Réapprovisionner" className="btn btn-primary btn-sm btn-colapsed">
            <i className="ri-download-2-line"></i>
            <span>Réapprovisionner</span>
          </Link> */}
          <Link
            to="#"
            onClick={() => handleEdit(row)}
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target=".new-agency-modal"
            className="btn btn-success btn-sm btn-colapsed"
          >
            <i className="mdi mdi-pencil"></i>
            <span>Modifier</span>
          </Link>
          <Link
            to={`#`}
            onClick={(e) => handleDelete(e, row)}
            title="Supprimer"
            className="btn btn-danger btn-sm btn-colapsed"
          >
            <i className="mdi mdi-delete"></i>
            <span>Supprimer</span>
          </Link>
        </div>
      ),
    },
  ];
  

  return (
    <div className="row py-">
      <div className="col-md-6">
        <PageTitle
          title={'Agences'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/configurations/agencies">Configurations</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Agences
            </li>
          </ol>
          }
        />
      </div>
     
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="">Liste des agences </h3>
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  data-bs-toggle="modal"
                  data-bs-target=".new-agency-modal"
                >
                  {' '}
                  + Nouvelle agence
                </button>
                <div
                  className="modal fade new-agency-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? 'Modifitation de l\'agence '
                            : 'Nouvelle agence'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <ManageAgenciesForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}
              
              <div >
                <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={invoices?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={isLoading}
                  paginationServer
                  paginationTotalRows={invoices?.count ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  paginationComponentOptions={paginationComponentOptions}
                  responsive
                />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Agencies;
