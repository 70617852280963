import { useState } from 'react';
import { PAYMENT_METHODS } from '../../components/utils/consts';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import LoaderPage from '../../components/LoaderPage';
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";



const PaymentGateway = () => {
  const [check, setcheck] = useState('male');
  const [amount, setAmount] = useState(0);
  const [dialed, setDialed] = useState(false);

  const { sigle } = useParams();

  const { data, isLoading } = useSWR(URLS.AGENCIES.payment(sigle));

  const handleCall = () => {
    if (check && amount) {
      window.location.href = `tel:${sigle}`;
      if (check.code === 'MOMO') {
        window.location.href = `tel:#126*4*${data?.results[0]?.merchand_code_momo}*${amount}#`;
        setDialed(true);
      } else if (check.code === 'OM') {
        window.location.href = `tel:#150*47*${data?.results[0]?.merchand_code_om}*${amount}#`;
        setDialed(true);
      }
    } else {
      toast.error('Veuillez sélectionner un mode de paiement et un montant');
    }
  };

  return (
    <div id="layout-wrapper">
        <Helmet>
                <meta charSet="utf-8" />
                <title>Good Feeling Payment Gateway </title>
        </Helmet>
      <div className="auth-maintenance d-flex align-items-center min-vh-100">
        <div className="container">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              {isLoading && <LoaderPage label={'Chargement...'} />}
              {!isLoading && data?.results?.length > 0 && !dialed && (
                <div>
                  <div className="text-center">
                    <img
                      src="/assets/images/logo-dark.webp"
                      alt=""
                      height="80"
                      className="auth-logo logo-dark mx-auto remove-bg"
                    />
                    <h2 className="text-uppercase text-center">Good Feeling Payment Gateway</h2>
                    <p>{data?.results[0]?.address}</p>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="amount">Montant</label>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                          placeholder="Entrer le montant"
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </div>
                      <div>
                        <h3 className="pt-4">Choisir une methode de paiement:</h3>
                        {PAYMENT_METHODS.map((method, index) => {
                          return (
                            <div
                              key={'PM' + index}
                              class={`card p-3 cursor-pointer ${
                                check?.code !== method.code ? 'border' : ''
                              }`}
                              style={
                                check?.code === method.code
                                  ? { border: '1px solid var(--bs-primary)' }
                                  : null
                              }
                              onClick={() => setcheck(method)}
                            >
                              <div className="row">
                                <div className="col-6">
                                  <div className="h-100">
                                    <div className="d-flex h-100">
                                      <input
                                        class="form-check-input my-auto"
                                        type="radio"
                                        name="radioFilter"
                                        id="filter-1"
                                        checked={check?.code === method.code}
                                      />
                                      <div class="ms-2 my-auto">
                                        <h4 class="card-title mb-0">{method.name}</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="text-end">
                                    <img src={method.img} height={'40px'} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          onClick={handleCall}
                          disabled={!check}
                          className="btn btn-primary"
                          style={{ width: '200px' }}
                        >
                          Payer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              
              {
                dialed && <div className="text-center card">
                    <div className="card-body">
                        <h2>Vous avez été redirigé vers l'interface de paiement</h2>
                        <img src="/assets/images/success.gif" alt="" />
                        <br />
                        <p>Vous n'avez pas ete redirigé vers l'interface de paiement ? </p>
                        <button
                          onClick={handleCall}
                          disabled={!check}
                          className="btn btn-primary"
                          style={{ width: '200px' }}
                        >
                          Payer maintenant
                        </button>
                    </div>
                </div>
              }
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGateway;
