import { useEffect, useRef, useState, useTransition } from 'react';
import { useForm } from 'react-hook-form';
import CLoader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Select from 'react-select';
import { formatLinkString, handleFocus, sumArray } from '../../../components/utils/functions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import DatePicker from '../../../components/DatePicker';

const NewAppointmentForm = ({ toEdit, onSuccess, handleClose, customerId2 }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [searchProduct, setSearchProduct] = useState('');
  const [searchEmployers, setSearchEmployers] = useState('');
  const [searchUsers, setSearchUsers] = useState('');
  const [searchCoupon, setSearchCoupon] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedService, setSelectedService] = useState();
  const [changingStatus, setChangingStatus] = useState('');
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [confirmingAppointmennt, setConfirmingAppointmennt] = useState(false);
  const [silently, setSilently] = useState(false);

  const [addingToCart, setAddingTocart] = useState(false);
  const [cart, setCart] = useState([]);
  // Just ids
  const [cartItems, setCartItems] = useState([]);

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);

  const { customerId } = useParams();

  const { data: employees, isLoading: loadingEmployees } = useSWR(
    URLS.USERS.employees(active_agency?.id, searchEmployers, 1)
  );
  const {
    data: services,
    isLoading,
    mutate,
  } = useSWR(URLS.PRODUCTS.list(active_agency?.id, searchProduct, 1, 'SERVICE'));

  const { data: users, isLoading: loadingUsers } = useSWR(
    URLS.USERS.list(active_agency?.id, searchUsers, 1, 'SERVICE')
  );

  const { data: coupons, isLoading: loadingCoupons } = useSWR(
    URLS.COUPON.list(active_agency?.id, searchCoupon, 1)
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleComfirmAppointment = (id) => {
    setSilently(true)
    const id2 = toast.loading('Confirmationn en cours');
    // MArk an appointment as started
    setConfirmingAppointmennt(true);
    handleSetAfterAction('CLOSE', true);
    API.put(URLS.APPOINTMENTS.mark_as_started(id))
      .then((resp) => {
        setConfirmingAppointmennt(false);
        toast.update(id2, {
          render: 'Rendez-vous confirmé',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
        navigate('/invoices/pending');

        if (handleClose) {
          handleClose();
        }
      })
      .catch((error) => {
        setConfirmingAppointmennt(false);
        toast.update(id2, {
          render: error?.response?.data?.detail ?? t('requestGlobalErrorMessage'),
          type: 'error',
          isLoading: false,
          autoClose: true,
        });
      });
  };

  const handleChangeAppointmentStatus = (id, status) => {
    setChangingStatus(status);
    const id2 = toast.loading('Changement de statut en cours...');
    // MArk an appointment as Cancelled or completed
    API.put(status === 'CANCELLED' ? URLS.APPOINTMENTS.cancel(id) : URLS.APPOINTMENTS.complete(id))
      .then((resp) => {
        if (handleClose) {
          handleClose();
        }
        setChangingStatus('');
        toast.update(id2, {
          render:
            status === 'CANCELLED'
              ? 'Le rendez-vous a bien été annulé!'
              : 'Le rendez-vous a bien été complété!',
          type: 'success',
          isLoading: false,
          autoClose: true,
        });
      })
      .catch((error) => {
        setChangingStatus('');
      });
  };

  const onSubmit = (data) => {
    if (cart.length > 0) {
      let toSend = {
        start: moment(data.start).format('YYYY-MM-DD'),
        end: moment(data.start).format('YYYY-MM-DD') + 'T' + data.endTime,
        start_time: data.startTime,
        agency: active_agency.id,
        created_by: auth?.user?.id,
        coupon: selectedCoupon?.value ?? undefined,
      };

      if (toEdit) {
        setLoading(true);
        API.patch(URLS.APPOINTMENTS.update(toEdit.id), toSend)
          .then((resp) => {
            reset();
            if (onSuccess) {
              onSuccess(resp.data);
            }
            if(!silently) {
              toast.success('Le rendez-vous a bien été modifié');
            }
            setLoading(false);
            if (handleClose) {
              handleClose();
            }
          })
          .catch((error) => {

            if(!silently) {
              
              toast.error(
                error?.response?.data?.detail ??
                  "Une erreur s'est produite lors de la modification du rendez-vous, veuiller reésayer !"
              );
            }
            setLoading(false);
          });
        // Update order
        const orderData = {
          ref_code: 'string',
          ordered: true,
          received: false,
          refund_requested: false,
          refund_granted: false,
          items: cart.map((item) => item.id),
          order: toEdit?.id,
          coupon: selectedCoupon?.value ?? undefined,
        };
        API.patch(URLS.CART.ORDERS.update(toEdit?.order?.id), orderData)
          .then((resp) => {

            if(!silently) {
              
              toast.success('La commande a bien été modifiéé');
            }
            setLoading(false);
          })
          .catch((error) => {

            if(!silently) {
              
              toast.error(
                error?.response?.data?.detail ??
                  "Une erreur s'est produite lors de la modification de la commande, veuiller reésayer !"
              );
            }
            setLoading(false);
          });
      } else {
        // On submit, create the order first
        const orderData = {
          ref_code: 'string',
          ordered_date: moment(),
          ordered: true,
          received: false,
          refund_requested: false,
          refund_granted: false,
          customer: customerId ?? customerId2,
          items: cart.map((item) => item.id),
          coupon: selectedCoupon?.value ?? undefined,
        };
        setLoading(true);
        API.post(URLS.CART.ORDERS.create, orderData).then((resp) => {
          toSend['order'] = resp.data.id;
          API.post(URLS.APPOINTMENTS.create, toSend)
            .then((resp) => {
              reset();
              setSearchUsers();
              setSearchProduct();

              if (onSuccess) {
                onSuccess(resp.data);
              }
              if (handleClose) {
                handleClose();
              }
              toast.success('Le rendez-vous a bien été créé');
              setLoading(false);
              setAfterAction('');
              navigate(
                `/appointments/list?openDate=${resp?.data?.start}&appointment=${resp?.data?.id}`
              );
            })
            .catch((error) => {
              toast.error(
                error?.response?.data?.detail ??
                  "Une erreur s'est produite lors de la création du rendez-vous, veuiller reésayer !"
              );
              setLoading(false);
              setAfterAction('');
            });
        });
      }
      console.log(data);
    } else {
      toast.error('Veuiller rajouter un ou plusieurs services');
    }
  };

  const handleRemoveItem = (itemId) => {
    API.delete(URLS.CART.ITEM.delete(itemId))
      .then((resp) => {
        setCartItems((prev) => {
          const newCart = cart.filter((itm) => itm.id == itemId);
          const newCartItems = prev.filter((itm) => itm != newCart[0]?.item?.id);
          return newCartItems;
        });
        setCart((prev) => {
          const newCart = prev.filter((itm) => itm.id != itemId);
          return newCart;
        });
      })
      .catch((error) => {});
  };

  const handleUpdateQuantity = (itemId, quantity) => {
    // Update product item
    if (quantity >= 1) {
      API.patch(URLS.CART.ITEM.update(itemId), { quantity })
        .then((resp) => {
          setCart((prev) => {
            const newCart = prev.map((itm) => {
              if (itm.id === itemId) {
                itm.quantity = resp.data.quantity;
              }
              return itm;
            });
            return newCart;
          });
        })
        .catch((error) => {});
    }
  };

  const handleUpdatePrice = (itemId, item_price) => {
    // Update product item
    if (quantity >= 1) {
      API.patch(URLS.CART.ITEM.update(itemId), { item_price })
        .then((resp) => {
          setCart((prev) => {
            const newCart = prev.map((itm) => {
              if (itm.id === itemId) {
                itm.item_price = resp.data.item_price;
                itm.final_price = resp.data.item_price;
              }
              return itm;
            });
            return newCart;
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? 'Impossible de modifier le prix.');
        });
    }
  };

  const handleAddToCart = (event) => {
    event.preventDefault();
    console.log(selectedEmployee && selectedService);
    if (selectedEmployee && selectedService) {
      setAddingTocart(true);

      const data = {
        ordered: true,
        quantity: quantity ?? 1,
        customer: customerId ?? customerId2,
        item: selectedService?.value,
        executor: selectedEmployee?.value,
      };
      API.post(URLS.CART.ITEM.create, data)
        .then((resp) => {
          setAddingTocart(false);
          // toast.success("")
          setQuantity(1);
          // setSelectedEmployee({ label: '', value: undefined });
          setSelectedService({ label: '', value: undefined });
          setSearchEmployers('');
          setSearchProduct('');
          setCart((prev) => [resp.data, ...prev]);
          setCartItems((prev) => [String(resp.data?.item?.id), ...prev]);
        })
        .catch((error) => {
          setAddingTocart(false);
        });
    }
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window?.$('#submitCustomerForm2')?.click();
  };

  useEffect(() => {
    if (toEdit) {
      console.log(String(toEdit.end).slice(11, 16));
      reset({
        start: moment(toEdit.start).format('YYYY-MM-DD'),
        endTime: String(toEdit.end).slice(11, 16),
        startTime: toEdit.start_time,
      });
      setCart(toEdit?.order?.items ?? []);
      setCartItems(toEdit?.order?.items?.map((item) => String(item?.item?.id)) ?? []);
      setSelectedCoupon({
        value: toEdit?.order?.coupon?.id,
        label: `${toEdit?.order?.coupon?.code} (${toEdit?.order?.coupon?.amount} FCFA )`,
      });
    }
  }, [toEdit]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 mb-3">
            <div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="floatingFirstnameInput">
                      Date <span className="text-danger">*</span>{' '}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="floatingFirstnameInput"
                      {...register('start', { required: true })}
                      min={moment().format('YYYY-MM-DD')}
                      placeholder="Date et heure de début"
                      disabled={toEdit?.status === 'COMPLETED' || toEdit?.status === 'CANCELLED'}
                    />
                    {/* <div className="invalid-feedback">
                          Please select a valid state.
                      </div> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="floatingTimeInput">
                      Heure de Début <span className="text-danger">*</span>{' '}
                    </label>
                    <DatePicker
                      setValue={setValue}
                      register={register}
                      required={true}
                      name={'startTime'}
                      disabled={toEdit?.status === 'COMPLETED' || toEdit?.status === 'CANCELLED'}
                      getValue={getValues}
                    />
                    {/* <div className="invalid-feedback">
                          Please select a valid state.
                      </div> */}
                    {/* <span class="text-danger validity"> Choisir une heure entre 08:00 et 19:00 </span> */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <label htmlFor="floatingTimeEndInput">
                      Heure de Fin <span className="text-danger">*</span>{' '}
                    </label>
                    <DatePicker
                      setValue={setValue}
                      register={register}
                      name={'endTime'}
                      required={true}
                      disabled={toEdit?.status === 'COMPLETED' || toEdit?.status === 'CANCELLED'}
                      getValue={getValues}
                    />
                    {/* <input
                      type="time"
                      className="form-control"
                      id="floatingTimeEndInput"
                      {...register('endTime', {required: true})}
                      min={"07:30"}
                      max={"19:00"}
                      placeholder=""
                      disabled={toEdit?.status === 'COMPLETED' || toEdit?.status === 'CANCELLED'}
                    /> */}
                    {/* <div className="invalid-feedback">
                          Please select a valid state.
                      </div> */}
                    {/* <span class="text-danger validity"> Choisir une heure entre 08:00 et 19:00 </span> */}
                  </div>
                </div>

                <div className="py-3 col-12">
                  <div>
                    <h3>Prestations</h3>

                    {toEdit?.status != 'COMPLETED' && toEdit?.status != 'CANCELLED' && (
                      <div className="row py-2">
                        <div className="col-md-4 col-12">
                          <label htmlFor="#">Prestation</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isLoading={isLoading}
                            value={selectedService}
                            isClearable={true}
                            inputValue={searchProduct}
                            isSearchable={true}
                            onChange={(newValue) => setSelectedService(newValue)}
                            onInputChange={(value) => setSearchProduct(value)}
                            name="prestation"
                            options={services?.results?.map((service) => {
                              return {
                                value: service.id,
                                label: service.title,
                                isDisabled: cartItems.includes(String(service.id)),
                              };
                            })}
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <label htmlFor="#">Opérateur</label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isLoading={loadingEmployees}
                            inputValue={searchProduct}
                            isClearable={true}
                            isSearchable={true}
                            value={selectedEmployee}
                            onChange={(newValue) => setSelectedEmployee(newValue)}
                            onInputChange={(value) => setSearchEmployers(value)}
                            name="prestataire"
                            options={employees?.results?.map((employee) => {
                              return {
                                value: employee.id,
                                label: `${employee.first_name} ${employee.last_name}`,
                              };
                            })}
                          />
                        </div>
                        {/* <div className="col-md-3 col-12">
                          <div className="mb-4">
                            <label htmlFor="#">Quantité</label>
                            <input
                              className="form-control"
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                              type="number"
                              placeholder="Quantite"
                            />
                          </div>
                        </div> */}
                        <div className="col-md-3 col-12 pt-1">
                          <label htmlFor="#"></label>
                          <label htmlFor="#"></label>
                          <div className="mt-1">
                            <button
                              onClick={handleAddToCart}
                              className="btn btn-primary w-100 d-flex justify-content-center"
                              type="button"
                            >
                              Ajouter <span className="me-2"></span> <CLoader show={addingToCart} />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {cart.length > 0 && (
                      <div className="table-responsive mt-3 table-head">
                        <table className="table mb-0 table-bordered">
                          <thead className="table-head-appointment">
                            <tr>
                              <th>Référence</th>
                              <th>Désignation</th>
                              <th>{'Opérateur'}</th>
                              {/* <th>Quantité</th> */}
                              {/* <th>Prix Unitaire</th> */}
                              <th>Prix</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart?.map((item) => (
                              <tr key={'k' + item?.id}>
                                <th scope="row" className="py-1">
                                  {item.item?.reference}
                                </th>
                                <td className="py-1">{item.item?.title}</td>
                                <td className="py-1">
                                  {item?.executor?.first_name} {item?.executor?.last_name}
                                </td>
                                {/* <td className='py-1'>
                            <input type="number" onChange={(e) => handleUpdateQuantity(item.id, e.target.value)} className='m-0 p-0 border-0' style={{width: '30px'}} defaultValue={item?.quantity} onFocus={handleFocus} />
                          </td> */}
                                <td className="py-1">
                                  <input
                                    type="number"
                                    onChange={(e) => handleUpdatePrice(item.id, e.target.value)}
                                    className="m-0 p-0 border-0"
                                    style={{ width: '50px' }}
                                    defaultValue={item?.item_price}
                                    onFocus={handleFocus}
                                  />
                                </td>
                                {/* <td className='py-1'>{item.quantity * item?.item?.price}</td> */}
                                <td className="py-1">
                                  <Link
                                    to="#"
                                    className="btn btn-sm text-danger btn-colapsed p-0"
                                    onClick={() => handleRemoveItem(item.id)}
                                  >
                                    {addingToCart ? (
                                      <CLoader show={addingToCart} />
                                    ) : (
                                      <i className="mdi mdi-delete"></i>
                                    )}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={3}></td>
                              <td>
                                <span>
                                  <strong>TOTAL: </strong>{' '}
                                </span>
                              </td>
                              <td>{sumArray(cart.map((item) => item.final_price))}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="customer">Coupon de réduction</label>
                    <Select
                      className="basic-single-customer"
                      classNamePrefix="select"
                      isLoading={loadingCoupons}
                      value={selectedCoupon}
                      isClearable={true}
                      inputValue={searchCoupon}
                      isSearchable={true}
                      onChange={(newValue) => setSelectedCoupon(newValue)}
                      onInputChange={(value) => setSearchCoupon(value)}
                      name="customer"
                      options={coupons?.results?.map((coupon) => {
                        return {
                          value: coupon.id,
                          label: `${coupon?.code} (${coupon?.amount} FCFA)`,
                        };
                      })}
                    />
                  </div>
                </div> */}
                {/*<div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="floatingemailInput">Téléphone</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      {...register('phoneNumber')}
                      placeholder="Téléphone"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {toEdit && (
            <div className="my-3">
              <div className="d-flex">
                <div>
                <div className="d-flex flex-column">
                {toEdit?.status === 'SCHEDULED' && (
                  <button
                    type="button"
                    onClick={() => handleSetAfterAction('CLOSE')}
                    className="btn btn-primary w-md waves-effect mb-3 waves-light text-uppercase"
                  >
                    <span className="my-auto">{toEdit ? 'Modifier' : 'Enregistrer'} le RDV</span>{' '}
                    <span className="ms-2 d-inline-flex">
                      {' '}
                      <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
                    </span>
                  </button>
                )}
                {toEdit?.status === 'PENDING' && (
                  <button
                    disabled={changingStatus === 'COMPLETED'}
                    onClick={() => handleChangeAppointmentStatus(toEdit.id, 'COMPLETED')}
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                  >
                    <span className="my-auto"> TERMINER LA PRESTATION </span>{' '}
                    <span className="ms-2 d-inline-flex">
                      {/* <i className="ri-check-line align-middle ms-2"></i>{' '} */}
                      <CLoader show={changingStatus === 'COMPLETED'} />
                    </span>
                  </button>
                )}
                {toEdit?.status === 'SCHEDULED' && (
                  <button
                    disabled={changingStatus === 'CANCELLED'}
                    onClick={() => handleChangeAppointmentStatus(toEdit.id, 'CANCELLED')}
                    type="button"
                    className="btn btn-danger waves-effect waves-light"
                  >
                    <span className="my-auto">ANNULER LE RENDEZ-VOUS</span>{' '}
                    <span className="ms-2 d-inline-flex">
                      {/* <i className="ri-close-line align-middle me-2"></i>{' '} */}
                      <CLoader show={changingStatus === 'CANCELLED'} />
                    </span>
                  </button>
                )}
                
              </div>
                </div>
                <div className="ms-auto d-flex">
{toEdit?.status === 'SCHEDULED' && (
                  <button
                    disabled={confirmingAppointmennt}
                    onClick={() => handleComfirmAppointment(toEdit?.id)}
                    type="button"
                    className="btn m-auto btn-info waves-effect waves-light"
                  >
                    <span className="my-auto"> CONFIRMER LE RDV </span>{' '}
                    <span className="ms-2 d-inline-flex">
                      {/* <i className="ri-check-line align-middle ms-2"></i>{' '} */}
                      <CLoader show={confirmingAppointmennt} />
                    </span>
                  </button>
                )}
                </div>
              </div>
              
            </div>
          )}
        </div>

        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm2" type="submit"></button>
          {!toEdit && (
            <button
              type="button"
              onClick={() => handleSetAfterAction('CLOSE')}
              className="btn btn-primary w-md waves-effect waves-light d-flex"
            >
              <span className="my-auto">{toEdit ? 'Modifier' : 'Enregistrer'} le RDV</span>{' '}
              <span className="ms-2 d-inline-flex">
                {' '}
                <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
              </span>
            </button>
          )}

          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default NewAppointmentForm;
