import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import CLoader from '../../../components/Loader';
import { DOCUMENTS_TYPES } from '../../../components/utils/consts';

const ManageAttachments = ({ employee, toEdit, onSuccess, handleClose }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [document, setDocument] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    let formData = new FormData()
    
    formData.append("document_type", data.name);
    formData.append("document", document);
    formData.append("employee", employee);
    
    if (toEdit) {
      setLoading(true);
      API.patch(URLS.ATTACHMENTS.update(toEdit.id), formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          if(handleClose){
            handleClose()
          }
          toast.success(`Le fichier a bien été modifié`);
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.ATTACHMENTS.create, formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          if(handleClose){
            handleClose()
          }
          toast.success(`Le fichier a bien été rajouté`);
          setLoading(false);
          reset();
          
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);
  };

  const handleChangeFile = (event) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        setDocument(file)
    }
  }


  useEffect(() => {
    if (toEdit) {
      reset({
        name: toEdit.name,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    window?.bsCustomFileInput?.init();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="docType">Type de Document</label>

              <select className="form-control" {...register('name', {required: true})} id="docType">
                <option value="">Choisir</option>
                {
                    DOCUMENTS_TYPES.map((doc, index) => (
                        <option value={doc.name} key={'opt-' + index}> {doc.label} </option>
                    ))
                }
              </select>
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="docType">Document</label>

              <input type="file" accept='applicationn/pdf' class="form-control"  onChange={handleChangeFile} />
            </div>
          </div>
        </div>
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="submit"
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageAttachments
