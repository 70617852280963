import React, { useState } from 'react';
import { DatePicker } from 'react-responsive-calendar-picker';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import PageTitle from '../../components/PageTitle';
import LoaderPage from '../../components/LoaderPage';
import PaginationComponent from '../../components/Pagination';
import { formatDate, formatNumber } from '../../components/utils/functions';
import NewInvoiceForm from '../Invoices/components/NewInvoiceForm';
import InvoiceTicket from '../Invoices/components/InnvoiceTicket';
import EmptyDataToDisplay from '../../components/EmptyData';

const CustomersGifts = ({ type }) => {
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [searchCustomers, setSearchCustomers] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const { data: customers, isLoading: loadingCustomers } = useSWR(
    URLS.CUSTOMERS.list(active_agency?.id, searchCustomers, 1, '')
  );

  const {
    data: invoices,
    isLoading,
    mutate,
  } = useSWR(
    URLS.PAYMENTS.invoices(
      active_agency?.id,
      search,
      page,
      `&status=${status}&payment_method=OFFER&customer__id=${
        selectedCustomer?.value ?? ''
      }&created_at__gt=${formatDate(dates.checkin)}T00:00:00&created_at__lt=${formatDate(dates.checkout)}T00:00:00`
    )
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleChangePage = (pg) => {
    setPage(pg);
  };

  return (
    <div className="row py-5">
      <div className="col-md-6">
        <PageTitle
          title={'Factures'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des factures
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex">
          <div className="my-auto ms-auto">
          <div>
            <div className="py-3 position-relative" style={{ zIndex: '1' }}>
              <DatePicker
                dates={dates}
                setDates={setDates}
                open={open}
                setOpen={setOpen}
                normal={true}
              >
                <button className="btn btn-primary" onClick={() => setOpen(!open)}>
                  Factures du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                  {dates.checkout && 'au'}{' '}
                  {dates.checkout && dates.checkout.toLocaleDateString()}
                </button>
              </DatePicker>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">Liste des Factures</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row gy-2 gx-3 align-items-center">
                  <div className="col">
                    <div>
                      {/* <label htmlFor="autoSizingInput">Rechercher</label> */}
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Rechercher"
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="col">
                    <div>
                      <div>
                        {/* <label htmlFor="customers">Client</label> */}
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          isLoading={isLoading}
                          value={selectedCustomer}
                          isClearable={true}
                          inputValue={searchCustomers}
                          isSearchable={true}
                          onChange={(newValue) => setSelectedCustomer(newValue)}
                          onInputChange={(value) => setSearchCustomers(value)}
                          name="customers"
                          options={customers?.results?.map((customer) => {
                            return {
                              value: customer?.id,
                              label: customer?.first_name + ' ' + customer?.last_name,
                            };
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    <div>
                      <div>
                        <div className="">
                          <div>
                            <ExportExcel
                              label={'Exporter'}
                              link={URLS.INVOICES.export(
                                active_agency?.id,
                                search,
                                page,
                                `&status=PENDING&payment_method=${paymentMethod}&order__customer__id=${
                                  selectedCustomer?.value ?? ''
                                }`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div
                    className="modal fade new-product-modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="myLargeModalLabel">
                            {toEdit ? `Facture ${toEdit?.reference}` : 'Nouvelle facture'}
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <NewInvoiceForm
                            type={type === 'SERVICES' ? 'SERVICE' : 'PRODUCT'}
                            handleClose={handleClose}
                            onSuccess={handleSuccess}
                            toEdit={toEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}
            <hr className="mb-0" />
            <div className="row ">
            </div>
            <div>
             {
              isLoading ? <LoaderPage />
              :
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0 table-bordere table-hover">
                  <thead className="table-ligh">
                    <tr>
                      <th>Référence</th>
                      <th>Nom du client</th>
                      {/* <th>Désignation</th> */}
                      <th>Montant</th>
                      <th>Executé Par</th>
                      <th>Statut</th>
                      <th>Désignation</th>
                      <th>Date de création</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.results?.map((row) => (
                      <tr
                        key={'tr-' + row.id}
                        className="appointment-line"
                        onClick={() => navigate(`/invoices/${row?.invoice?.id}`)}
                      >
                        <td>#{row?.invoice?.reference ?? ''}</td>
                        <td>
                          {row?.invoice.order?.customer?.first_name + ' ' + row?.invoice.order?.customer?.last_name}
                        </td>
                        <td>{formatNumber(row.amount) + ' FCFA' ?? '0'}</td>
                        <td>
                          {row?.created_by?.first_name + ' ' + row?.created_by?.last_name}
                        </td>
                        <td>
                          <span
                            className={`badge badge-soft-${
                              row?.invoice?.status === 'PENDING'
                                ? 'warning'
                                : row?.invoice?.status === 'COMPLETED'
                                ? 'success'
                                : 'danger'
                            } p-2`}
                          >
                            {row?.invoice?.status === 'PENDING'
                              ? 'En attente'
                              : row?.invoice?.status === 'COMPLETED'
                              ? 'Payée'
                              : 'Annulée'}
                          </span>
                        </td>
                        <td>
                          {row?.invoice?.order?.items?.map(
                            (itm, idx) =>
                              (itm?.item?.title ?? '') + (idx + 1 === row?.items?.length ? '' : ', ')
                          )}
                        </td>
                        {/* <td>{moment(row.created_at).format('DD/MM/YYYY HH:MM')}</td> */}
                        <td>
                          <>
                            {' '}
                            {moment(row?.invoice?.created_at).format('DD/MM/YYYY')}{' '}
                            <span className="ms-3">{moment(row?.invoice?.created_at).format('HH:MM')}</span>
                          </>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>}
            </div>
            <div>
            <div>
            {
                invoices?.results?.length === 0 && <EmptyDataToDisplay />
            }
          </div>
            </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="ms-auto">
        <div className="pt-3">
          {
            invoices?.count > 0 && (
              <PaginationComponent currentPage={page} handlePageChange={handleChangePage} totalPages={Math.ceil(invoices.count / DEFAULT_PAGE_COUNT) ?? 0} />
            )
          }
          </div>
        
        </div>
      </div>
      <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + selectedInvoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={selectedInvoice} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersGifts;
