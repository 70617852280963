import { useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";

const RestrictedMenu = ({group, user_level, children}) => {

  return group?.includes(user_level) ? children : <div />
}

const MenuItem = ({ item }) => {

  const { auth } = useSelector((state) => state.auth);

  const hasRights = item?.group?.includes(auth?.user?.user_level);

  // if( item?.group?.includes(auth?.user?.user_level) ){

    return (hasRights && item.children?.length > 0  )? (
      <li key={'mescolu-' + item.id}>
        <Link to="#" id={"lnk" + item.id} className="has-arrow d-flex waves-effect">
          <span className="d-flex menu-span">
            <span class="material-symbols-outlined">{item.icon}</span>
            <span className=" py-1 px-2">{item.title}</span>
          </span>
        </Link>
        <ul className="sub-menu mm-collapse">
          {
            item.children.map((subMenu, index) => (
              (subMenu?.group?.includes(auth?.user?.user_level)) && <li key={"subb_" + subMenu.id + index}>
                <NavLink to={subMenu.link} className="waves-effect">
                  {/* <span className="uim uim-airplady"></span> */}
                  <span className="d-flex menu-span">
                    <span class="material-symbols-outlined my-auto">{subMenu.icon}</span>
                    <span className=" py-1 px-2">{subMenu.title}</span>
                  </span>
                </NavLink>
              </li>
            ))
          }
        </ul>
      </li>
    ) : (hasRights && item.simple_text) ? (
      <li key={'meu-' + item.id} className="menu-title">{item.title}</li>
    ) : (hasRights) ? (
      <li key={'meu-' + item.id}>
        <NavLink to={item.link ?? "#"} exact={true} className="waves-effect">
          {/* <span className="uim uim-airplay"></span> */}
          {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
          <span className="d-flex menu-span">
            <span className="material-symbols-outlined">{item.icon}</span>
            <span className="py-1 px-2">{item.title}</span>
          </span>
        </NavLink>
      </li>
    ) : auth?.user?.user_level === "ADMIN" ? (
      <li key={'meuk-' + item.id} className="text-danger">
        <NavLink to={item.link ?? "#"} exact={true} className="waves-effect disabled">
          {/* <span className="uim uim-airplay"></span> */}
          {/* <span className="badge rounded-pill bg-success float-end">3</span> */}
          <span className="d-flex menu-span">
            <span className="material-symbols-outlined">{item.icon}</span>
            <span className="py-1 px-2">{item.title}</span>
          </span>
        </NavLink>
      </li>) : null
  // } else {
  //   return <div>
  //     {JSON.stringify(auth?.user?.user_level)}
  //     {/* {JSON.stringify(item?.group)} */}
  //     {JSON.stringify(item?.group?.includes(auth?.user?.user_level) ? 'True' : 'False')}
  //   </div>
  // }

};

export default MenuItem;
