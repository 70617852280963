

function filterNavItems(items, allAgencies) {

    return items
    .filter(item => item.all_agencies === allAgencies || (item.children && item.children.some(child => child.all_agencies === allAgencies)))
    .reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
            current.children = current.children?.filter(child => child.all_agencies === allAgencies);
            console.log("current", current);
            acc.push(current);
        }
        return acc;
    }, []);
}


const formatMenu = (menu, allAgencies, user_level) => {

    // console.log("user_level", user_level);

    if(user_level === "ADMIN") {

        let newMenu = filterNavItems(menu, allAgencies);
        // console.log("newMenu", newMenu);

        return newMenu;
    } else {
        return menu;
    }
}


export { formatMenu };