import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewInvoiceForm from '../Invoices/components/NewInvoiceForm';
import NewAppointmentForm from '../Appointment/components/NewAppointmentForm';
import DepositForm from '../Finances/Checkouts/DepositForm';
import InvoiceTicket from '../Invoices/components/InnvoiceTicket';
import PageTitle from '../../components/PageTitle';
import { formatNumber, formateDate } from '../../components/utils/functions';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import PaginationComponent from '../../components/Pagination';
import EmptyDataToDisplay from '../../components/EmptyData';

const CustomersList2 = () => {
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: customers,
    isLoading,
    mutate,
  } = useSWR(URLS.CUSTOMERS.customers_management(active_agency?.id, search, page, ''));

  const { t } = useTranslation();

  const handleDisplayInvoice = (invoice) => {
    console.log('invoice ====================================', invoice);
    setSelectedInvoice(invoice);
  };

  const handleChangePage = (page) => {
    // event?.preventDefault();
    if (page) {
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccessAppointment = () => {
    window.$('.new-appointment-modal')?.modal('hide');
  };

  const handleCloseAppointment = () => {
    // window.$(".new-invoice-modal .btn-close")?.click();
    // var detailsModal = new window.bootstrap.Modal(document.getElementById('customerDetailsModal'), {
    // })
    // detailsModal?.show();
  };

  const handleCloseDetails = () => {
    window?.$(".modal-backdrop.fade").hide()
  }

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.CUSTOMERS.delete(obj.id), t, mutate);
  };

  const handleShowCustomerDetails = (customer) => {
    if (customer) {
      setSelectedCustomer(customer);
    }
    var detailsModal = new window.bootstrap.Modal(
      document.getElementById('customerDetailsModal'),
      {}
    );
    detailsModal?.show();
  };

  const columns = useMemo(() => [
    {
      name: 'Code client',
      selector: (row) => `${row.reference}`,
      sortable: true,
      val: 'reference',
    },
    {
      name: 'Nom complet',
      selector: (row) => `${row.first_name ?? ''} ${row.last_name ?? ''}`,
      sortable: true,
      val: 'first_name',
    },
    {
      name: 'Nombre de Factures',
      selector: (row) => `${row.phone_number}`,
      sortable: true,
      val: 'phone_number',
    },
    {
      name: 'Nombre de Coiffures (Total)',
      selector: (row) => `${row.email}`,
      sortable: true,
      val: 'email',
    },
    {
      name: 'Nombre de Soins (Total)',
      selector: (row) => (
        <span className={`${row?.wallet?.amount > 0 ? 'text-success' : 'text-danger'}`}>
          {row?.wallet?.amount}
        </span>
      ),
      sortable: true,
      val: 'email',
    },
    {
      name: "Date d'Ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    // {
    //   name: 'Actions',
    //   selector: (row) => (
    //     <div className="d-flex gap-3">
    //       <Link to="#" onClick={() => handleEdit(row)} data-bs-toggle="modal" data-bs-target=".new-client-modal" className="btn btn-success btn-sm">
    //         <i className="mdi mdi-pencil"></i>
    //       </Link>
    //       <Link to={`/customers/${row.id}`} className="btn btn-warning btn-sm">
    //         <i className="mdi mdi-eye"></i>
    //       </Link>
    //       <Link to="#" onClick={() => handleDelete(row)} className="btn btn-danger btn-sm">
    //         <i className="mdi mdi-delete"></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ]);

  useEffect(() => {
    // window?.$('.new-appointment-modal').on('hidden.bs.modal', function (e) {

    //   handleShowCustomerDetails()
    // })
    window?.$('.new-invoice-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.new-client-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });

    window?.$('.details-invoice-modal').on('hidden.bs.modal', function () {
      window?.$('.modal-backdrop.fade').hide();
    });
    // new-client-modal
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Liste des Clients'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Liste des Clients
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        
      </div>
      <div className="col-12">
        <div className="card mb-0">
          <div className="card-header">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="c-table-card-title">Liste des Clients</div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col">
                    <div>
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Rechercher"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div>
                      <ExportExcel
                        label={'Exporter'}
                        link={URLS.AGENCIES.exportCustomers(active_agency?.id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div>
              {
              isLoading ? <LoaderPage />
              : <div class="table-responsive">
                <table class="table table-hover c-table-hover">
                  <thead>
                    <tr>
                      {columns?.map((item, i) => (
                        <th scope="col" key={'itm_' + i}>
                          {item?.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(customers?.results ?? [])?.map((row) => (
                      <tr class="" key={'ki_' + row?.id} onClick={() => handleShowCustomerDetails(row)}>
                        <td>{row.reference}</td>
                        <td>{`${row.first_name ?? ''} ${row.last_name ?? ''}`}</td>
                        <td>{row.nombre_factures}</td>
                        <td>{row.nombre_coiffure} {row.nombre_coiffure > 0 && <span>({row.total_coiffure})</span>}</td>
                        <td>{row.nombre_soins} {row.total_soins > 0 && <span>({formatNumber(row.total_soins)} XAF)</span>}</td>
                        <td>{formateDate(row?.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }
              <div>
              {
                    customers?.results?.length === 0 && <EmptyDataToDisplay />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Table pagination */}
      <div className="d-flex">
        <div className="ms-auto">

          <div className="pt-3">
          {
            customers?.count > 0 && (
              <PaginationComponent currentPage={page} handlePageChange={handleChangePage} totalPages={Math.ceil(customers.count / DEFAULT_PAGE_COUNT) ?? 0} />
            )
          }
          </div>

        </div>
      </div>
    </div>
  );
};

export default CustomersList2;
