import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CLoader from '../../components/Loader';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { API } from "../../services/axios";
import { URLS } from "../../services/urls";
import { toast } from "react-toastify";
import { setUserDetails } from "../../components/utils/functions";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/auth";


export const Login = () => {

    const [loading, setLoading] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    setLoading(true);
    API.post(URLS.AUTHS.LOGIN, {...data})
      .then((resp) => {
        const redirect = window.localStorage.getItem("redirect");
        const disconnected = window.localStorage.getItem("disconnected");
        setLoading(false);
        let usrData = resp.data;

        // console.log("usrData", usrData)
        setUserDetails(usrData);
        dispatch(loginSuccess(usrData));
        navigate(searchParams.get("redirect") ?? redirect ?? "/");
        if(usrData?.user?.user_level === "OP_CAISSE") {
          // Reload the page only if the user is already connected once
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error?.response?.data?.message.user ?? error?.response?.data?.message ?? t("textLoginError"));
        toast.error(error?.response?.data?.message.user ?? error?.response?.data?.message ?? t("textLoginError"));
      });
  };
  return (
    <div className="auth-maintenance d-flex align-items-center min-vh-100">
      <div className="bg-overlay bg-light"></div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="auth-full-page-content d-flex min-vh-100 py-sm-5 py-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100 py-0 py-xl-3">
                  <div className="text-center mb-4">
                    <Link to="" className="">
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="80"
                        className="auth-logo logo-dark mx-auto remove-bg"
                      />
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="80"
                        className="auth-logo logo-light mx-auto"
                      />
                    </Link> 
                    <p className="text-muted mt-2">
                      Services de beauté et Spa
                    </p>
                  </div>

                  <div className="card my-auto overflow-hidden">
                    <div className="row g-0">
                      <div className="col-lg-6">
                        <div className="bg-overlay bg-primary"></div>
                        <div className="h-100 bg-auth align-items-end"></div>
                      </div>

                      <div className="col-lg-6">
                        <div className="p-lg-5 p-4 py-md-10">
                          <div>
                            <div className="text-center mt-1">
                              <h4 className="font-size-18">Welcome Back !</h4>
                              <p className="text-muted">Sign in to continue.</p>
                            </div>

                            {message && <div class="alert alert-danger" role="alert">
                                               {message?.user ?? message}
                                            </div>}

                            <form
                                onSubmit={handleSubmit(onSubmit)}
                              className="auth-input"
                            >
                              <div className="mb-2">
                                <label for="username" className="form-label">
                                  Nom d'utilisateur
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="username"
                                  autoComplete="off" role="presentation"
                                  {...register("username", {required: true})}
                                  placeholder="Username"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="form-label" for="password-input">
                                  ********
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Mot de passe"
                                  autoComplete="off"
                                  {...register("password", {required: true})}
                                />
                              </div>
                              {/* <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="auth-remember-check"
                                />
                                <label className="form-check-label" for="auth-remember-check">
                                  Se souvennir de moi
                                </label>
                              </div> */}
                              <div className="mt-3">
                                <button className="btn btn-primary w-100 d-flex justify-content-center" type="submit">
                                  Se connecter <span className="me-2"></span> <CLoader show={loading}/>
                                </button>
                              </div>
                              {/* <div className="mt-4 pt-2 text-center">
                                <div className="signin-other-title">
                                  <h5 className="font-size-14 mb-4 title">Sign In with</h5>
                                </div>
                                <div className="pt-2 hstack gap-2 justify-content-center">
                                  <button type="button" className="btn btn-primary btn-sm">
                                    <i className="ri-facebook-fill font-size-16"></i>
                                  </button>
                                  <button type="button" className="btn btn-danger btn-sm">
                                    <i className="ri-google-fill font-size-16"></i>
                                  </button>
                                  <button type="button" className="btn btn-dark btn-sm">
                                    <i className="ri-github-fill font-size-16"></i>
                                  </button>
                                  <button type="button" className="btn btn-info btn-sm">
                                    <i className="ri-twitter-fill font-size-16"></i>
                                  </button>
                                </div>
                              </div> */}
                            </form>
                          </div>

                          {/* <div className="mt-4 text-center">
                            <p className="mb-0">
                              Don't have an account ?{' '}
                              <a href="auth-register.html" className="fw-medium text-primary">
                                {' '}
                                Register{' '}
                              </a>{' '}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} GF. Crafted
                      with <i className="mdi mdi-heart text-danger"></i> by 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
