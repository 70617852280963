import { useState } from "react";
import Avatar from "react-avatar";
import useSWR from "swr";
import { URLS } from "../../../../services/urls";
import moment from "moment";

const PERIODS = ['WEEK', 'MONTH', 'YEAR'];
const AGENCIES = ['CURRENT', 'ALL'];

const dateFromPeriod = (period) => {
  var currentDate = moment();

  switch (period) {
    case 'WEEK':
      var weekStart = currentDate.clone().startOf('isoWeek');
      var weekEnd = currentDate.clone().endOf('isoWeek');

      return [weekStart.format('YYYY-MM-DD'), weekEnd.format('YYYY-MM-DD')];
    case 'MONTH':
      var monthStart = currentDate.clone().startOf('month');
      var monthEnd = currentDate.clone().endOf('month');

      return [monthStart.format('YYYY-MM-DD'), monthEnd.format('YYYY-MM-DD')];
    case 'YEAR':
      var yearStart = currentDate.clone().startOf('year');
      var yearEnd = currentDate.clone().endOf('year');

      return [yearStart.format('YYYY-MM-DD'), yearEnd.format('YYYY-MM-DD')];

    default:
      var weekStart2 = currentDate.clone().startOf('isoWeek');
      var weekEnd2 = currentDate.clone().endOf('isoWeek');

      return [weekStart2.format('YYYY-MM-DD'), weekEnd2.format('YYYY-MM-DD')];
  }
};

function formatNumber(number) {
    if(number >= 1000) {
      return (number / 1000).toFixed(1) + "k";
    } else {
      return number.toString();
    }
  }


const SellByAgency = ({agency}) => {

    const [ca, setCa] = useState("");
    const [perc, setPerc] = useState(0);
    const [activePeriod, setActivePeriod] = useState(PERIODS[0]);

    const {
        data: stats,
        isLoading,
        mutate: refetchStats,
      } = useSWR(
        URLS.DASHBOARD.caSells(
          dateFromPeriod(activePeriod)[0],
          dateFromPeriod(activePeriod)[1],
          agency?.id
        )
      );


    return (
        <tr >
            <td className='text-start' style={{width: '40px'}}>
                <Avatar size='40' name={agency?.name} round />
            </td>
            <td className=" text-start">{agency?.name}</td>
            <td className=" text-end">
                <img src={`/assets/images/variation-${stats?.variation_percentage > 0 ? 'up' : 'down'}.svg`} alt="" />
            </td>
            <td className=" text-end">{formatNumber(stats?.count ?? 0)}</td>
        </tr>
    )
}


export default SellByAgency;