import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API } from '../../../../services/axios';
import { URLS } from '../../../../services/urls';
import CLoader from '../../../../components/Loader';

const ManagePlaces = ({ type, toEdit, onSuccess, handleClose }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    let formData = new FormData()
    
    formData.append("name", data.name);
    formData.append("agency", active_agency.id);
    
    if (toEdit) {
      setLoading(true);
      API.patch(URLS.STOCKS.PLACES.update(toEdit.id), formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`L'emplacement a bien été modifié`);
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.STOCKS.PLACES.create, formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`L'emplacement a bien été rajouté`);
          setLoading(false);
          reset();
          
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

  };


  useEffect(() => {
    if (toEdit) {
      reset({
        name: toEdit.name,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    window?.bsCustomFileInput?.init();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Nom</label>
              <input
                type="text"
                className="form-control"
                id="floatingFirstnameInput"
                {...register('name', {required: true})}
                placeholder={'Entrer le nom de l\'emplacement'}
              />
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>
        </div>
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="submit"
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManagePlaces
