import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { URLS } from '../../../services/urls';
import moment from 'moment';

const AppoinntmentTable = ({handleEdit, me, unniqueId, period}) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [addDays, setAddDays] = useState(0);

  const appRef = useRef();

  const { active_agency } = useSelector((state) => state.agencies);

  const { data: appointments, isLoading, mutate } = useSWR(
    me ? URLS.APPOINTMENTS.me(active_agency?.id, search, page, `&c_start=${moment().add(addDays, 'days').format("YYYY-MM-DD")}&limit=100`) :URLS.APPOINTMENTS.list(active_agency?.id, search, page, `&c_start=${moment().add(addDays, 'days').format("YYYY-MM-DD")}`)
  );

  const { data: tomorowApps, isLoading: loadingTomorow, mutate: mutateTomorrow } = useSWR(
    me ? URLS.APPOINTMENTS.me(active_agency?.id, search, page, `&c_start=${moment().add(addDays + 1, 'days').format("YYYY-MM-DD")}&limit=100`) : URLS.APPOINTMENTS.list(active_agency?.id, search, page, `&c_start=${moment().add(addDays + 1, 'days').format("YYYY-MM-DD")}`)
  );

  useEffect(() => {
    if(unniqueId){

      mutate();
      mutateTomorrow()
    }
  }, [unniqueId])

  return (
    <div>
      {/* {!me ? <h3 className='text-uppercase'>Liste des rendez-vous</h3> : <h3 className='text-uppercase'>Mes rendez-vous</h3>} */}
      <div ref={appRef}>
        <div className="row">
          {
            period === "TODAY" ?
          <div className="col-md-12">
            <div>
              {/* <p className="text-primary">
                <strong>Aujourd'hui</strong>
              </p> */}
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-darke">
                    <tr>
                      <th>#</th>
                      <th>Heure Début</th>
                      <th>Heure Fin</th>
                      <th>Client</th>
                      <th>Contact</th>
                      <th>Prestations</th>
                      <th>Prestatiare</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments?.results?.map((appointment, index) => {
                      return (
                        <tr onClick={() => handleEdit( {event: {"id": ""}}, appointment)} key={'stock' + appointment?.id} className={`appointment-line table-${appointment.status == 'SCHEDULED'
                        ? 'warning'
                        : appointment.status == 'PENDING'
                        ? 'info'
                        : appointment.status == 'COMPLETED'
                        ? 'success'
                        : 'danger'}`}>
                          <th>{index + 1}</th>
                          <th>{String(appointment.start_time).slice(0, 5)}</th>
                          <th>{String(appointment.end).slice(11, 16)}</th>
                          <td>{appointment?.order?.customer?.first_name} {appointment?.order?.customer?.last_name}</td>
                          <td>{appointment?.order?.customer?.phone_number}</td>
                          <td>{appointment?.order?.items?.map((item, index) => item?.item?.title + (index+1 == appointment?.order?.items?.length ? "" : ", "))}</td>
                          <td>{appointment?.order?.items[0]?.executor?.first_name} {appointment?.order?.items[0]?.executor?.last_name} </td>
                          {/* {!me && <td>
                            <div className="">
                            {<button onClick={() => handleEdit( {event: {"id": ""}}, appointment)} type="button" className="btn btn-primary waves-effect waves-light">
                                {' '}
                                Voir
                                </button>}
                            </div>
                          </td>} */}
                        </tr>
                      );
                    })}
                  </tbody>
                  {
                    (!isLoading && appointments?.results?.length === 0) &&
                    <caption className='text-center'>Aucun rendez-vous</caption>
                  }
                </table>
              </div>
            </div>
          </div>
          :
          <div className="col-md-12">
            <div>
              {/* <p className="text-primary">
                <strong>Demain</strong>
              </p> */}
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead className="table-darke">
                    <tr>
                    <th>#</th>
                      <th>Heure Début</th>
                      <th>Heure Fin</th>
                      <th>Client</th>
                      <th>Contact</th>
                      <th>Prestations</th>
                      <th>Prestatiare</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tomorowApps?.results?.map((appointment, index) => {
                      return (
                        <tr onClick={() => handleEdit( {event: {"id": ""}}, appointment)} key={'stock' + appointment?.id} className={`table-${appointment.status == 'SCHEDULED'
                        ? 'warning'
                        : appointment.status == 'PENDING'
                        ? 'info'
                        : appointment.status == 'COMPLETED'
                        ? 'success'
                        : 'danger'}`}>
                          <th>{index + 1}</th>
                          <th>{String(appointment.start_time).slice(0, 5)}</th>
                          <th>{String(appointment.end).slice(11, 16)}</th>
                          <td>{appointment?.order?.customer?.first_name} {appointment?.order?.customer?.last_name}</td>
                          <td>{appointment?.order?.customer?.phone_number}</td>
                          <td>{appointment?.order?.items?.map((item, index) => item?.item?.title + (index+1 == appointment?.order?.items?.length ? "" : ", "))}</td>
                          <td>{appointment?.order?.items[0]?.executor?.first_name} {appointment?.order?.items[0]?.executor?.last_name} </td>
                          {/* {!me && <td>
                            <div className="">
                            {<button onClick={() => handleEdit( {event: {"id": ""}}, appointment)} type="button" className="btn btn-primary waves-effect waves-light">
                                {' '}
                                Voir
                                </button>}
                            </div>
                          </td>} */}
                        </tr>
                      );
                    })}
                  </tbody>
                  {
                    (!loadingTomorow && tomorowApps?.results?.length === 0) &&
                    <caption className='text-center'>Aucun rendez-vous</caption>
                  }
                </table>
              </div>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AppoinntmentTable;
