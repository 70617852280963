import { Link } from 'react-router-dom';
import useSWR from 'swr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { URLS } from '../../services/urls';
import { API } from '../../services/axios';
import { useState } from 'react';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import PageTitle from '../../components/PageTitle';
import PaginationComponent from '../../components/Pagination';

const Notifications = () => {
  const { active_agency } = useSelector((state) => state.agencies);
  const { auth } = useSelector((state) => state.auth);

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [activeNotif, setActiveNotif] = useState();

  const {
    data: notifications,
    isLoading,
    mutate,
  } = useSWR(URLS.NOTIFICATION.my(`&page=${page}&search=${search}`));

  const handleChangePage = (event, page) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleReadNotif = (notification) => {
    setActiveNotif(notification);
    const data = {
      is_readed: true,
      readed_by: auth?.user?.id,
    };
    API.patch(URLS.NOTIFICATION.update(notification.id), data)
      .then((resp) => {
        mutate();
      })
      .catch((error) => {});
  };

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Notifications'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Notifications
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-12">
        <div className="d-lg-flex mb-4">
          <div className="chat-leftsidebar me-4">
            <div className="card mb-0">
              <div className="card-body pt-0">
                <div className="py-3 border-bottom">
                  <div className="">
                    <h5 className="font-size-14 mb-0 text-uppercase">Notifications récentes</h5>
                  </div>
                </div>
                <div className="card-body py-2 mt-3">
                  <div className="search-box">
                    <div className="position-relative">
                      <input
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        className="form-control border"
                        placeholder="Rechercher..."
                      />
                      <i className="ri-search-line search-icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <div>
                <ul
                  className="list-unstyled chat-list simplebar-scrollable-y"
                  data-simplebar="init"
                  style={{ maxHeight: 500 }}
                >
                  <div className="simplebar-wrapper" style={{ margin: 0 }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex={0}
                          role="region"
                          aria-label="scrollable content"
                          style={{ height: 'auto', overflow: 'hidden scroll' }}
                        >
                          <div className="simplebar-content" style={{ padding: 0 }}>
                            {notifications?.results?.map((notif) => (
                              <li
                                key={'notif-' + notif.id}
                                className={notif?.is_readed ? '' : 'active'}
                              >
                                <Link
                                  to="#"
                                  className="mt-0"
                                  onClick={() => handleReadNotif(notif)}
                                >
                                  <div className="d-flex">
                                    <div className="user-img online align-self-center me-3">
                                      <span
                                        style={{ width: '40px', height: '40px' }}
                                        className="avatar-title bg-primary avatar-xs rounded-circle font-size-16"
                                      >
                                        <i className="ri-alarm-line"></i>
                                      </span>
                                      {!notif?.is_readed && <span className="user-status" />}
                                    </div>
                                    <div className="flex-1 overflow-hidden">
                                      <h5 className="text-truncate font-size-14 mb-1">
                                        {notif?.title}
                                      </h5>
                                      <p className="text-truncate mb-0">{notif?.body}</p>
                                    </div>
                                    <div className="font-size-11">
                                      {moment(notif?.created_at).fromNow()}
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ width: 390, height: 567 }} />
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: 'hidden' }}
                  >
                    <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }} />
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: 'visible' }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: 440,
                        transform: 'translate3d(0px, 0px, 0px)',
                        display: 'block',
                      }}
                    />
                  </div>
                </ul>
              </div>
            </div>
            <div className="">
              {notifications?.count > 0 && (
                <PaginationComponent
                  currentPage={page}
                  handlePageChange={handleChangePage}
                  totalPages={Math.ceil(notifications.count / DEFAULT_PAGE_COUNT) ?? 0}
                />
              )}
            </div>
            
          </div>
          <div className="w-100 user-chat mt-4 mt-sm-0 card mb-0">
            <div className="card-body">
              <div className="pb-3 user-chat-border">
                <div className="row">
                  {activeNotif && (
                    <div className="col-md-4 col-6">
                      <h5 className="font-size-15 mb-1 text-truncate">{activeNotif?.title}</h5>
                      <p className="text-muted text-truncate mb-0">
                        <i className="ri-alarm-line text-primary font-size-10 align-middle me-1" />{' '}
                        {moment(activeNotif?.created_at).fromNow()}
                      </p>
                    </div>
                  )}
                  <div className="col-md-8 col-6">
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="chat-conversation py-3">
                <ul
                  className="list-unstyled mb-0 pe-3 simplebar-scrollable-y"
                  data-simplebar="init"
                  style={{ maxHeight: 230 }}
                >
                  <div className="simplebar-wrapper" style={{ margin: '0px -16px 0px 0px' }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                        <div
                          className="simplebar-content-wrapper"
                          tabIndex={0}
                          role="region"
                          aria-label="scrollable content"
                          style={{ height: 'auto', overflow: 'hidden scroll' }}
                        >
                          {!activeNotif && (
                            <p className="text-center">
                              Selectionner une notification pour afficher son contenu
                            </p>
                          )}
                          {activeNotif && <p>{activeNotif?.body}</p>}
                        </div>
                      </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ width: 701, height: 770 }} />
                  </div>
                  <div
                    className="simplebar-track simplebar-horizontal"
                    style={{ visibility: 'hidden' }}
                  >
                    <div className="simplebar-scrollbar" style={{ width: 0, display: 'none' }} />
                  </div>
                  <div
                    className="simplebar-track simplebar-vertical"
                    style={{ visibility: 'visible' }}
                  >
                    <div
                      className="simplebar-scrollbar"
                      style={{
                        height: 515,
                        transform: 'translate3d(0px, 24px, 0px)',
                        display: 'block',
                      }}
                    />
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
