


const NotFound = () => {



    return(
        <div className="auth-error d-flex align-items-center min-vh-100">
            <div className="bg-overlay bg-light"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-8">
                        <div>
                            <div className="text-center mb-4">
                                <div className="mt-5">
                                    <h1 className="error-title mt-5"><span>404!</span></h1>
                                    <h4 className="mt-2 text-uppercase mt-4">Sorry, page not found</h4>
                                    <p className="mt-4 text-muted w-50 mx-auto">It will be as simple as Occidental in fact, it will Occidental to an English person</p>
                                </div>

                                <div className="mt-5 text-center">
                                    <a className="btn btn-primary waves-effect waves-light" href="index-2.html">Back to Dashboard</a>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound