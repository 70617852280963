import { configureStore } from "@reduxjs/toolkit";
import cartReducer from './cartSlice'
import authReducer from './slices/auth'
import agencyReducer from './slices/agencies'
import localeReducer from './slices/lannguage'
import sortieCaisseReducer from './slices/sortieCaisse'


const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        agencies: agencyReducer,
        sortiesCaisse: sortieCaisseReducer,
        locale: localeReducer,
    }
})

export default store;