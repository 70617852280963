import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CLoader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';



const NewProductForm = ({ type, toEdit, onSuccess, handleClose }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [image, setImage] = useState();

  
  
  const { auth } = useSelector((state) => state.auth);
  
  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: categories,
    isLoading,
    mutate,
  } = useSWR(URLS.PRODUCTS.CATEGORIES.list(active_agency?.id, "", 1, `${type}`));

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        setImage(file)
        var reader = new FileReader();

        reader.onload = function (e) {
            setPreviewUrl(e.target.result);
        };

        reader.readAsDataURL(event.target.files[0]);
    }
  };

  const navigate = useNavigate();
  const onSubmit = (data) => {
    let formData = new FormData()
    
    formData.append("title", data.title);
    formData.append("price", data.price);
    // formData.append("reference", data.reference);
    formData.append("buy_price", data.buyPrice ?? 1);
    formData.append("discount_price", 0);
    formData.append("category", "BEAUTY");
    // formData.append("cat", data.category);
    formData.append("type", type);
    formData.append("description", "j");
    formData.append("in_stock", true);
    formData.append("agency", active_agency.id);
    formData.append("item_discount_price", data.price);
    
    if(type === "SERVICE") {
      formData.append("quantity", 1);
      formData.append("cat", data.category);
    } else {
      formData.append("quantity", data.quantity ?? 1);

    }
    
    if(image) {
        formData.append("image", image);
    }
    if (toEdit) {
      setLoading(true);
      API.patch(URLS.PRODUCTS.update(toEdit.id), formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`Le ${productOrService} a bien été modifié`);
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              reset();
              navigate(`/customers/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              if (handleClose) {
                reset();
                handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout du ${productOrService}, veuiller reésayer !`
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.PRODUCTS.create, formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`Le ${productOrService} a bien été rajouté`);
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              navigate(`/products/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              if (handleClose) {
                reset();
                // handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout du ${productOrService}, veuiller reésayer !`
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window.$('#submitCustomerForm')?.click();
  };

  const productOrService = type === 'SERVICE' ? 'service' : 'produit';

  useEffect(() => {
    if (toEdit) {
      reset({
        title: toEdit.title,
        price: toEdit.price,
        buyPrice: toEdit.buy_price,
        category: toEdit.cat,
        description: toEdit.description,
        inStock: toEdit.in_stock,
        reference: toEdit.reference,
        quantity: toEdit.quantity,
      });
      setPreviewUrl(toEdit.image)
    }
  }, [toEdit]);

  useEffect(() => {
    window?.bsCustomFileInput?.init();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Nom du {productOrService} <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control"
                id="floatingFirstnameInput"
                {...register('title', {required: true})}
                placeholder={'Nom du ' + productOrService}
              />
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
            </div>
            {/* <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="floatingReferencetnameInput">Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="floatingReferenceInput"
                  {...register('reference', {required: true})}
                  placeholder={'Code du ' + productOrService}
                />
                {/* <div className="invalid-feedback">
                      Please select a valid state.
                  </div> *
              </div>
            </div> */}
          {type === "PRODUCT" && <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingLastnameInput">Stock initial</label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="floatingLastnameInput"
                {...register('quantity')}
                placeholder="Stock initial"
              />
            </div>
          </div>}
          {type === "PRODUCT" && <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Prix d'achat <span className="text-danger">*</span> </label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="phoneNumber"
                {...register('buyPrice')}
                placeholder="Prix d'achat"
              />
            </div>
          </div>}
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput"> {type === "PRODUCT" ? "Prix de vente" : "Prix"} <span className="text-danger">*</span></label>
              <input
                type="number"
                min={0}
                className="form-control"
                id="floatingemailInput"
                {...register('price', {required: true})}
                placeholder={type === "PRODUCT" ? "Prix de vente" : "Prix"}
              />
            </div>
          </div>
          <div className="col-md-6">
            {type === "SERVICE" && <div className="mb-3">
              <label className="" htmlFor="inlineFormSelectPref">
                Catégorie
              </label>
              <select
                defaultValue={''}
                {...register('category', {required: true})}
                className="form-select"
                id="inlineFormSelectPref"
              >
                <option value={''} disabled>
                  Choisir...
                </option>
                {
                  categories?.results?.map((category) => (

                    <option key={'cat-option-' + category.id} value={category.id}>{category.name}</option>
                  ))
                }
              </select>
            </div>}
            {/* {type === "PRODUeCT" && <div className="mb-3">
                <div className="form-check mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="formCheck1"
                    {...register('inStock')}
                />
                <label className="form-check-label" htmlFor="formCheck1">
                    En stock
                </label>
                </div>
            </div>} */}
          </div>
          {/* <div className="col-md-6">
            <div className="mb-3">
              <div className="">
                <label className="" htmlFor="productImage">
                  Image du {productOrService}
                </label>
                <input
                  onChange={handleChangeImage}
                  type="file"
                  id="customFile"
                  className="form-control"
                  accept="image/*"
                />
                <div className='mt-3' style={{ height: '50px', width: '50px' }}>
                  <img width={"100%"} src={previewUrl ?? '/assets/images/no-image.jpeg'} alt="" />
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col">
            <div className="mb-3">
                <label class="form-label" htmlFor="basicpill-address-input">Description du {productOrService}</label>
                <textarea {...register("description")} id="basicpill-address-input" className="form-control" rows={4}></textarea>
            </div>
          </div> */}
        </div>
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default NewProductForm;
