import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../services/urls';
import useSWR from 'swr';
import LoaderPage from '../../components/LoaderPage';
import moment from 'moment';


const Details = () => {
    const { productId } = useParams();
    const { data: product, isLoading, mutate } = useSWR(URLS.PRODUCTS.details(productId));
    const { data: stocks, isLoading: loading } = useSWR(URLS.STOCKS.list(productId, "", 1));
  

    return (
        <section className="product-container">
            {isLoading && <LoaderPage />}
            <div className="py-5">
                <div className="card">
                    <div className="card-body">
                        <div className="p-3">
                            <h3>
                                Détails du {product?.type === "SERVICE" ? "service" : "produit"}
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="img-card">
                                    <img src={product?.image ?? "/assets/images/no-image.jpeg"} alt="" id="featured-image"/>
                                    {/* <div className="small-Card">
                                        <img src="img/image-1.png" alt="" className="small-Img"/>
                                        <img src="img/small-img-2.png" alt="" className="small-Img"/>
                                        <img src="img/small-img-3.png" alt="" className="small-Img"/>
                                        <img src="img/image-1.png" alt="" className="small-Img"/>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-6 col-12">

                                <div className="product-info">
                                    <p className="category-text m-0">{product?.category}</p>
                                    <h3>{product?.title} {product?.type === "PRODUCT" && <span className={`ms-3 rounded px-3 small bg-${product?.in_stock ? 'success' : 'danger'}`}>{product?.in_stock ? 'En stock' : 'Stock épuisé'}</span>} </h3>
                                    <h5>Prix: {product?.price} 
                                    {/* <del>$170</del> */}
                                    </h5>
                                    <p>
                                        {product?.description}
                                    </p>
                        {/* 
                                    <div className="sizes">
                                        <p>Size:</p>
                                        <select name="Size" id="size" className="size-option">
                                            <option value="xxl">XXL</option>
                                            <option value="xl">XL</option>
                                            <option value="medium">Medium</option>
                                            <option value="small">Small</option>
                                        </select>
                                    </div>

                                    <div className="quantity">
                                        <input type="number" value="1" min="1"/>
                                        <button>Add to Cart</button>
                                    </div> */}

                                    <div>
                                        {/* <p>Delivery:</p>
                                        <p>Free standard shipping on orders over $35 before tax, plus free returns.</p> */}
                                        <div className="delivery">
                                            <p>Prix d'achat: </p> <p>{product?.buy_price}</p>
                                        </div>
                                        <hr/>
                                        <div className="delivery">
                                            <p>Marge: </p> 
                                            <p>{product?.price - product?.buy_price}</p>
                                        </div>
                                        <hr/>
                                        {
                                            product?.type === "PRODUCT" && <>
                                                <div className="delivery">
                                                    <p>Etat du stock: </p> 
                                                    <p>{product?.quantity}</p>
                                                </div>
                                                <hr/>
                                            </>
                                        }
                                        <div className="delivery">
                                            <p>Date de création: </p> 
                                            <p>{moment(product?.created_at).format("lll")}</p>
                                        </div>
                                        <hr/>
                                        {
                                            product?.type === "PRODUCT" && <>
                                                <div className="delivery">
                                                    <p>Date de dernier réapprovisionnement:</p> 
                                                    <p>{moment(product?.latest_restocking).format("lll")}</p>
                                                </div>
                                                <hr/>
                                            </>
                                        }
                                        <div className="delivery">
                                            <p>Date de derniere modification:</p> 
                                            <p>{moment(product?.modified_at).format("lll")}</p>
                                        </div>
                                        <hr/>
                                        <div className="delivery">
                                            <p>Créé par: </p> 
                                            <p>{product?.created_by?.first_name ?? ""} {" "} {product?.created_by?.last_name ?? ""} </p>
                                        </div>
                                        <hr/>
                                        <div className="delivery">
                                            <p>Modifé par: </p> 
                                            <p>{product?.modified_by?.first_name ?? ""} {" "} {product?.modified_by?.last_name ?? ""} </p>
                                        </div>
                                        <hr/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {product?.type === "PRODUCT" && <div>
                            <h3>Historique du stock</h3>
                            <div className="py-4">
                            <div className="table-responsive">
                                            <table className="table table-hover mb-0">
        
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Produit</th>
                                                        <th>Prix d'achat</th>
                                                        <th>Prix de vente</th>
                                                        <th>Entrée / Sortie</th>
                                                        <th>Executé par</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        stocks?.results?.map((stock, index) => (

                                                            <tr>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{stock.product.title}</td>
                                                                <td>{stock.buy_price}</td>
                                                                <td>{stock.sell_price}</td>
                                                                <td>{stock.side === "IN" ? "Entrée" : "Sortie"}</td>
                                                                <td>{stock?.created_by?.first_name} {stock?.created_by?.last_name}</td>
                                                                <td>{moment(stock.created_at).format("lll")}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
    </section>
    )
}

export default Details;