import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useSWR from 'swr';
import { URLS } from "../../services/urls";
import moment from "moment";
import ManageCategory from "./components/categories/Managecategory";
import { useState } from "react";
import ManagePlaces from "./components/places/ManagePlaces";




const Places = () => {
    const [toEdit, setToEdit] = useState();

    const { active_agency } = useSelector((state) => state.agencies);

    const {
        data: categories,
        isLoading,
        mutate,
      } = useSWR(URLS.STOCKS.PLACES.list(active_agency?.id, "", 1, ``));


    const handleSuccess = () => {
        mutate();
        setToEdit();
    }

    return (
        
        <div className="row">
            <div className="col-12">
        <nav aria-label="breadcrumb" className="main-breadcrumb card">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Emplacements
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-md-6 col-12">
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered text-uppercase">
                            <thead>
                            <tr className="bg-light">
                        <th>Nom</th>
                        <th>Date de Création</th>
                        <th>Actions</th>
                        {/* {!me && <th>Actions</th>} */}
                        </tr>
                            </thead>
                            <tbody>
                                {
                                    categories?.results?.map((cat) => {
                                        return (
                                            <tr key={'cat-' + cat.id}>
                                                <td>{cat.name}</td>
                                                <td>{moment(cat.created_at).format("DD/MM/YYYY")} <span className="ms-3">{moment(cat.created_at).format("LT")}</span></td>
                                                <td>
                                                <Link to="#" onClick={() => setToEdit(cat)} className="btn btn-success btn-sm">
                                                    <i className="mdi mdi-pencil"></i>
                                                </Link>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
      </div>
      <div className="col-md-6 col-12">
        <div>
            <div className="card">
                <div className="card-body">
                    <h4 className="text-uppercase">
                        {
                            !toEdit ? 'Nouvel Emplacement' :
                            'Modification de l\'Emplacement'
                        }
                    </h4>
                    <div className="mt-5">
                        <ManagePlaces toEdit={toEdit} onSuccess={handleSuccess} />
                    </div>
                </div>
            </div>
        </div>
      </div>
        </div>
    )
}


export default Places;