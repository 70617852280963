import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading_sortie_caisse_request: false,
    loading_sortie_caisse_success: false,
    loading_sortie_caisse_failed: false,
    sorties_caisse: [],
    active_agency: null,
}

export const agencySlice = createSlice({
  name: 'sortieCaisse',
  initialState,
  reducers: {
    loadingRequest: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loading_sortie_caisse_request = true;
      state.loading_sortie_caisse_success = false;
      state.loading_sortie_caisse_failed = false;
    },
    loadingSuccess: (state, action) => {
        state.loading_sortie_caisse_request = false;
        state.loading_sortie_caisse_success = true;
        state.loading_sortie_caisse_failed = false;
        state.sorties_caisse = action.payload
    },
    loadingFailed: (state) => {
        state.loading_sortie_caisse_request = false;
        state.loading_sortie_caisse_success = false;
        state.loading_sortie_caisse_failed = true;
    },
    selectAnAgency: (state, action) => {
      state.active_agency = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { loadingRequest, loadingSuccess, loadingFailed, selectAnAgency } = agencySlice.actions

export default agencySlice.reducer