import { Outlet } from "react-router-dom"





const Repports = () => {

    return (
        <div>
            <Outlet />
        </div>
    )
}

export default Repports;