import React, { useRef, useEffect } from 'react';
import './styles.css';
import { OPENING_HOURS, getHoursList } from '../utils/consts';

const DatePicker = ({ register, name, required, disabled, setValue, getValue }) => {
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState();


  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(!open);
  };

  const handleChange = (value, next) => {
    setValue(name, value);
    if (name == 'startTime' && next) {
      setValue('endTime', next);
    }
    setOpen(!open);
  };

  // useEffect(() => {
  //   window?.$("body").on("click", function(){
  //     setOpen(false)
  //   })
  // }, [])
  
  const divRef = useRef(null);

  useEffect(() => {
    // Add event listener to detect clicks outside the div
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup the event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      // Click occurred outside the div
      // console.log('Clicked outside');
      setOpen(false)
    }
  };

  return (
    <div className="dropdown">
      <div className="position-relative">
        <span className="position-absolute full-left ps-2 pt-2">
          <span class="material-symbols-outlined fs-4">schedule</span>
        </span>
        {/* Just to display the value to the user */}
        <input
          type="time"
          className="form-control ps-5 cursor-pointer"
          id={'floatingTimeInput' + name}
          {...register(name, { required })}
          placeholder=""
          disabled={disabled}
          onClick={handleOpen}
        />
      </div>
      {open ? (
        <ul className="menu" ref={divRef}>
          {getHoursList().map((hour, index) => {
            // Une periode de 15 minnute signifie un quart d'heure
            return (
              <li className="menu-item" key={'date-' + name + index} >
                <button
                  onClick={() =>
                    handleChange(hour, hour != '19:15' ? getHoursList()[index + 2] : '19:45')
                  }
                  className={getValue(name) === hour ? 'active' : ''}
                >
                  {hour}
                </button>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export default DatePicker;
