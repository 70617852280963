import MetisMenu from '@metismenu/react';
import { navItems } from '../routes/routeItems';
import MenuItem from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAnAgency } from '../../store/slices/agencies';
import { useEffect, useState } from 'react';
import { handleToggleSidbar } from '../utils/functions';
import { formatMenu } from './utils/utils';
import { ROLE_ADMIN, ROLE_CHEF_AGENCE, ROLE_RESPONSABLE_EXPLOITATION } from '../utils/consts';


const allowed = ["ADMIN", "ADMIN"];
const admins = ROLE_RESPONSABLE_EXPLOITATION;

const dashboardItem = {
  id: "2",
  title: "Tableau de bord",
  children: [],
  icon: "dashboard",
  link: "",
  group: [...ROLE_ADMIN, ...ROLE_CHEF_AGENCE],
  all_agencies: "also_simple_agency",
}

const Sidebar = () => {

  const [allAgencies, setAllAgencies] = useState(true);

  const { auth } = useSelector((state) => state.auth);

  const { agencies, active_agency } = useSelector((state) => state.agencies);

  const dispatch = useDispatch();

  const handleChangeAgency = (agency) => {
    dispatch(selectAnAgency(agency));
  };

  useEffect(() => {
    if (agencies?.length > 0 && auth?.user && auth?.user?.is_superuser) {
      dispatch(selectAnAgency({ id: "", name: "Global", address: "Rapport Global" }));
    }
    console.log("agencies =======> ", agencies)
  }, [agencies]);

  useEffect(() => {
    if(active_agency) {
      console.log("active_agency =======> ", active_agency, allAgencies);
      setAllAgencies(active_agency?.name === "Global");
    }
    window.$(".disabled")?.on("click", function (event) {
      event?.preventDefault();
    });

  }, [active_agency]);

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link href="/" className="logo logo-dark">
          <span className="logo-sm">
            <img
              src="/assets/images/dark-favicon.png"
              alt=""
              height="60"
              className="auth-logo logo-light mx-auto"
            />
          </span>
          <span className="logo-lg">
            <img
              src="/assets/images/new-logo-text.png"
              alt=""
              height="60"
              className="auth-logo logo-light mx-auto"
            />
          </span>
        </Link>

        <a href="/" className="logo logo-light">
          <span className="logo-sm">
            <img
              src="/assets/images/dark-favicon.png"
              alt=""
              height="50"
              className="auth-logo logo-light"
            />
          </span>
          <span className="logo-lg py-2">
            <img
              src="/assets/images/new-logo-text.png"
              alt=""
              height="60"
              className="auth-logo logo-light mx-auto"
            />
          </span>
        </a>
        <button
          type="button"
          className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
          id="vertical-menu-btn"
          onClick={handleToggleSidbar}
        >
          <i className=" ri-arrow-left-s-line align-middle"></i>
        </button>
      </div>


      <div data-simplebar className="vertical-scroll">
        <div id="sidebar-menu">
          <div className="dropdown mx-3 sidebar-user user-dropdown select-dropdown">
            <button
              type="button"
              className="btn btn-light w-100 waves-effect waves-light border-0"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <div className="avatar-xs rounded-circle flex-shrink-0">
                    <div className="avatar-title border bg-light text-primary rounded-circle text-uppercase user-sort-name">
                      {active_agency?.name[0]}
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 ms-2 text-start">
                  <h6 className="mb-1 fw-medium user-name-text"> {active_agency?.name} </h6>
                  <p className="font-size-13 text-muted user-name-sub-text mb-0">
                    {' '}
                    {active_agency?.address}{' '}
                  </p>
                </div>
                <div className="flex-shrink-0 text-end">
                  <i className="mdi mdi-chevron-down font-size-16"></i>
                </div>
              </span>
            </button>
            <hr />
            <div className="dropdown-menu dropdown-menu-end w-100">
              {agencies.length === 0 && (
                <Link className="dropdown-item d-flex align-items-center px-3" to="#">
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs rounded-circle flex-shrink-0">
                      <div className="avatar-title border rounded-circle text-uppercase dropdown-sort-name">
                        {active_agency?.name[0]}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 dropdown-name">{active_agency?.name}</h6>
                    <p className="text-muted font-size-13 mb-0 dropdown-sub-desc">
                      {active_agency?.address}
                    </p>
                  </div>
                </Link>
              )}
              {admins.includes(auth?.user?.user_level) && <Link
                  className="dropdown-item d-flex align-items-center px-3"
                  to="#"
                  onClick={() => handleChangeAgency({ id: "", name: "Global", address: "Rapport Global" })}
                >
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs rounded-circle flex-shrink-0">
                      <div className="avatar-title border rounded-circle text-uppercase dropdown-sort-name">
                        {"G"}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 dropdown-name">Global</h6>
                    <p className="text-muted font-size-13 mb-0 dropdown-sub-desc">
                      Rapport Global
                    </p>
                  </div>
                </Link>}
              { admins.includes(auth?.user?.user_level) && agencies.map((agency) => (
                <Link
                  key={'meu' + agency.id}
                  className="dropdown-item d-flex align-items-center px-3"
                  to="#"
                  onClick={() => handleChangeAgency(agency)}
                >
                  <div className="flex-shrink-0 me-2">
                    <div className="avatar-xs rounded-circle flex-shrink-0">
                      <div className="avatar-title border rounded-circle text-uppercase dropdown-sort-name">
                        {agency.name[0]}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 dropdown-name">{agency.name}</h6>
                    <p className="text-muted font-size-13 mb-0 dropdown-sub-desc">
                      {agency.address}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          {
            (auth?.user && !admins.includes(auth?.user?.user_level)) &&
            <MetisMenu toggle={false} id="side-menu-218" className="list-unstyled">
              {navItems.map((item) => (
                // item?.group?.includes(auth?.user?.user_level) ? 
                <MenuItem key={'mitemop' + item?.id} item={item} />
                // <div/>
              ))}
            </MetisMenu>
          }
          {/* Admin and not allAgencies */}
          {
            (auth?.user && admins.includes(auth?.user?.user_level) && !allAgencies) &&
            <MetisMenu toggle={false} id="side-menu-2adminnotall" className="list-unstyled">
                <MenuItem item={dashboardItem} />
              {formatMenu(navItems, false, auth?.user?.user_level).map((item) => (
                // item?.group?.includes(auth?.user?.user_level) ? 
                <MenuItem key={'mitem' + item?.id} item={item} />
                // <div/>
              ))}
            </MetisMenu>
          }
          {/* Admin and  allAgencies */}
          {
            (auth?.user && admins.includes(auth?.user?.user_level) && allAgencies) &&
            <MetisMenu toggle={false} id="side-menu-2adminall" className="list-unstyled">
              {/* Add dashbboard by default */}
                <MenuItem item={dashboardItem} />
              {formatMenu(navItems, true, auth?.user?.user_level).map((item) => (
                // item?.group?.includes(auth?.user?.user_level) ? 
                <MenuItem key={'mitem' + item?.id} item={item} />
                // <div/>
              ))}
            </MetisMenu>
          }
          {/* <ul className="metismenu list-unstyled" id="side-menu">

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-comment-message"></i>
                <span>Apps</span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Email
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="email-inbox.html">Inbox</a>
                    </li>
                    <li>
                      <a href="email-read.html">Read Email</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="calendar.html">Calendar</a>
                </li>

                <li>
                  <a href="apps-chat.html">Chat</a>
                </li>

                <li>
                  <a href="apps-file-manager.html">File Manager</a>
                </li>

                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Invoice
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="invoices.html">Invoices</a>
                    </li>
                    <li>
                      <a href="invoice-detail.html">Invoice Detail</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Users
                  </a>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <a href="users-list.html">Users List</a>
                    </li>
                    <li>
                      <a href="users-detail.html">Users Detail</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-window-grid"></i>
                <span>Layouts</span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Vertical
                  </a>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <a href="layouts-dark-sidebar.html">Dark Sidebar</a>
                    </li>
                    <li>
                      <a href="layouts-light-sidebar.html">Light Sidebar</a>
                    </li>
                    <li>
                      <a href="layouts-compact-sidebar.html">Compact Sidebar</a>
                    </li>
                    <li>
                      <a href="layouts-icon-sidebar.html">Icon Sidebar</a>
                    </li>
                    <li>
                      <a href="layouts-boxed.html">Boxed Layout</a>
                    </li>
                    <li>
                      <a href="layouts-preloader.html">Preloader</a>
                    </li>
                  </ul>
                </li>

                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Horizontal
                  </a>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <a href="layouts-horizontal.html">Horizontal</a>
                    </li>
                    <li>
                      <a href="layouts-hori-light-header.html">Light Header</a>
                    </li>
                    <li>
                      <a href="layouts-hori-topbar-dark.html">Topbar Dark</a>
                    </li>
                    <li>
                      <a href="layouts-hori-boxed-width.html">Boxed width</a>
                    </li>
                    <li>
                      <a href="layouts-hori-preloader.html">Preloader</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li className="menu-title">Pages</li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-sign-in-alt"></i>
                <span>Authentication</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="auth-login.html">Login</a>
                </li>
                <li>
                  <a href="auth-register.html">Register</a>
                </li>
                <li>
                  <a href="auth-recoverpw.html">Recover Password</a>
                </li>
                <li>
                  <a href="auth-lock-screen.html">Lock Screen</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-box"></i>
                <span>Extra Pages</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="pages-starter.html">Starter Page</a>
                </li>
                <li>
                  <a href="pages-maintenance.html">Maintenance</a>
                </li>
                <li>
                  <a href="pages-comingsoon.html">Coming Soon</a>
                </li>
                <li>
                  <a href="pages-404.html">Error 404</a>
                </li>
                <li>
                  <a href="pages-500.html">Error 500</a>
                </li>
                <li>
                  <a href="pages-faq.html">(Help Center) FAQ</a>
                </li>
                <li>
                  <a href="pages-profile.html">Profile</a>
                </li>
                <li>
                  <a href="pages-pricing.html">Pricing</a>
                </li>
                <li>
                  <a href="pages-terms-conditions.html">Terms & Conditions</a>
                </li>
              </ul>
            </li>

            <li className="menu-title">Components</li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-layer-group"></i>
                <span>UI Elements</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="ui-alerts.html">Alerts</a>
                </li>
                <li>
                  <a href="ui-buttons.html">Buttons</a>
                </li>
                <li>
                  <a href="ui-cards.html">Cards</a>
                </li>
                <li>
                  <a href="ui-carousel.html">Carousel</a>
                </li>
                <li>
                  <a href="ui-dropdowns.html">Dropdowns</a>
                </li>
                <li>
                  <a href="ui-grid.html">Grid</a>
                </li>
                <li>
                  <a href="ui-images.html">Images</a>
                </li>
                <li>
                  <a href="ui-lightbox.html">Lightbox</a>
                </li>
                <li>
                  <a href="ui-modals.html">Modals</a>
                </li>
                <li>
                  <a href="ui-offcanvas.html">Offcavas</a>
                </li>
                <li>
                  <a href="ui-rangeslider.html">Range Slider</a>
                </li>
                <li>
                  <a href="ui-roundslider.html">Round Slider</a>
                </li>
                <li>
                  <a href="ui-session-timeout.html">Session Timeout</a>
                </li>
                <li>
                  <a href="ui-progressbars.html">Progress Bars</a>
                </li>
                <li>
                  <a href="ui-sweet-alert.html">Sweetalert 2</a>
                </li>
                <li>
                  <a href="ui-tabs-accordions.html">Tabs & Accordions</a>
                </li>
                <li>
                  <a href="ui-typography.html">Typography</a>
                </li>
                <li>
                  <a href="ui-video.html">Video</a>
                </li>
                <li>
                  <a href="ui-general.html">General</a>
                </li>
                <li>
                  <a href="ui-rating.html">Rating</a>
                </li>
                <li>
                  <a href="ui-notifications.html">Notifications</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="waves-effect">
                <i className="uim uim-document-layout-left"></i>
                <span className="badge rounded-pill bg-danger float-end">6</span>
                <span>Forms</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="form-elements.html">Basic Elements</a>
                </li>
                <li>
                  <a href="form-validation.html">Validation</a>
                </li>
                <li>
                  <a href="form-plugins.html">Plugins</a>
                </li>
                <li>
                  <a href="form-editors.html">Editors</a>
                </li>
                <li>
                  <a href="form-uploads.html">File Upload</a>
                </li>
                <li>
                  <a href="form-wizard.html">Wizard</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-table"></i>
                <span>Tables</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="tables-bootstrap.html">Bootstrap Tables</a>
                </li>
                <li>
                  <a href="tables-datatable.html">Data Tables</a>
                </li>
                <li>
                  <a href="tables-editable.html">Editable Table</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-chart-pie"></i>
                <span>Charts</span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Apexcharts Part 1
                  </a>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <a href="charts-line.html">Line</a>
                    </li>
                    <li>
                      <a href="charts-area.html">Area</a>
                    </li>
                    <li>
                      <a href="charts-column.html">Column</a>
                    </li>
                    <li>
                      <a href="charts-bar.html">Bar</a>
                    </li>
                    <li>
                      <a href="charts-mixed.html">Mixed</a>
                    </li>
                    <li>
                      <a href="charts-timeline.html">Timeline</a>
                    </li>
                    <li>
                      <a href="charts-candlestick.html">Candlestick</a>
                    </li>
                    <li>
                      <a href="charts-boxplot.html">Boxplot</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Apexcharts Part 2
                  </a>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <a href="charts-bubble.html">Bubble</a>
                    </li>
                    <li>
                      <a href="charts-scatter.html">Scatter</a>
                    </li>
                    <li>
                      <a href="charts-heatmap.html">Heatmap</a>
                    </li>
                    <li>
                      <a href="charts-treemap.html">Treemap</a>
                    </li>
                    <li>
                      <a href="charts-pie.html">Pie</a>
                    </li>
                    <li>
                      <a href="charts-radialbar.html">Radialbar</a>
                    </li>
                    <li>
                      <a href="charts-radar.html">Radar</a>
                    </li>
                    <li>
                      <a href="charts-polararea.html">Polararea</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="charts-echart.html">E Charts</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-object-ungroup"></i>
                <span>Icons</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="icons-remix.html">Remix Icons</a>
                </li>
                <li>
                  <a href="icons-materialdesign.html">Material Design</a>
                </li>
                <li>
                  <a href="icons-unicons.html">Unicons</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-comment-plus"></i>
                <span>Maps</span>
              </a>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <a href="maps-google.html">Google Maps</a>
                </li>
                <li>
                  <a href="maps-vector.html">Vector Maps</a>
                </li>
              </ul>
            </li>

            <li>
              <a href="javascript: void(0);" className="has-arrow waves-effect">
                <i className="uim uim-layers-alt"></i>
                <span>Multi Level</span>
              </a>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <a href="javascript: void(0);">Level 1.1</a>
                </li>
                <li>
                  <a href="javascript: void(0);" className="has-arrow">
                    Level 1.2
                  </a>
                  <ul className="sub-menu" aria-expanded="true">
                    <li>
                      <a href="javascript: void(0);">Level 2.1</a>
                    </li>
                    <li>
                      <a href="javascript: void(0);">Level 2.2</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul> */}
        </div>
      </div>

      {/* <div className="dropdown px-3 sidebar-user sidebar-user-info">
        <button
          type="button"
          className="btn w-100 px-0 border-0"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                src="/assets/images/users/avatar-2.jpg"
                className="img-fluid header-profile-user rounded-circle"
                alt=""
              />
            </div>

            <div className="flex-grow-1 ms-2 text-start">
              <span className="ms-1 fw-medium user-name-text">
                {auth?.user?.first_name} {auth?.user?.last_name}
              </span>
            </div>

            <div className="flex-shrink-0 text-end">
              <i className="mdi mdi-dots-vertical font-size-16"></i>
            </div>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <a className="dropdown-item" href="pages-profile.html">
            <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Profile</span>
          </a>
          <a className="dropdown-item" href="apps-chat.html">
            <i className="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Messages</span>
          </a>
          <a className="dropdown-item" href="pages-faq.html">
            <i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Help</span>
          </a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="pages-profile.html">
            <i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </a>
          <a className="dropdown-item" href="#">
            <span className="badge bg-primary mt-1 float-end">New</span>
            <i className="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Settings</span>
          </a>
          <a className="dropdown-item" href="auth-lock-screen.html">
            <i className="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>{' '}
            <span className="align-middle">Lock screen</span>
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Sidebar;
