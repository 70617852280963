import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import CLoader from '../../../components/Loader';

const ManageSalary = ({ type, toEdit, onSuccess, handleClose, employee }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    let formData = new FormData()
    
    formData.append("montant", data.amount);
    formData.append("employee", employee.id);
    formData.append("type", data.type);
    formData.append("created_by", auth?.user?.id);
    formData.append("managed_by", auth?.user?.id);
    formData.append("agency", active_agency.id);
    
    if (toEdit) {
      setLoading(true);
      API.patch(URLS.SALARY.update(toEdit.id), formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`La modification sur salaire a bien été prise en compte`);
          setLoading(false);
          reset();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.SALARY.create, formData)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success(`La modification de salaire a bien été prise en compte`);
          setLoading(false);
          reset();
          
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              `Une erreur s'est produite lors de l'ajout, veuiller reésayer !`
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

  };


  useEffect(() => {
    if (toEdit) {
      reset({
        name: toEdit.name,
      });
    }
  }, [toEdit]);

  useEffect(() => {
    window?.bsCustomFileInput?.init();
  }, []);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Montant</label>
              <input
                type="text"
                className="form-control"
                id="floatingFirstnameInput"
                {...register('amount', {required: true})}
                placeholder={'Montant'}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingTypeInput">Type de Modification</label>
              <select className="form-control" {...register('type', {required: true})} id="docType">
                <option value="">Choisir</option>
                <option value="AVANCE_SALAIRE">Avance sur Salaire</option>
                <option value="RETENUE_SALAIRE">Retenue sur Salaire</option>
                <option value="PRODUITS">Produits</option>
                <option value="PRIME_EXCEPTIONNELLE">Prime Exceptionnelle</option>
              </select>
            </div>
          </div>
        </div>
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="submit"
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ManageSalary
