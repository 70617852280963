import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API } from '../services/axios';
import { URLS } from '../services/urls';
import { loadingSuccess } from '../store/slices/sortieCaisse';
import useSWR from 'swr';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleToggleSidbar } from './utils/functions';
import axios from 'axios';

const Navbar = () => {
  const { active_agency } = useSelector((state) => state.agencies);
  const { auth } = useSelector((state) => state.auth);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: notifications, isLoading, mutate } = useSWR(URLS.NOTIFICATION.my(''));

  const handleLogout = () => {
    axios
      .get(URLS.BASE_URL + URLS.AUTHS.LOGOUT, {
        headers: { 'Client-Id': URLS.CLIENT_ID },
      })
      .then((reps) => {
        window.localStorage.removeItem('user-details');
        navigate('/login');
      });
  };

  const dispatch = useDispatch();

  const handleReadNotif = (notification) => {
    const data = {
      is_readed: true,
      readed_by: auth?.user?.id,
    };
    API.patch(URLS.NOTIFICATION.update(notification.id), data)
      .then((resp) => {
        mutate();
      })
      .catch((error) => {});
  };

  const handleChangeLanguage = (event, lng) => {
    event.preventDefault();
  };

  useEffect(() => {
    // API.get(URLS.TRANSFERT.list(active_agency?.id, '', 1, '&sortie_caisse=true'))
    API.get(URLS.SEARCH.transactions(active_agency?.id, '', 1, '&sortie_caisse=true'))
      .then((resp) => {
        dispatch(loadingSuccess(resp.data));
      })
      .catch((error) => {});
  }, []);

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="#" className="logo logo-dark">
              <span className="logo-sm">
                <img src="/assets/images/logo-dark.png" alt="logo-sm-dark" height="24" />
              </span>
              <span className="logo-lg">
                <img
                  src={process.env.PUBLIC_URL + '/assets/images/logo-sm-dark.png'}
                  alt="logo-dark"
                  height="25"
                />
              </span>
            </Link>

            <Link href="#" className="logo logo-light">
              <span className="logo-sm">
                <img src="/assets/images/logo-light.png" alt="logo-sm-light" height="24" />
              </span>
              <span className="logo-lg">
                <img src="/assets/images/logo-sm-light.png" alt="logo-light" height="25" />
              </span>
            </Link>
          </div>

          <button
            type="button"
            onClick={handleToggleSidbar}
            className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn"
            id="vertical-menu-btn"
          >
            <i className="ri-menu-2-line align-middle"></i>
          </button>

          <div className="page-title-box align-self-center d-none d-md-block"></div>
        </div>

        <div className="d-flex">
          <div className="dropdown d-inline-block d-lg-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="ri-search-line"></i>
            </button>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-search-dropdown"
            >
              <form className="p-3">
                <div className="mb-3 m-0">
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search ..." />
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="submit">
                        <i className="ri-search-line"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="dropdown d-none d-sm-inline-block me-2">
            <div className="d-flex h-100"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
              <div className="header-rounded m-auto">
                <button
                  type="button"
                  className="btn header-item waves-effect text-center"
                >
                  <img
                    className=""
                    src="/assets/images/flags/cm.png"
                    alt="Header Language"
                    height="22"
                  />
                </button>
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                to="#;"
                onClick={(e) => handleChangeLanguage(e, 'fr')}
                className="dropdown-item notify-item"
              >
                <img
                  src="/assets/images/flags/cm.png"
                  alt="user"
                  className="me-1"
                  height="12"
                />{' '}
                <span className="align-middle">{t('lang_fr')}</span>
              </Link>

              <a
                href="#;"
                onClick={(e) => handleChangeLanguage(e, 'en')}
                className="dropdown-item notify-item"
              >
                <img
                  src="/assets/images/flags/cm.png"
                  width={'19px'}
                  alt="user"
                  className="me-1"
                  height="12"
                />{' '}
                <span className="align-middle">{t('lang_en')}</span>
              </a>
            </div>
          </div>

          <div className="dropdown d-inline-block">
            <div className="d-flex h-100" 
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
              <div className="header-rounded m-auto">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-notifications-dropdown"
                >
                  <i className="ri-notification-3-line"></i>
                  <span className="noti-dot"></span>
                </button>
              </div>
            </div>
            <div
              className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
              aria-labelledby="page-header-notifications-dropdown"
            >
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0 text-uppercase"> Notifications </h6>
                  </div>
                  <div className="col-auto">
                    <Link to="/notifications" className="small">
                      {' '}
                      Voir tout
                    </Link>
                  </div>
                </div>
              </div>
              <div data-simplebar style={{ maxHeight: '330px', overflow: 'scroll' }}>
                {notifications?.results?.map((notification) => (
                  <Link
                    to="#"
                    className={`text-reset notification-item ${
                      notification?.is_readed ? '' : 'badge-soft-primary'
                    } `}
                    onClick={() => handleReadNotif(notification)}
                  >
                    <div className="d-flex badge-soft-primary">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="ri-alarm-line"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <h6 className="mb-1">{notification?.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notification?.body}</p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"></i>{' '}
                            {moment(notification?.created_at).fromNow()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="p-2 border-top">
                <div className="d-grid">
                  <Link
                    className="btn btn-sm btn-link font-size-14 text-center text-uppercase"
                    to="/notifications"
                  >
                    <i className="mdi mdi-arrow-right-circle me-1"></i> Voir Plus..
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3">
            <div className="border-start h-100"></div>
          </div>
          <div className="dropdown d-inline-block px-3 sidebar-user sidebar-user-info">
            <button
              type="button"
              className="btn w-100 h-100 px-0 border-0"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src={`https://ui-avatars.com/api/?name=${auth?.user?.first_name}+${auth?.user?.last_name}`}
                    className="img-fluid header-profile-user rounded-circle"
                    alt=""
                  />
                </div>

                <div className="flex-grow-1 ms-2 text-start">
                  <span className="ms-1 fw-medium user-name-text">
                    {auth?.user?.first_name} {auth?.user?.last_name}
                  </span>
                </div>

                <div className="flex-shrink-0 text-end">
                  <i className="mdi mdi-dots-vertical font-size-16"></i>
                </div>
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link className="dropdown-item" to={`/employees/${auth?.user?.id}`}>
                <i className="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i>{' '}
                <span className="align-middle">Mon Profil</span>
              </Link>
              <Link className="dropdown-item" to="/" onClick={handleLogout}>
                <i className="mdi mdi-lock text-muted font-size-16 align-middle me-1"></i>{' '}
                <span className="align-middle">Deconnexion</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
// <div className='nav-head'>
//     <span className='logo'>Redux Store</span>

//     <div>
//         <Link className='nav-link' to="/"> Home </Link>
//         <Link className='nav-link' to="/shop"> Shop </Link>
//         <Link className='nav-link' to="/cart"> Cart </Link>

//     <span className='cart-count'>Cart item: 0</span>
//     </div>

// </div>
