import { Link, useParams } from 'react-router-dom';
import { URLS } from '../../services/urls';
import useSWR from 'swr';
import LoaderPage from '../../components/LoaderPage';
import moment from 'moment';
import { useState } from 'react';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import { DEFAULT_IMAGE } from '../../components/utils/consts';
import NewEmployeeForm from './components/NewEmployeeForm';

import { DatePicker } from 'react-responsive-calendar-picker';

import 'react-responsive-calendar-picker/dist/index.css';
import EmployeeInvoices from './components/EmployeeInvoices';
import ManageAttachments from './components/ManageAttachments';
import { formatLinkString } from '../../components/utils/functions';
import ManageSalary from './components/MannageSalary';
import PageTitle from '../../components/PageTitle';

const formatDate = (date) => {
  if (date) {
    return String(date.toLocaleDateString()).split('/').reverse().join('-');
  } else {
    return '9999-01-01';
  }
};

const EmployeeDetails = () => {
  const [toEdit, setToEdit] = useState();
  const { employeeId } = useParams();
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { data: employee, isLoading, mutate } = useSWR(URLS.USERS.details(employeeId));
  const {
    data: attahcments,
    isLoading: loadingAttachments,
    mutate: mutateAttachments,
  } = useSWR(URLS.ATTACHMENTS.employee(employeeId));
  const {
    data: stats,
    isLoading: loadingStats,
    mutate: refetchStats,
  } = useSWR(
    URLS.STATS.EMPLOYEE.list(employeeId, formatDate(dates.checkin), formatDate(dates.checkout))
  );
  // const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  // const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const handleSuccess = () => {
    setToEdit();
    mutate();
    mutateAttachments();
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
    window.$('#modificationSalaire')?.modal('hide');
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
    window.$('.new-client-modal')?.modal('show');
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.AP.delete(obj.id), t, mutate);
  };

  return (
    <div className="row">
      {isLoading && <LoaderPage />}
      <div className="container">
        <div className="main-body">
          <PageTitle
            title={`${employee?.first_name} ${employee?.last_name}`}
            breadcrumbs={
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Tableau de bord</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/employees/list">Employés</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {employee?.first_name} {employee?.last_name}
                </li>
              </ol>
            }
          />

          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <img src={DEFAULT_IMAGE} alt="Admin" className="rounded-circle" width="150" />
                    <div className="mt-3">
                      <h4>
                        {employee?.first_name} {employee?.last_name}
                      </h4>
                      <p className="text-black mb-1">{employee?.reference}</p>
                      <p className="text-secondary mb-1">
                        {String(employee?.role).replaceAll('_', ' ')}
                      </p>
                      <p className="text-muted font-size-sm">
                        {employee?.agency?.name}, {employee?.agency?.address}
                      </p>
                      {/* <button className="btn btn-primary">Follow</button>
                      <button className="btn btn-outline-primary">Message</button> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="card mt-3 p-5"></div> */}
            </div>
            <div className="col-md-4">
              <div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="mb-0">Email</p>
                      </div>
                      <div className="col-sm-8 text-secondary">{employee?.email}</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="mb-0">Téléphone</p>
                      </div>
                      <div className="col-sm-8 text-secondary">{employee?.phone_number}</div>
                    </div>
                    <hr />
                    {/* <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Agence</p>
                      </div>
                      <div className="col-sm-9 text-secondary">{employee?.agency?.name}</div>
                    </div> */}
                    {/* <hr /> */}
                    <div className="row">
                      <div className="col-sm-4">
                        <p className="mb-0">Adresse</p>
                      </div>
                      <div className="col-sm-8 text-secondary">{employee?.address}</div>
                    </div>
                    {!employee?.is_superuser && (
                      <>
                        <hr />
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Salaire Fixe</p>
                          </div>
                          <div className="col-sm-8 text-secondary">
                            {employee?.salary} <strong>FCFA</strong>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-4">
                            <p className="mb-0">Commissions (%)</p>
                          </div>
                          <div className="col-sm-8 text-secondary">
                            {employee?.comission_percentage}
                          </div>
                        </div>
                      </>
                    )}
                    <hr />
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="d-flex">
                          <div className="me-auto">
                            <Link
                              className="btn btn-primary px-4 rounded-0"
                              to="#"
                              onClick={() => handleEdit(employee)}
                            >
                              Modifier
                            </Link>
                          </div>
                          {!employee?.is_superuser
                      && <div>
                            <Link
                              className="btn btn-primary px-4 rounded-0"
                              to="#"
                              data-bs-toggle="modal"
                              data-bs-target="#modificationSalaire"
                            >
                              Modification Salaire
                            </Link>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- Modif salaire --> */}
                <div
                  className="modal fade"
                  id="modificationSalaire"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Modification Salaire
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <ManageSalary
                          employee={employee}
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal fade new-client-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? "Modifitation de l'employé <<" +
                              (toEdit.first_name ?? '') +
                              ' ' +
                              (toEdit.last_name ?? '') +
                              '>>'
                            : 'Nouvel employé'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewEmployeeForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <div
                  className="modal fade new-file-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabelFile"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabelFile">
                          {toEdit ? '' : 'Nouveau Document'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <ManageAttachments
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          employee={employeeId}
                          // toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!employee?.is_superuser
                      && <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-5">
                        <p className="mb-0">Avance Salaire</p>
                      </div>
                      <div className="col-sm-7 text-secondary">{stats?.avance_salaire} FCFA</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-5">
                        <p className="mb-0">Retenue salaire</p>
                      </div>
                      <div className="col-sm-7 text-secondary">{stats?.retenue_salaire} FCFA</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-5">
                        <p className="mb-0">Prime exeptionnelle</p>
                      </div>
                      <div className="col-sm-7 text-secondary">
                        {stats?.prime_exeptionnelle} FCFA
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-5">
                        <p className="mb-0">Matricule CNPS</p>
                      </div>
                      <div className="col-sm-7 text-secondary">{employee?.matricule_cnps}</div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-5">
                        <p className="mb-0 text-uppercase">Niu</p>
                      </div>
                      <div className="col-sm-7 text-secondary">{employee?.niu}</div>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <h6>FICHIERS</h6>
                      <a
                        href="#"
                        className="text-uppercase ms-auto"
                        data-bs-toggle="modal"
                        data-bs-target=".new-file-modal"
                      >
                        Ajouter
                      </a>
                    </div>
                    {attahcments?.results?.map((att) => (
                      <a href="#" className="bg-light d-block p-2 mb-3">
                        <div className="d-flex">
                          <p className="mb-0">{String(att?.document_type).replace('_', ' ')}</p>
                          <small className="ms-auto">
                            {moment(att?.created_at).format('DD/MM/YYYY HH:MM')}
                          </small>
                        </div>
                        <span>
                          <small>{formatLinkString(att?.document)}</small>
                        </span>
                      </a>
                    ))}
                  </div>
                </div>}
              </div>
            </div>
            {!employee?.is_superuser
                      && <div className="col-12">
              <div className="row">
                <div>
                  <h2 className="text-uppercase">RAPPORT DE PERFORMANCE</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div>
                    <div className="row gutters-sm">
                      <div className="col-12">
                        <div className="py-3 position-relative" style={{ zIndex: '1' }}>
                          <DatePicker
                            dates={dates}
                            setDates={setDates}
                            open={open}
                            setOpen={setOpen}
                            normal={true}
                          >
                            <button
                              className="btn btn-primary rounded-0"
                              onClick={() => setOpen(!open)}
                            >
                              Stats du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                              {dates.checkout && 'au'}{' '}
                              {dates.checkout && dates.checkout.toLocaleDateString()}
                            </button>
                          </DatePicker>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-md flex-shrink-0">
                                <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                  <span className="uim-svg">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                    >
                                      <rect
                                        width="20"
                                        height="15"
                                        x="2"
                                        y="3"
                                        className="uim-tertiary"
                                        rx="3"
                                      ></rect>
                                      <path
                                        className="uim-primary"
                                        d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden ms-4">
                                <p className="text-muted text-truncate font-size-15 mb-2">
                                  {' '}
                                  Nombre de prestations{' '}
                                </p>
                                <h3 className="fs-4 flex-grow-1 mb-3">{stats?.nbre_prestations}</h3>
                                {/* <p className="text-muted mb-0 text-truncate">
                            <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                              <i className="mdi mdi-arrow-top-right"></i> 23% Increase
                            </span>{' '}
                            vs last month
                          </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-md flex-shrink-0">
                                <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                  <span className="uim-svg">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                    >
                                      <path
                                        className="uim-primary"
                                        d="M10,6V5h4V6h2V5a2.00229,2.00229,0,0,0-2-2H10A2.00229,2.00229,0,0,0,8,5V6Z"
                                      ></path>
                                      <path
                                        className="uim-quaternary"
                                        d="M9 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 9 15zM15 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 15 15z"
                                      ></path>
                                      <path
                                        className="uim-tertiary"
                                        d="M20,6H4A2,2,0,0,0,2,8v3a2,2,0,0,0,2,2H8V12a1,1,0,0,1,2,0v1h4V12a1,1,0,0,1,2,0v1h4a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Z"
                                      ></path>
                                      <path
                                        className="uim-primary"
                                        d="M20,13H16v1a1,1,0,0,1-2,0V13H10v1a1,1,0,0,1-2,0V13H4a2,2,0,0,1-2-2v8a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V11A2,2,0,0,1,20,13Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden ms-4">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <p className="text-muted text-truncate font-size-15 mb-2">
                                        {' '}
                                        Total des Services
                                      </p>
                                      <h3 className="fs-4 flex-grow-1 mb-3">
                                        {stats?.services_ca}{' '}
                                        <span className="text-muted font-size-16">FCFA</span>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div>
                                      <p className="text-muted text-truncate font-size-15 mb-2">
                                        {' '}
                                        Total des Produits
                                      </p>
                                      <h3 className="fs-4 flex-grow-1 mb-3">
                                        {stats?.products_ca}{' '}
                                        <span className="text-muted font-size-16">FCFA</span>
                                      </h3>
                                    </div>
                                  </div>
                                </div>

                                {/* <p className="text-muted mb-0 text-truncate">
                            <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                              <i className="mdi mdi-arrow-top-right"></i> 2.8% Increase
                            </span>{' '}
                            vs last month
                          </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-md flex-shrink-0">
                                <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                  <span className="uim-svg">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                    >
                                      <path
                                        className="uim-quaternary"
                                        d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                                      ></path>
                                      <path
                                        className="uim-tertiary"
                                        d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                      ></path>
                                      <path
                                        className="uim-primary"
                                        d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden ms-4">
                                <p className="text-muted text-truncate font-size-15 mb-2">
                                  {' '}
                                  Commissions
                                </p>
                                <h3 className="fs-4 flex-grow-1 mb-3">
                                  {stats?.commission}{' '}
                                  <span className="text-muted font-size-16">FCFA</span>
                                </h3>
                                {/* <p className="text-muted mb-0 text-truncate">
                            <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                              <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                            </span>{' '}
                            vs last month
                          </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div className="avatar-md flex-shrink-0">
                                <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                                  <span className="uim-svg">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="1em"
                                    >
                                      <path
                                        className="uim-quinary"
                                        d="M13.5,9A1.5,1.5,0,1,1,15,7.5,1.50164,1.50164,0,0,1,13.5,9Z"
                                      ></path>
                                      <path
                                        className="uim-tertiary"
                                        d="M19,2H5A3.00879,3.00879,0,0,0,2,5v8.86L5.88,9.98a3.07531,3.07531,0,0,1,4.24,0l2.87139,2.887.88752-.88751a3.00846,3.00846,0,0,1,4.24218,0L22,15.8584V5A3.00879,3.00879,0,0,0,19,2ZM13.5,9A1.5,1.5,0,1,1,15,7.5,1.50164,1.50164,0,0,1,13.5,9Z"
                                      ></path>
                                      <path
                                        className="uim-primary"
                                        d="M10.12,9.98a3.07531,3.07531,0,0,0-4.24,0L2,13.86V19a3.00882,3.00882,0,0,0,3,3H19a2.9986,2.9986,0,0,0,2.16-.92Z"
                                      ></path>
                                      <path
                                        className="uim-quaternary"
                                        d="M22,15.8584l-3.87891-3.87891a3.00849,3.00849,0,0,0-4.24218,0l-.88752.88751,8.16425,8.20856A2.96485,2.96485,0,0,0,22,19Z"
                                      ></path>
                                    </svg>
                                  </span>
                                </span>
                              </div>
                              <div className="flex-grow-1 overflow-hidden ms-4">
                                <p className="text-muted text-truncate font-size-15 mb-2">
                                  {' '}
                                  Pourcentage de participation
                                </p>
                                <h3 className="fs-4 flex-grow-1 mb-3">
                                  {stats?.ca_percentage ?? 0}{' '}
                                  <span className="text-muted font-size-16">%</span>
                                </h3>
                                {/* <p className="text-muted mb-0 text-truncate">
                            <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                              <i className="mdi mdi-arrow-top-right"></i> 4.6% Growth
                            </span>{' '}
                            vs last month
                          </p> */}
                              </div>
                              <div className="flex-shrink-0 align-self-start">
                                <div className="dropdown">
                                  <a
                                    className="dropdown-toggle btn-icon border rounded-circle"
                                    href="#"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="ri-more-2-fill text-muted font-size-16"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" href="#">
                                      Yearly
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Monthly
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Weekly
                                    </a>
                                    <a className="dropdown-item" href="#">
                                      Today
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <EmployeeInvoices employee={employee?.id} dates={dates} employeeData={employee} />
                  {/* <div>
                    <div className="card p-3">
                        <div className="card-body">
                        </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
