import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CLoader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const NewCustomerForm = ({ toEdit, onSuccess, handleClose }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');

  const { auth } = useSelector((state) => state.auth);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    let toSend = {
      first_name: data.firstName,
      last_name: data.lastName,
      reference: 'string',
      email: data.email,
      phone_number: data.phoneNumber,
      birth_date: data.birthDate,
      agency: active_agency.id,
      created_by: auth?.user?.id,
    };
    
    if(data.anonymClient){
        toSend = {
            first_name: "CLIENTS",
            last_name: "DIVERS",
            phone_number: "000 000 000",
            agency: active_agency.id,
        }
    }

    if (toEdit) {
        setLoading(true);
      API.patch(URLS.CUSTOMERS.update((toEdit.id)), toSend)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('Le client a bien été modifié');
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              navigate(`/customers/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              if (handleClose) {
                handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              "Une erreur s'est produite lors de l'ajout du client, veuiller reésayer !"
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.CUSTOMERS.create, toSend)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('Le client a bien été rajouté');
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              navigate(`/customers/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              if (handleClose) {
                handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              "Une erreur s'est produite lors de l'ajout du client, veuiller reésayer !"
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window.$('#submitCustomerForm')?.click();
  };

  useEffect(() => {
    if(toEdit){
        reset({
            firstName: toEdit.first_name,
            lastName: toEdit.last_name,
            phoneNumber: toEdit.phone_number,
            email: toEdit.email,
            birthDate: toEdit.birth_date,
        })
    }
  }, [toEdit]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Nom</label>
              <input
                type="text"
                className="form-control"
                id="floatingFirstnameInput"
                {...register('firstName')}
                placeholder="Nom"
              />
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingLastnameInput">Prénom</label>
              <input
                type="text"
                className="form-control"
                id="floatingLastnameInput"
                {...register('lastName')}
                placeholder="Prénom"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Téléphone</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                {...register('phoneNumber')}
                placeholder="Téléphone"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Addresse email</label>
              <input
                type="email"
                className="form-control"
                id="floatingemailInput"
                {...register('email')}
                placeholder="Adresse email"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Date de naissance</label>
              <input
                type="date"
                className="form-control"
                id="floatingemailInput"
                {...register('birthDate')}
                placeholder="Date de naissance"
              />
            </div>
          </div>
        </div>
        {!toEdit && <div className="row">
          <div className="col">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="formCheck1"
                {...register('anonymClient')}
              />
              <label className="form-check-label" htmlFor="formCheck1">
                Client anonyme
              </label>
              <div className="py-3">
                {watch('anonymClient') && (
                  <div
                    className="alert alert-warning alert-top-border alert-dismissible fade show"
                    role="alert"
                  >
                    <i className="ri-alert-line me-3 align-middle fs-16 text-warning"></i>
                    <span className="fw-medium">Warning</span>- En selectionnant client anonyme, ce
                    client sera enregistré par défaut en tant que « CLIENTS DIVERS » avec pour
                    contact par défaut le « 000 000 000 ».
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>}
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="m-auto"> {toEdit ? "Modifier" : "Ajouter"} </span>{' '}
            {(afterAction === 'CLOSE' && loading) && <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>}
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default NewCustomerForm;
