
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from "swr";
import { URLS } from '../../../../services/urls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { formatNumber, formatPercentage } from '../../../../components/utils/functions';
import CLoader from '../../../../components/Loader';


const GREEN_COLOR = "#71B444";
const RED_COLOR = "#FF6F66";

const PERIODS = ["WEEK", "MONTH", "YEAR"];
const AGENCIES = ["CURRENT", "ALL",];

const dateFromPeriod = (period) => {

    var currentDate = moment();

    switch (period) {
        case "WEEK":
            var weekStart = currentDate.clone().startOf('isoWeek');
            var weekEnd = currentDate.clone().endOf('isoWeek');
            
            return [weekStart.format("YYYY-MM-DD"), weekEnd.format("YYYY-MM-DD")]
        case "MONTH":
            var monthStart = currentDate.clone().startOf('month');
            var monthEnd = currentDate.clone().endOf('month');
            
            return [monthStart.format("YYYY-MM-DD"), monthEnd.format("YYYY-MM-DD")]
        case "YEAR":
            var yearStart = currentDate.clone().startOf('year');
            var yearEnd = currentDate.clone().endOf('year');
            
            return [yearStart.format("YYYY-MM-DD"), yearEnd.format("YYYY-MM-DD")]
        
        default:
            var weekStart2 = currentDate.clone().startOf('isoWeek');
            var weekEnd2 = currentDate.clone().endOf('isoWeek');
            
            return [weekStart2.format("YYYY-MM-DD"), weekEnd2.format("YYYY-MM-DD")];
    }
}

const ProductsCard = () => {
const [agencies, setAgencies] = useState(AGENCIES[0]);
const [count, setCount] = useState(0);
const [percentage, setPercentage] = useState(0);
const [activePeriod, setActivePeriod] = useState(PERIODS[0]);
  const [chartOptions, setChartOptions] = useState({
    // Define your chart options here
    chart: {
        id: 'spark1-products',
        type: 'area',
        height: 80,
        sparkline: {
          enabled: true
        },
      },
      series: [{
        type: "area",
        data: [25, 66, 41, 59, 25, 44, 12, 36, 9, 21]
      }],
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      markers: {
        size: 0
      },
      grid: {
        padding: {
          top: 20,
          bottom: 10,
          left: 110
        }
      },
      colors: [RED_COLOR],
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function formatter(val) {
              return '';
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          opacityFrom: 0.4,
          opacityTo: 0.2
        }
      },
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: stats,
    isLoading,
    mutate: refetchStats,
  } = useSWR(URLS.DASHBOARD.products(dateFromPeriod(activePeriod)[0], dateFromPeriod(activePeriod)[1], active_agency?.id))

  const { t } = useTranslation();
  const handleChangePeriod = (period) => {
    setActivePeriod(period);
    refetchStats()
  }
  const handleChangeAgency = (agenncy) => {
    setAgencies(agenncy)

  }

  useEffect(() => {
    if(agencies === "CURRENT"){
        setCount(stats?.count);
        setPercentage(stats?.variation_percentage)
        setChartOptions((prev) => {
          return {
            ...prev, colors: [stats?.variation_percentage > 0 ? GREEN_COLOR : RED_COLOR],
            series: [
              {
                type: "area",
                data: stats?.data,
              }
            ]
          }
        })
    } else {
        setCount(stats?.all_count);
        setPercentage(stats?.all_variation_percentage)
        setChartOptions((prev) => {
            return {
                ...prev, colors: [stats?.all_variation_percentage > 0 ? GREEN_COLOR : RED_COLOR],
                series: [
                    {
                        type: "area",
                        data: stats?.all_data,
                    }
                ]
            }
        })
    }
  }, [stats, agencies, activePeriod]);

  useEffect(() => {
    if (active_agency && active_agency.id === "") {
      setAgencies("ALL");
    } else {
      setAgencies("CURRENT");
    }
  }, [active_agency]);

  return (
    <div className="col-md-6 col-12 mb-3">
      <div className="c-card">
        <div className="row">
          <div className="col-10">
            <p className="title">{isLoading ? <CLoader show={true} /> : formatNumber(count)} XAF</p>
            <p className="text">Total Des Produits</p>
          </div>
          <div className="col-2">
          <div className="dropdown w-100 d-flex">
        <button
          type="button"
          className="btn border-0 ms-auto p-0"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex">
              <div className="c-menu ms-auto d-flex">
                <i className="mdi mdi-dots-vertical"></i>
              </div>
            </div>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          {
            PERIODS.map((period) => (
                <Link key={'p' + period} className={`dropdown-item ${period === activePeriod ? 'active' : ''}`} to="#" onClick={() => handleChangePeriod(period)}>
                    <span className="align-middle">{t(`THIS_${period}`)}</span>
                </Link>
            ))
          }
          {/* <div className="dropdown-divider"></div>
          {
            AGENCIES.map((agency) => (
                agency !== agencies && <Link key={'ag' + agency} className={`dropdown-item ${agency === agencies ? 'active' : ''}`} to="#" onClick={() => handleChangeAgency(agency)}>
                    <span className="align-middle">{t(`AGENCIES_${agency}`)}</span>
                </Link>
            ))
          } */}
          
        </div>
      </div>
            
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className={`variation ${percentage > 0 ? 'up' : 'down'}`}>{formatPercentage(percentage)}%</p>
            <p className="text">{t(`THIS_${activePeriod}`)}</p>
          </div>
          <div className="col-6">
            <div className='h-100 position-relative'>
                <div className="position-absolute" style={{bottom: 0, right: 0}}>
                    <ReactApexChart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="line"
                        height={100}
                    />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCard;
