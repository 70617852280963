import { useNavigate } from 'react-router-dom';
import NewInvoiceForm from './components/NewInvoiceForm';

const NewInvoice = () => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate('/invoices/list');
  };
  return (
    <div className="row py-5">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="">Nouvelle facture </h3>
              </div>
            </div>
            <div className="py-5">
              <NewInvoiceForm onSuccess={handleSuccess} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvoice;
