


import React, { useState } from "react";
import xlsx from "json-as-xlsx"
import { toast } from "react-toastify";
import CLoader from "../Loader";
import { API, API_DOWNLOAD } from "../../services/axios";
import { formatLinkString } from "../utils/functions";


const ExportExcel = ({label, link}) => {
    
    let callback = function (sheet) {
        toast.success("Le fichier a bien été téléchargé !")
    }


  const [downloading, setDownloading] = useState(false);
  // Export customer file
  const handleExportCustomers = () => {
    setDownloading(true);

    API.get(link).then((response) => {
      setDownloading(false);

      const url = response.data;
        const link = document.createElement('a');
        link.href = formatLinkString(url);
        link.setAttribute('download', '');
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
      setDownloading(false);
    })
  }
        return (
          <button onClick={handleExportCustomers} className="btn btn-gray">
            <span className="d-flex">
              
              <span className="my-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path d="M4.71436 8.14284H10.3586L9.25293 9.25284L9.85721 9.85713L12.0001 7.71427L9.85721 5.57141L9.25293 6.1757L10.3586 7.2857H4.71436V8.14284Z" fill="black"/>
                <path d="M8.57143 5.14286V3.42858C8.57175 3.37218 8.56094 3.31626 8.53962 3.26405C8.51829 3.21183 8.48686 3.16434 8.44714 3.12429L5.44714 0.124293C5.4071 0.0845723 5.3596 0.0531472 5.30739 0.0318195C5.25517 0.0104918 5.19926 -0.000318934 5.14286 7.16327e-06H0.857143C0.629814 7.16327e-06 0.411797 0.090313 0.251051 0.251058C0.0903058 0.411804 0 0.629822 0 0.85715V11.1429C0 11.3702 0.0903058 11.5882 0.251051 11.749C0.411797 11.9097 0.629814 12 0.857143 12H7.71429C7.94161 12 8.15963 11.9097 8.32038 11.749C8.48112 11.5882 8.57143 11.3702 8.57143 11.1429V10.2857H7.71429V11.1429H0.857143V0.85715H4.28571V3.42858C4.28571 3.65591 4.37602 3.87392 4.53677 4.03467C4.69751 4.19542 4.91553 4.28572 5.14286 4.28572H7.71429V5.14286H8.57143ZM5.14286 3.42858V1.03286L7.53857 3.42858H5.14286Z" fill="black"/>
                </svg>
              </span>
                <span className="ms-2 my-auto d-flex">
                  <span className="my-auto">{label} </span>
                  <span className="ms-1"><CLoader size={20} show={downloading} /></span></span>
            </span>
            </button>
        );
    }


export default ExportExcel;