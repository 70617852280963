import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewProductForm from './components/NewProduct';
import ManageStockForm from './components/ManageStockForm';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import { formatNumber } from '../../components/utils/functions';
import BonDeSortie from './components/BonDeSortie';
import StockTransfertForm from './components/StockTransfert';

const exportColumns = [
  {
    label: 'Nom du produit',
    value: 'title',
  },
  {
    label: 'Prix d\'achat',
    value: 'buy_price',
  },
  {
    label: 'Prix de vente',
    value: 'sell_price',
  },
  {
    label: 'Marge',
    value: 'marge',
  },
  {
    label: 'Quantité',
    value: 'quantity',
  },
  {
    label: "Date d'ajout",
    value: 'created_at',
  },
//   {
//     label: 'Solde en compte',
//     value: 'sold',
//   },
  {
    label: 'Cree par',
    value: 'created_by',
  },
];

const exportServices = [
    {
        label: 'Nom du service',
        value: 'title',
      },
      {
        label: 'Prix',
        value: 'price',
      },
      {
        label: 'Categorie',
        value: 'category',
      },
      {
        label: "Date d'ajout",
        value: 'created_at',
      },
    //   {
    //     label: 'Solde en compte',
    //     value: 'sold',
    //   },
      {
        label: 'Cree par',
        value: 'created_by',
      },
]

const paginationComponentOptions = {
  // noRowsPerPage: true,
};

const StockTransfert = ({type, title, isSelledHistory}) => {


    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const [toEdit, setToEdit] = useState();
    const [toEditStock, setToEditStock] = useState();
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [page, setPage] = useState(1);

    const {
      active_agency
    } = useSelector((state) => state.agencies);
  
  
    const { data: transferts, isLoading, mutate } = useSWR(URLS.STOCKS.TRANSFERT.list(active_agency?.id, search, page));
  
    const { t } = useTranslation();
  
    const handleChange = ({ selectedRows }) => {
      // You can set state or dispatch with something like Redux so we can use the retrieved data
      setSelected(selectedRows);
    };
  
    const handleChangePage = (event, page) => {
      if (page) {
        console.log(page);
        setPage(page);
      }
    };

    const handleClose = () => {
      window.$(".btn-close")?.click();
    }
  
    const handleSuccess = () => {
      setToEdit();
      mutate()
    }
  
    const handleEdit = (obj) => {
      setToEdit(obj)
    }
  
    const handleDelete = (obj) => {
      confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate)
    }
    
  let columns = [
    {
      name: 'Nom du produit',
      selector: (row) => row.product?.title,
      sortable: true,
    },
    {
      name: 'Prix d\'achat',
      selector: (row) => `${row.product?.buy_price ?? "0"} FCFA`,
      sortable: true,
    },
    {
        name: 'Prix de vente',
        selector: (row) => `${row.product?.price} FCFA`,
        sortable: true,
    },
    // {
    //   name: 'Marge',
    //   selector: (row) => `${row.product?.sell_price - row.product?.buy_price} FCFA`,
    //   sortable: true,
    // },
    {
      name: 'Quantité',
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Date d'ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    
  ];

  return (
      <div className="row">
        <div className="col-12">
          <div className="=">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-column">
                <div>
                  <p className="text-uppercase h4">{title ?? "Sorties de stock"}</p>
                  {/* <h3 className="text-uppercase">Historique des sorties de stock </h3> */}
                  {/* <p>Les sorties de stock representent les produits sortis pour utilisation.</p> */}
                </div>
                <div>
                  {!isSelledHistory && <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light rounded-0 px-3"
                    data-bs-toggle="modal"
                    data-bs-target=".new-stock-modal-transfert"
                  >
                    {' '}
                    + Transférer
                  </button>}
                </div>
              </div>
  
              {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

              {/* Manage stock */}

              {!isSelledHistory && <div
                className="modal fade new-stock-modal-transfert"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="myLargeModalLabel">
                        {
                          "Nouveau transfert de stock"
                        }
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <StockTransfertForm handleClose={handleClose} onSuccess={handleSuccess} toEdit={toEditStock} side={"OUT"} />
                    </div>
                  </div>
                </div>
              </div>}
  
              <div>
                <div className="row py-5">
                  <div className="col-md-6 col-12">
                    <div>
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        className="form-control"
                        id="autoSizingInput"
                        placeholder="Ref produit, Designation, etc ..."
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div></div>
                    {/* <div className="d-flex justify-content-end">
                      <ExportExcel
                        label={'Exporter en Excel'}
                        sheetName={`Customers page: ${page}`}
                        columns={type === "SERVICES" ? exportServices : exportColumns}
                        dataToExport={(products?.results ?? []).map((customer) => {
                          return {
                            ...customer,
                            marge: `${customer.price - customer.buy_price}`,
                            created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                          };
                        })}
                      />
                    </div> */}
                  </div>
                </div>
                <div></div>
                <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-uppercase">Référence</th>
                      <th className="text-uppercase">Destinataire</th>
                      {type != 'SERVICES' ? (
                        <>
                          <th className="text-uppercase">{isSelledHistory ? "Qtés vendues" : "Quantité"}</th>
                          <th className="text-uppercase">{ isSelledHistory ? "Coût d'achat" : "Prix d'achat"}</th>
                          <th className="text-uppercase">{ isSelledHistory ? "Montant Total" : "Prix de vente"}</th>
                          <th className="text-uppercase">Marge</th>
                          {/* <th className="text-uppercase">Stock</th> */}
                          {/* <th className="text-uppercase">Emplacement</th> */}
                        </>
                      ) : (
                        <>
                          <th className="text-uppercase">Prix</th>
                        </>
                      )}
                      <th className="text-uppercase">Date </th>
                      <th className="text-uppercase">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(transferts?.results ?? [])?.map((item) => (
                      <tr key={'itm-' + item.id}>
                        <td>{item?.reference}</td>
                        <td>{item?.to_agency?.name}</td>
                        <td>
                          <span className="badge badge-soft-success">{item.quantity}</span>
                        </td>
                        <td>
                          <span className="badge badge-soft-success">
                          {formatNumber(item?.from_stock?.buy_price * item?.from_stock?.quantity)} FCFA
                          </span>
                        </td>
                        <td>
                        <span className="badge badge-soft-success">{formatNumber(item?.from_stock?.sell_price * item?.from_stock?.quantity)} FCFA </span>
                        </td>
                        <td>
                        <span className="badge badge-soft-success">{formatNumber((item?.from_stock?.sell_price - item?.from_stock?.buy_price) * item?.from_stock?.quantity)} FCFA </span>
                        </td>
                        {/* <td>{item.location.name}</td> */}
                        {/* <td>
                          {item.new_quantity}
                        </td> */}
                        <td>
                          {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                          <span className="ms-3">{moment(item.created_at).format('HH:MM')}</span>{' '}
                        </td>
                        <td>
                          <div className="d-flex gap-2 justify-content-center">
                            <Link
                              to="#"
                              // onClick={() => setSelectedInvoice(item)}
                              title="Imprimer"
                              data-bs-toggle="modal"
                              data-bs-target=".bon-sortie-modal"
                              className="btn btn-primary btn-sm btn-colapsed"
                            >
                              <i className="ri-printer-line"></i>
                              <span>Imprimer</span>
                            </Link>
                            <Link
                              to="#"
                              onClick={() => handleEdit(item)}
                              title="Modifier"
                              data-bs-toggle="modal"
                              data-bs-target=".new-product-modal"
                              className="btn btn-success btn-sm btn-colapsed"
                            >
                              <i className="mdi mdi-pencil"></i>
                              <span>Modifier</span>
                            </Link>
                            <Link
                              to={`/products/${item.id}`}
                              title="Afficher"
                              className="btn btn-warning btn-sm btn-colapsed"
                            >
                              <i className="mdi mdi-eye"></i>
                              <span>Afficher</span>
                            </Link>
                            <Link
                              to="#"
                              title="Supprimer"
                              onClick={() => handleDelete(item)}
                              className="btn btn-danger btn-sm btn-colapsed"
                            >
                              <i className="mdi mdi-delete"></i>
                              <span>Supprimer</span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div>
        <div
          className="modal fade bon-sortie-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoicdseModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoicdseModalLabel">
                  {'Facture ' + selectedInvoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <BonDeSortie invoice={selectedInvoice}/>
              </div>
            </div>
          </div>
        </div>
      </div>
                <div>
                  {
                    isLoading && <LoaderPage />
                  }
                </div>
              </div>
              <div>
                {transferts?.count > 0 && (
                  <div className="paginations pt-3">
                    <ul className="pagination pagination-rounded">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                      {new Array(Math.ceil(transferts.count / DEFAULT_PAGE_COUNT))
                        .fill(3)
                        .map((pag, index) => (
                          <li
                            key={'page' + index}
                            className={`page-item ${page === index + 1 ? 'active' : ''}`}
                            aria-current="page"
                          >
                            <Link
                              className={`pager-number ${
                                page * DEFAULT_PAGE_COUNT === Math.ceil(transferts.count)
                                  ? 'disabled'
                                  : ''
                              } ${page === index + 1 ? 'active' : ''}`}
                              to="#"
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, index + 1)}
                            >
                              <span className="page-link">{index + 1}</span>
                            </Link>
                          </li>
                        ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
                {/* <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={products?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={isLoading}
                  paginationServer
                  paginationTotalRows={products?.count ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  paginationComponentOptions={paginationComponentOptions}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};


export default StockTransfert;