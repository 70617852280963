import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CLoader from '../../../components/Loader';
import { useSelector } from 'react-redux';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ROLE_CHEF_AGENCE } from '../../../components/utils/consts';

const NewEmployeeForm = ({ toEdit, onSuccess, handleClose }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [isUserActive, setIsUserActive] = useState(toEdit?.is_active);

  const { auth } = useSelector((state) => state.auth);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    console.log("data", data);
    let toSend = {
      first_name: data.firstName,
      last_name: data.lastName,
      reference: 'string',
      email: data.email,
      phone_number: data.phoneNumber,
      birth_date: data.birthDate,
      address: data.address,
      salary: data.salary,
      role: data.role,
      niu: data.niu,
      user_level: data.user_level,
      matricule_cnps: data.matricule_cnps,
      comission_percentage: data.comission_percentage,
      agency: active_agency.id ?? auth?.user?.agency?.id,
      created_by: auth?.user?.id,
    };
    
    if(data.anonymClient){
        toSend = {
            first_name: "CLIENTS",
            last_name: "DIVERS",
            phone_number: "000 000 000",
            agency: active_agency.id,
        }
    }

    console.log("toSend", toSend);

    if (toEdit) {
      toSend.is_active = isUserActive;
        setLoading(true);
      API.patch(URLS.USERS.update((toEdit.id)), toSend)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('L\'employé a bien été modifié');
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              navigate(`/employees/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              if (handleClose) {
                handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
              "Une erreur s'est produite lors de la mise a jour de l'employé, veuiller reésayer !"
          );
          setLoading(false);
        });
    } else {
      toSend["password"] = data.password;
      toSend["password2"] = data.password2;
      setLoading(true);
      API.post(URLS.AUTHS.REGISTRATION, toSend)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('Le compte a bien été créé');
          setLoading(false);
          switch (afterAction) {
            case 'ADD':
              reset();
              break;
            case 'DETAILS':
              navigate(`/employees/${resp?.data?.id}`);
              break;
            case 'CLOSE':
              reset();
              if (handleClose) {
                handleClose();
              }
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
            error?.response?.data?.message?.email ??
            error?.response?.data?.message?.phone_number ??
              "Une erreur s'est produite lors de la création du compte, veuiller reésayer !"
          );
          setLoading(false);
        });
    }
    console.log(data);
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);
    var submitCustomerForm = document.getElementById('submitEmployeeForm');

    if(submitCustomerForm){
      submitCustomerForm.click();
    }

  };

  useEffect(() => {
    if(toEdit){
        reset({
            firstName: toEdit.first_name,
            lastName: toEdit.last_name,
            phoneNumber: toEdit.phone_number,
            email: toEdit.email,
            birthDate: toEdit.birth_date,
            role: toEdit.role,
            niu: toEdit.nui,
            comission_percentage: toEdit.comission_percentage,
            matricule_cnps: toEdit.matricule_cnps,
            user_level: toEdit.user_level,
            address: toEdit.address,
            salary: toEdit.salary,
        })
    }
  }, [toEdit]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id="appEmployeeForm">
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Nom</label>
              <input
                type="text"
                className="form-control"
                id="floatingFirstnameInput"
                {...register('firstName')}
                placeholder="Nom"
              />
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingLastnameInput">Prénom</label>
              <input
                type="text"
                className="form-control"
                id="floatingLastnameInput"
                {...register('lastName')}
                placeholder="Prénom"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingroleInput">Poste</label>
              <select className="form-control" {...register('role', {required: true})} id="floatingroleInput">
                <option value="">Choisir</option>
                <option value="RESPONSABLE_EXPLOITATION">RESPONSABLE D'EXPLOITATION</option>
                <option value="CHEF_AGENCE">CHEF D'AGENCE</option>
                <option value="CAISSIER">CAISSIER (E)</option>
                <option value="ESTHETICIENNE">ESTHETICIENNE</option>
                <option value="COIFFEUR">COIFFEUR</option>
                <option value="AUTRES">AUTRES</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="phoneNumber">Téléphone</label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                {...register('phoneNumber')}
                placeholder="Téléphone"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Addresse email</label>
              <input
                type="email"
                className="form-control"
                id="floatingemailInput"
                {...register('email')}
                placeholder="Adresse email"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="birthData">Date de naissance</label>
              <input
                type="date"
                className="form-control"
                id="birthData"
                {...register('birthDate')}
                placeholder="Date de naissance"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="address">Adresse</label>
              <input
                type="text"
                className="form-control"
                id="address"
                {...register('address')}
                placeholder="Makepe BM, Douala Cameroun"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="salary">Salaire Fixe</label>
              <input
                type="number"
                className="form-control"
                id="salary"
                {...register('salary')}
                placeholder="50000"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="comission_percentage">Pourcentage des commissions</label>
              <input
                type="number"
                className="form-control"
                id="comission_percentage"
                {...register('comission_percentage', {max: 100})}
                placeholder="10"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="matriculeCNPS">Matricule CNPS</label>
              <input
                type="text"
                className="form-control"
                id="matriculeCNPS"
                {...register('matricule_cnps')}
                placeholder=""
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="niu">NIU</label>
              <input
                type="text"
                className="form-control"
                id="niu"
                {...register('niu')}
                placeholder=""
              />
            </div>
          </div>
          {
            <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="niu">Niveau d'utilisation</label>
              <select className="form-control" {...register('user_level')} id="user_level">
                <option value="">Choisir</option>
                <option value="EMPLOYEE">Employé</option>
                {
                  ROLE_CHEF_AGENCE.includes(auth?.user?.user_level) && <>
                  <option value="EMPLOYEE">Employé</option>
                  <option value="CHEF_SALLE">Chef de Salle</option>
                  <option value="OP_CAISSE">Opératrice de caisse</option>
                  <option value="CHEF_AGENCE">Chef d'agence</option>
                  <option value="RESPONSABLE_EXPLOITATION">Responsable d'exploitation</option>
                  <option value="ADMIN">Super Utilisateur</option>
                  </>
                }
              </select>

            </div>
          </div>
          }
        </div>
        {
          !toEdit && <div className="row">
            <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingpasswordInput">Mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="floatingpasswordInput"
                {...register('password')}
                placeholder="********"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingpassword2Input">Confirmer le mot de passe</label>
              <input
                type="password"
                className="form-control"
                id="floatingpassword2Input"
                {...register('password2')}
                placeholder="********"
              />
            </div>
          </div>
          </div>
        }

        {
          toEdit && <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="mb-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" {...register('userlevel')} type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={isUserActive} onChange={() => setIsUserActive(!isUserActive)} />
                  <label class="form-check-label" for="flexSwitchCheckChecked">{!isUserActive ? <span className="text-primary">Activer le compte</span> : <span className="text-danger">Désactiver le compte</span>}</label>
                </div>
              </div>
            </div>
          </div>
        }
        
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitEmployeeForm" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default NewEmployeeForm;
