import useSWR from "swr";
import { URLS } from "../../services/urls";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loginSuccess } from "../../store/slices/auth";
import { getUserDetails, ls, setUserDetails } from "../utils/functions";
import { API } from "../../services/axios";
import { loadingFailed, loadingRequest, loadingSuccess, selectAnAgency } from "../../store/slices/agencies";



const Auth = (props) => {
    
    const userDetails = getUserDetails();
    const dispatch = useDispatch();
    const { auth } = useSelector((state) => state.auth);

    useEffect(() => {
        if(userDetails){
            API.get(URLS.AUTHS.me).then((resp) => {
                dispatch(loginSuccess({...userDetails, user: resp.data}));
                dispatch(selectAnAgency(resp.data.agency));
                setUserDetails({...userDetails, user: resp.data})
            }).catch((error) => {
                dispatch(loginSuccess({...userDetails}));
                dispatch(selectAnAgency(userDetails.user.agency));
            });

        }
    }, []);

    useEffect(() => {
        if(userDetails && auth?.user){
            dispatch(loadingRequest());
            API.get(URLS.AGENCIES.list("", 1)).then((resp) => {
                dispatch(loadingSuccess(resp.data.results));
            }).catch((error) => {
                dispatch(loadingFailed());
            });
        }
    }, [auth?.user]);

    return <div></div>;
}

export default Auth