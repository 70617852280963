


const PageTitle = (props) => {

    const { breadcrumbs, title } = props;


    return (
        <div className="page-title">
            <div className="title">{title}</div>
            <div className="breadcrumbs">
                {breadcrumbs}
            </div>
        </div>
    )
}

export default PageTitle;