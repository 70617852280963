import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import { useSelector } from 'react-redux';
import { getPaymentMethodIcon } from '../../../components/utils/functions';
import moment from 'moment';

const LatestTransactions = () => {

  const { active_agency } = useSelector((state) => state.agencies);

  const { data: transactions, isLoading: loadingTransactions } = useSWR(
    URLS.SEARCH.transactions( active_agency?.id, "", 1, "&ordering=-created_at")
  );

  // const { data: transactions, isLoading: loadingTransactions } = useSWR(
  //   URLS.WALLET.allTrannsactions( '', 1, `&agency__id=${active_agency?.id}`)
  // );

  return (
    <div className="card-body pt-2 ">
        <div className="">
            <p className="c-title mb-0 p-3">Dernières Transactions</p>
            <hr />
        </div>
      <div className="">
        <div className="table-responsive">
          <table className="table align-middle table-nowrap mb-0">
            <thead>
              <tr>
                <th style={{ width: '20px' }}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="customCheck1" />
                    <label className="form-check-label" for="customCheck1">
                      &nbsp;
                    </label>
                  </div>
                </th>
                <th>Reference</th>
                <th>Type</th>
                <th>Montant</th>
                <th>Raison</th>
                {/* <th>Mode de paiement</th> */}
                <th>Date</th>
                <th>Statut</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {transactions?.results?.slice(0, 4)?.map((transfert) => (
                <tr key={'trsc-' + transfert.id}>
                  <td>
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="customCheck2" />
                      <label className="form-check-label" for="customCheck2">
                        &nbsp;
                      </label>
                    </div>
                  </td>
                  <td>
                    <a href="javascript: void(0);" className="text-body">
                      #{transfert.reference}
                    </a>{' '}
                  </td>
                  <td>
                    {transfert?.type === 'DEPOSIT' ? (
                      <span className="badge rounded badge-soft-success font-size-12">Entrée</span>
                    ) : (
                      <span className="badge rounded badge-soft-danger font-size-12">Sortie</span>
                    )}
                  </td>
                  <td>
                    <p className="mb-0">
                      {parseFloat(transfert?.amount) + parseFloat(transfert.trannsport_amount ?? '0')} FCFA
                    </p>
                  </td>
                  <td>{String(transfert.reason).replaceAll('_', ' ')}</td>
                  
                  {/* <td>{transfert.payment_method}</td> */}
                  <td>{moment(transfert?.created_at).format('DD/MM/YYYY')} <span className="ms-3">{moment(transfert?.created_at).format('HH:mm')}</span> </td>
                  {/* <td>
                            <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                          </td> */}
                  <td>
                    <span className="badge rounded badge-soft-success font-size-12">Complété</span>
                  </td>
                  {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default LatestTransactions;
