import * as React from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NewAppointmentForm from './components/NewAppointmentForm';
import _ from 'lodash';
import AppoinntmentTable from './components/AppointmentsTable';

const MyAppointments = () => {
  const [toEdit, setToEdit] = React.useState();
  const [search, setSearch] = React.useState('');
  const [showCalendar, setShowCalendar] = React.useState();
  const [page, setPage] = React.useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: appointments,
    isLoading,
    mutate,
  } = useSWR(URLS.APPOINTMENTS.me(active_agency?.id, search, page));

  const handleTakeAppointment = () => {
    window.$('#newAppointmentMOdal')?.modal('show');
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = (appointment) => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    const { id } = obj.event;
    const edit = _.filter(appointments?.results, { id })[0];
    console.log(edit);
    if (edit) {
      setToEdit(edit);
      window.$('#newAppointmentMOdal')?.modal('show');
    }
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i className="ms-1">{eventInfo.event.title}</i>
      </>
    );
  };

  return (
    <div className="row py-5">
      <div className="col-12">
        <nav aria-label="breadcrumb" className="main-breadcrumb card">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Liste des rendez-vous
            </li>
          </ol>
        </nav>
      </div>
      <div className="col-12">
        <div className="card">
          <div className="p-3">
          <div className="d-flex">
              <div className="ms-auto">
                {!showCalendar ? <button onClick={() => setShowCalendar(true)} className="btn btn-primary waves-effect waves-light">Voir le calendrier</button>
                : <button onClick={() => setShowCalendar(false)}className="btn btn-primary waves-effect waves-light mb-3">Voir la liste</button>}
              </div>
            </div>
            {!showCalendar && <AppoinntmentTable handleEdit={handleEdit} me={true} />}
            {
              showCalendar && 
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              initialView="dayGridMonth"
              editable={true}
              selectable={true}
              selectMirror={true}
              dayMaxEvents={true}
              eventContent={renderEventContent}
              events={appointments?.results?.map((evnt) => {
                return {
                  title: `${evnt?.order?.customer?.first_name} ${evnt?.order?.customer?.last_name}`,
                  date: evnt.start,
                  classNames: [
                    evnt.status == 'SCHEDULED'
                      ? 'bg-warning'
                      : evnt.status == 'PENDING'
                      ? 'bg-success'
                      : evnt.status == 'COMPLETED'
                      ? 'bg-success'
                      : 'bg-danger',
                  ],
                  id: evnt.id,
                };
              })}
              // weekends={this.state.weekendsVisible}
              // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
              // select={handleTakeAppointment}
              // eventContent={renderEventContent} // custom render function
              eventClick={handleEdit}
              // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
              /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
            />}
          </div>
          <div className="p-3">
            <div className="d-flex">
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-warning d-inline-block me-2`}></span>
                <span className="">Rendez-vous pris</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-success d-inline-block me-2`}></span>
                <span className="">Rendez-vous terminé</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-info d-inline-block me-2`}></span>
                <span className="">Prestation en cours d'exécution</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-circle w-20 h-20 bg-danger d-inline-block me-2`}></span>
                <span className="">Rendez-vous annulé ou manqué</span>
              </div>
            </div>
          </div>
          <div
            className="modal fade new-appointment-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="newAppointmentMOdal"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="myLargeModalLabel">
                    {toEdit
                      ? 'Modifitation du rendez-vous <<' +
                        (toEdit?.order?.customer?.first_name ?? '') +
                        ' ' +
                        (toEdit?.order?.customer?.last_name ?? '') +
                        '>>'
                      : 'Nouvel employé'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <NewAppointmentForm
                    choisirCustomer={false}
                    handleClose={handleClose}
                    onSuccess={handleSuccess}
                    toEdit={toEdit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAppointments;
