import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewEmployeeForm from './components/NewEmployeeForm';
import { filterEmployee } from '../../components/utils/functions';
import PageTitle from '../../components/PageTitle';

const exportColumns = [
  {
    label: 'Nom complet',
    value: 'full_name',
  },
  {
    label: 'Telephone',
    value: 'phone_number',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Role',
    value: 'role',
  },
  {
    label: 'Date de naissance',
    value: 'birth_date',
  },
  {
    label: "Date d'ajout",
    value: 'created_at',
  },
  {
    label: 'Cree par',
    value: 'created_by',
  },
];

const paginationComponentOptions = {
  // noRowsPerPage: true,
};

const DataTabl = ({ data, handleEdit, handleDelete }) => {
  return (
    <div>
      <div className="table-responsive">
        <table className="table align-middle table-nowrap mb-0 table-bordered table-hover">
          <thead className="table-light">
            <tr>
              <th>Matricule</th>
              <th>Nom et prénom</th>
              <th>Poste occupé</th>
              <th>Date de créationn</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((employee) => (
              <tr key={'empl-' + employee?.id}>
                <td>{employee.reference}</td>
                <td>
                  {employee.first_name} {employee.last_name}
                </td>
                <td>{String(employee.role)?.replaceAll('_', ' ')}</td>
                <td>{moment(employee.created_at).format('DD/MM/YYYY HH:MM')}</td>
                <td>
                  <div className="d-flex gap-3">
                    <Link
                      to="#"
                      onClick={() => handleEdit(employee)}
                      data-bs-toggle="modal"
                      data-bs-target=".new-client-modal"
                      className="btn btn-success btn-sm"
                    >
                      <i className="mdi mdi-pencil"></i>
                    </Link>
                    <Link to={`/employees/${employee.id}`} className="btn btn-warning btn-sm">
                      <i className="mdi mdi-eye"></i>
                    </Link>
                    <Link
                      to="#"
                      onClick={() => handleDelete(employee)}
                      className="btn btn-danger btn-sm"
                    >
                      <i className="mdi mdi-delete"></i>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const EmployeesList = () => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: customers,
    isLoading,
    mutate,
  } = useSWR(URLS.USERS.employees(active_agency?.id, search, page));

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
    setSelected(selectedRows);
  };

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.CUSTOMERS.delete(obj.id), t, mutate);
  };

  const columns = [
    {
      name: 'Nom complet',
      selector: (row) => `${row.first_name ?? ''} ${row.last_name ?? ''}`,
      sortable: true,
      val: 'first_name',
    },
    {
      name: 'Matricule',
      selector: (row) => `${row.reference}`,
      sortable: true,
      val: 'reference',
    },
    {
      name: 'Téléphone',
      selector: (row) => `${row.phone_number}`,
      sortable: true,
      val: 'phone_number',
    },
    {
      name: 'Email',
      selector: (row) => `${row.email}`,
      sortable: true,
      val: 'email',
    },
    {
      name: 'Poste',
      selector: (row) => `${row.role}`,
      sortable: true,
      val: 'role',
    },
    {
      name: 'Date de naissance',
      selector: (row) => row.birth_date,
      sortable: true,
      val: 'birth_date',
    },
    {
      name: "Date d'ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    {
      name: 'Actions',
      selector: (row) => (
        <div className="d-flex gap-3">
          <Link
            to="#"
            onClick={() => handleEdit(row)}
            data-bs-toggle="modal"
            data-bs-target=".new-client-modal"
            className="btn btn-success btn-sm"
          >
            <i className="mdi mdi-pencil"></i>
          </Link>
          <Link to={`/employees/${row.id}`} className="btn btn-warning btn-sm">
            <i className="mdi mdi-eye"></i>
          </Link>
          <Link to="#" onClick={() => handleDelete(row)} className="btn btn-danger btn-sm">
            <i className="mdi mdi-delete"></i>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Employés'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Employés
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
            <div className="me-auto">
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                data-bs-toggle="modal"
                data-bs-target=".new-client-modal"
              >
                {' '}
                + Ajouter un employé
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">Liste des employés </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <div className="">
                    <div className="row gy-2 gx-3 align-items-center">
                      <div className="col">
                        <div>
                          {/* <label htmlFor="autoSizingInput">Rechercher</label> */}
                          <div className="form search-form">
                            <i className="fa fa-search"></i>
                            <input
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              class="form-control form-input py-2"
                              placeholder="Rechercher"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-auto">
                        <div>
                          <div className="d-flex justify-content-end">
                            <ExportExcel
                              label={'Exporter en Excel'}
                              sheetName={`Employees page: ${page}`}
                              columns={exportColumns}
                              dataToExport={(customers?.results ?? []).map((customer) => {
                                return {
                                  ...customer,
                                  full_name: `${customer.first_name} ${customer.last_name}`,
                                  sold: customer?.wallet?.amount ?? 0,
                                  created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                                };
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <div
                  className="modal fade new-client-modal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? "Modifitation de l'employé <<" +
                              (toEdit.first_name ?? '') +
                              ' ' +
                              (toEdit.last_name ?? '') +
                              '>>'
                            : 'Nouvel employé'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewEmployeeForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <p className="card-title-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
            </p> */}
            <hr className="mb-0" />
            <div>
              <div className="row">
                {/* <div className="col-md-6 col-12">
                  <div>
                    <input
                      onChange={(e) => setSearch(e.target.value)}
                      type="text"
                      className="form-control"
                      id="autoSizingInput"
                      placeholder="Rechercher ..."
                    />
                  </div>
                </div> */}
                <div className="col-md-6 col-12">
                  
                </div>
              </div>
              {/* Admin section */}
              {filterEmployee(customers?.results ?? [], 'admin').length > 0 && (
                <div className="mb-3">
                  <div>
                    <p className="h4 text-uppercase">Administration</p>
                  </div>
                  <DataTabl
                    // ref={tableRef}
                    columns={columns}
                    data={filterEmployee(customers?.results ?? [], 'admin') ?? []}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </div>
              )}
              {filterEmployee(customers?.results ?? [], 'esthe').length > 0 && (
                <div className="mb-3">
                  <div>
                    <p className="h4 text-uppercase">Opérateur/Estéticienne</p>
                  </div>
                  <DataTabl
                    // ref={tableRef}
                    data={filterEmployee(customers?.results ?? [], 'esthe') ?? []}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </div>
              )}
              {filterEmployee(customers?.results ?? [], 'coiff').length > 0 && (
                <div className="mb-3">
                  <div>
                    <p className="h4 text-uppercase">Opérateur/Coiffeur</p>
                  </div>
                  <DataTabl
                    // ref={tableRef}
                    columns={columns}
                    data={filterEmployee(customers?.results ?? [], 'coiff') ?? []}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </div>
              )}
              {filterEmployee(customers?.results ?? [], 'autres').length > 0 && (
                <div className="mb-3">
                  <div>
                    <p className="h4 text-uppercase">Autres</p>
                  </div>
                  <DataTabl
                    // ref={tableRef}
                    columns={columns}
                    data={filterEmployee(customers?.results ?? [], 'autres') ?? []}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesList;
