import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({currentPage, handlePageChange, totalPages}) => {
 
  const renderPaginationItems = () => {
    const data = [];
    const numPage = 5;
    if (totalPages <= numPage) {
        for (let i = 1; i <= totalPages; i++) {
            data.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }
    } else {
        const leftside = currentPage - numPage / 2 > 1;
        const rightside = currentPage + numPage / 2 < totalPages;
        data.push(
            <Pagination.First
                key="first"
                onClick={() => handlePageChange(1)}
            />
        );
        data.push(
            <Pagination.Prev
                key="prev"
                onClick={() => handlePageChange(currentPage - 1)}
            />
        );
        if (leftside) {
            data.push(<Pagination.Ellipsis key="leftEllipsis" />);
        }
        const str = Math.max(1, Math.round(currentPage - numPage / 2));
        const end = Math.min(totalPages, Math.round(currentPage + numPage / 2));
        for (let i = str; i <= end; i++) {
            data.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }
        if (rightside) {
            data.push(<Pagination.Ellipsis key="rightEllipsis" />);
        }
        data.push(
            <Pagination.Next
                key="next"
                onClick={() => handlePageChange(currentPage + 1)}
            />
        );
        data.push(
            <Pagination.Last
                key="last"
                onClick={() => handlePageChange(totalPages)}
            />
        );
    }
    return data;

  };

  return (
    <Pagination className="pagination-rounded">{renderPaginationItems()}</Pagination>
  );
};

export default PaginationComponent;
