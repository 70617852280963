import { useNavigate } from 'react-router-dom';
import NewCustomerForm from './components/NewCustomerForm';

const NewClient = () => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate('/customers/list');
  };
  return (
    <div className="row py-5">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="">Nouveau client </h3>
              </div>
            </div>
            <div className="py-5">
              <NewCustomerForm onSuccess={handleSuccess} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClient;
