import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ManageCategory from './components/categories/Managecategory';
import { useState } from 'react';
import { formatNumber, handleFocus } from '../../components/utils/functions';
import { API } from '../../services/axios';
import NewProductForm from './components/NewProduct';

const Categories = ({ type }) => {
  const [toEdit, setToEdit] = useState();
  const [toEditProduct, setToEditProduct] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: categories,
    isLoading,
    mutate,
  } = useSWR(URLS.PRODUCTS.CATEGORIES.list(active_agency?.id, '', 1, `${type}`));

  const handleSuccess = () => {
    mutate();
    setToEdit();
  };

  const handleChangePrice = (id, value) => {
    API.patch(URLS.PRODUCTS.update(id), { price: value }).then((resp) => {
      mutate();
    });
  };
  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  return (
    <div className="row">
      <div className="col-12">
        {/* <nav aria-label="breadcrumb" className="main-breadcrumb card">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to="/">Tableau de bord</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Catégories
            </li>
          </ol>
        </nav> */}
        {/* <nav aria-label="breadcrumb" className="main-breadcrumb card">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item active" aria-current="page">
              Catégories
            </li>
          </ol>
        </nav> */}
        <div className="d-flex flex-row justify-content-between w-100">
          <div className="my-auto">
            <p className="text-uppercas h4 mb-0">Liste des Produits</p>
          </div>
          <div className="">
            <div>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light px-3 rounded-"
                data-bs-toggle="modal"
                data-bs-target=".new-product-modal"
              >
                {' '}
                + Ajouter un {type === 'SERVICES' ? 'service' : 'produit'}
              </button>
              <div
                className="modal fade new-product-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="myLargeModalLabel">
                        {toEditProduct
                          ? 'Modifitation du produit ' + (toEditProduct.title ?? '')
                          : 'Nouveau ' + (type === 'SERVICES' ? 'service' : 'produit')}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <NewProductForm
                        type={type === 'SERVICES' ? 'SERVICE' : 'PRODUCT'}
                        handleClose={handleClose}
                        onSuccess={handleSuccess}
                        toEdit={toEditProduct}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div>
          <div className="mt-3">
            <div className="">
              <div className="table-responsive">
                <table className="table table-bordered text-uppercas">
                  <thead>
                    <tr className="bg-light">
                      <th>Référence</th>
                      <th>Nom du produit</th>
                      {/* <th>Famille</th> */}
                      <th>CMUP</th>
                      <th>Prix de vente</th>
                      <th>Date de Création</th>
                      <th>Actions</th>
                      {/* {!me && <th>Actions</th>} */}
                    </tr>
                  </thead>
                  <tbody>
                    {categories?.results?.map((cate) => {
                      return cate?.products?.map((cat) => (
                        <tr key={'cat-' + cat.id}>
                          <td>{cat.reference}</td>
                          <td>{cat.title}</td>
                          <td>{formatNumber(Number(cat.buy_price).toFixed(2))} FCFA</td>
                          {/* <td>{cate.name}
                                                <Link to="#" onClick={() => setToEdit(cate)} className="btn ms-2 btn-success btn-sm">
                                                    <i className="mdi mdi-pencil"></i>
                                                </Link>
                                                </td> */}
                          <td>
                            <span className="d-flex justify-content-center">
                              <input
                                className="me-2 border-0"
                                style={{ width: '50px' }}
                                onFocus={handleFocus}
                                onChange={(e) => handleChangePrice(cat.id, e.target.value)}
                                type="number"
                                value={cat.price}
                              />{' '}
                              FCFA
                            </span>
                          </td>
                          <td>
                            {moment(cat.created_at).format('DD/MM/YYYY')}{' '}
                            <span className="ms-3">{moment(cat.created_at).format('LT')}</span>
                          </td>
                          <td>
                            <Link
                              to="#"
                              onClick={() => setToEditProduct(cat)}
                              className="btn btn-success btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target=".new-product-modal"
                            >
                              <i className="mdi mdi-pencil"></i>
                            </Link>
                          </td>
                        </tr>
                      ));
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Categories;
