import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import { useSelector } from 'react-redux';
import SellByAgency from './cards/SellByAgency';
import _ from 'lodash';

const SellsByAgency = () => {
    const { agencies } = useSelector((state) => state.agencies);
    const { auth } = useSelector((state) => state.auth);

  return (
    <div className="sells-by-agency">
      <div className="d-flex">
        <p className="title my-auto">Ventes par agence</p>
        {/* <div className="ms-auto">
          <div className="action">
            <div className="dropdown w-100 d-flex">
              <button
                type="button"
                className="btn border-0 ms-auto p-0"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="d-flex">
                  <div className="c-menu ms-auto d-flex">
                    <i className="mdi mdi-dots-vertical"></i>
                  </div>
                </div>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className={`dropdown-item`} to="#">
                  <span className="align-middle">LL</span>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <hr />
      <div
        className="table-responsive"
      >
        <table
            className="table mb-0"
        >
            <tbody>
                {
                  auth?.user?.user_level === "ADMIN" ? agencies?.map((agency) => (

                      <SellByAgency agency={agency} key={'rsp-' + agency?.id} />
                    ))
                    :
                    _.filter(agencies ?? [], {'id': auth?.user?.agency?.id}).map((agency) => (

                      <SellByAgency agency={agency} key={'rsp-' + agency?.id} />
                    ))
                }
            </tbody>
        </table>
      </div>
      
    </div>
  );
};

export default SellsByAgency;
