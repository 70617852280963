import * as React from 'react';
import { formatDate } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import NewAppointmentForm from './components/NewAppointmentForm';
import _ from 'lodash';
import AppoinntmentTable from './components/AppointmentsTable';
import AppointmentsListDate from './components/AppointmentsList';
import moment from 'moment';
import PageTitle from '../../components/PageTitle';


const PERIODS = ["TODAY", "TOMOROW"];

const AppointmentsList = () => {
  const [toEdit, setToEdit] = React.useState();
  const [showCalendar, setShowCalendar] = React.useState();
  const [search, setSearch] = React.useState('');
  const [unnique, setUnique] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState('');
  const [selectedPeriod, setSelectedPeriod] = React.useState(PERIODS[0]);
  const [page, setPage] = React.useState(1);
  let [searchParams, setSearchParams] = useSearchParams();


  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: appointments,
    isLoading,
    mutate,
  } = useSWR(URLS.APPOINTMENTS.list(active_agency?.id, search, page));

  const {
    data: specialAppointments,
    isLoadingSpecial,
  } = useSWR(URLS.APPOINTMENTS.list(active_agency?.id, "", 1, `&start=${searchParams?.get("openDate")}`));

  const handleChangeView = (view) => {
    setSelectedPeriod(view)
  };

  const navigate = useNavigate();

  const handleClose = () => {
    window.$('.btn-close')?.click();
    setUnique(_.uniqueId());
    console.log(_.uniqueId());
  };

  const handleSuccess = (appointment) => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj, toedit) => {
    const { id } = obj?.event;
    const edit = toedit ??  _.filter(appointments?.results, { id: id })[0];
    if (edit) {
      setToEdit(edit);
      window.$('#newAppointmentDetailModal')?.modal('hide');
      window.$('#newAppointmentMOdal')?.modal('show');
    }
  };
  const handleShowAppointments = (date) => {
    setSelectedDate(date);
    window.$('#newAppointmentDetailModal')?.modal('show');
    window.$('.customer-details-modal')?.modal('hide');
  }

  React.useEffect(() => {
    if(searchParams.get("openDate") && searchParams.get("appointment")) {
      handleShowAppointments();
    } else {
      // alert("opps")
    }
    
    window?.$('.app-details-modal').on('hidden.bs.modal', function (e) {
      window?.$(".modal-backdrop.fade").hide()
    })
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Planning RDV'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Planning RDV
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
          <div className="me-auto">
                {!showCalendar ? <button onClick={() => navigate("/appointments/list/calendar")} className="btn btn-primary waves-effect waves-light ">Voir le Calendrier</button>
                : <button onClick={() => navigate("/appointments/list")} className="btn btn-primary waves-effect waves-light mb-3">Voir la Liste</button>}
              </div>
          </div>
        </div>
      </div>
      
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">Planning RDV </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className='d-flex justify-content-end'>
                  <button
                  className={`btn btn-${selectedPeriod === "TODAY" ? 'primary' : 'gray'} me-3`}
                  onClick={() => handleChangeView("TODAY")}
                  >Aujourd'hui</button>
                  <button
                  className={`btn btn-${selectedPeriod === "TOMOROW" ? 'primary' : 'gray'} me-3`}
                  onClick={() => handleChangeView("TOMOROW")}
                  >Demain</button>
                </div>
              </div>
            </div>
          </div>
          
          <div className="p-3">
            <div className="d-flex mb-3">
            </div>
            {!showCalendar && <AppoinntmentTable handleEdit={handleEdit} unniqueId={unnique} period={selectedPeriod} />}
          </div>
          <div className="p-3">
            <div className="d-flex">
              <div className="d-flex ms-3 appointments-legends">
                <span className={`rounded-1 w-20 h-20 bg-warning d-inline-block me-2`}></span>
                <span className="">Rendez-vous pris</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-1 w-20 h-20 bg-success d-inline-block me-2`}></span>
                <span className="">Rendez-vous terminé</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-1 w-20 h-20 bg-info d-inline-block me-2`}></span>
                <span className="">Prestation en cours d'exécution</span>
              </div>
              <div className="d-flex ms-3">
                <span className={`rounded-1 w-20 h-20 bg-danger d-inline-block me-2`}></span>
                <span className="">Rendez-vous annulé ou manqué</span>
              </div>
            </div>
          </div>
          <div
            className="modal fade new-appointment-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            id="newAppointmentMOdal"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="myLargeModalLabel">
                    {toEdit
                      ? 'Modifitation du rendez-vous <<' +
                        (toEdit?.order?.customer?.first_name ?? '') +
                        ' ' +
                        (toEdit?.order?.customer?.last_name ?? '') +
                        '>>'
                      : 'Nouveau rendez-vous'}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <NewAppointmentForm
                    choisirCustomer={false}
                    handleClose={handleClose}
                    onSuccess={handleSuccess}
                    toEdit={toEdit}
                    customerId2={toEdit?.order?.customer?.id}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Appointment for date */}
          <div
            className="modal fade app-details-modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="appointmentsLabel"
            aria-hidden="true"
            id="newAppointmentDetailModal"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title text-uppercase" id="appointmentsLabel">
                    {'Liste des rendez-vous du ' } {moment(searchParams?.get("openDate") ?? '').format("DD/MM/YYYY")}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <AppointmentsListDate higlingth={searchParams.get("appointment")} selectedDate={{dateStr: searchParams?.get("openDate")}} handleEdit={handleEdit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsList;
