

export const fr = {
    lang_en: "Anglais",
    lang_fr: "Français",
    passMustMatch: "Les mots de passe doivent correspondre",
    requis : "Requis",
    noPassProvided: "Aucun mot de passe fourni.",
    passToShort: "Le mot de passe est trop court - doit être de 8 caractères minimum.",
    passOnlyLatinLetters: "Le mot de passe ne peut contenir que des lettres latines.",
    startForFree: "Démarrez gratuitement aujourd'hui",
    acessAllFeatures: "Accès à toutes les fonctionnalités. Aucune carte de crédit requise.",
    firstName: "Prénom",
    lastName: "Nom de famille",
    email: "Email",
    password: "Mot de passe",
    deletionActionCancel: "Annuler la suppression",
    deletionActionButton: "Oui, supprimer",
    deletionProcess: "Suppression en cours",
    deletionDeleted: "Supprimé !",
    deletionSuccessMessage: "La suppression a réussit et l'élement a été rétiré.",
    deletionAreYouSure: "Etes vous sûr de vouloir continuer ?",
    deletionIrreversibleAlert: "L'élement et tous les autres qui lui sont associés seront supprimés.",
    requestGlobalErrorMessage: "Une erreur s'est produite, veuiller reéssayer s'il vous plait.",
    VISA_CARD: "Carte de débit",
    SOLDE_DISPONIBLE: "Paiements en Compte",
    OFFERT: "Gratuité",
    THIS_WEEK: "Cette Semaine",
    THIS_MONTH: "Ce Mois",
    THIS_TRIM: "Ce Trimestre",
    THIS_YEAR: "Cette Année",
    AGENCIES_CURRENT: "Agence sélectionnée",
    AGENCIES_ALL: "Toutes les Agences",

}