import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../services/axios';
import { URLS } from '../../../services/urls';
import CLoader from '../../../components/Loader';
import useSWR from 'swr';
import Select from 'react-select';


const StockTransfertForm = ({ toEdit, onSuccess, handleClose, side }) => {
    // if toEdit is undefined it mean that we have to choose a product
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(undefined);
  const [searchProducts, setSearchProducts] = useState("");
  const [searchAgency, setSearchAgency] = useState("");
  // Emplacement du stock
  const [selectedPlace, setSelectedPlace] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [searchPlace, setSearchPlace] = useState("");

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);
  
  const { data: products, isLoading } = useSWR(URLS.PRODUCTS.list(active_agency?.id, searchProducts, 1, "PRODUCT"));
  const { data: stocks, isLoading: loadingStocks } = useSWR(URLS.STOCKS.list2(active_agency?.id, searchProducts, 1, "PRODUCT"));
  const { data: agencies, isLoading: isLoadingAgencies } = useSWR(URLS.AGENCIES.list(active_agency?.id, searchAgency, 1, "PRODUCT"));
  const { data: places, isLoading: loadingPlaces } = useSWR(URLS.STOCKS.PLACES.list(active_agency?.id, searchPlace, 1, ""));

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleSelectProduct = (pro) => {
    console.log(pro);
    setSelectedProduct(pro);
  }

  const navigate = useNavigate();
  const onSubmit = (data) => {
    let toSend = {
        "quantity": parseFloat(data.quantity),
        "from_stock": toEdit ? toEdit?.id : selectedProduct?.value,
        "to_agency": selectedAgency?.value,
        "from_agency": active_agency?.id,
        "agencies": [active_agency?.id, selectedAgency?.value],
      }

      console.log(selectedProduct, toSend)
      if (!toEdit && !selectedProduct){

        toast.error("Veuiller sélectionner un stock")

      } else {

        setLoading(true);
        API.post(URLS.STOCKS.TRANSFERT.create, toSend)
        .then((resp) => {
            reset();
            if (onSuccess) {
            onSuccess(resp.data);
            }
            toast.success('Le stock a bien été Transféré');
            setLoading(false);
            if (handleClose) {
            handleClose();
            }
            setSearchPlace("");
            setSelectedPlace();
            setSearchProducts("");
            setSelectedProduct();
        })
        .catch((error) => {
            toast.error(
            error?.response?.data?.detail ??
            error?.response?.data ??
                ("Une erreur s'est produite lors du transfert du Stock, veuiller reésayer !")
            );
            setLoading(false);
        });
      }
    
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window.$('#submitCustomerForm1')?.click();
  };

  useEffect(() => {
    if(toEdit){
        reset({
            name: toEdit.name,
            email: toEdit.email,
            phoneNumber: toEdit.phone_number,
            address: toEdit.address,
        })
    }
  }, [toEdit]);

  useEffect(() => {
    console.log('xx', selectedProduct);
  }, [selectedProduct])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
        {!toEdit && <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingProductInput">Stock <span className="text-danger">*</span></label>
              <div>
                <Select
                    className="basic-single-product"
                    classNamePrefix="select"
                    isLoading={loadingStocks}
                    value={selectedProduct}
                    // isClearable={true}
                    // isSearchable={true}
                    onChange={(newValue) => handleSelectProduct(newValue)}
                    onInputChange={(value) => setSearchProducts(value)}
                    
                    name="customer"
                    options={stocks?.results?.map((product) => {
                        return { value: product.id, label: `${product.product?.title}` };
                    })}
                    />
              </div>
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>}
          {/* {<div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingPlaceInput">Emplacement <span className="text-danger">*</span></label>
              <div>
                <Select
                  className="basic-single-place"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  value={selectedPlace}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(newValue) => setSelectedPlace(newValue)}
                  onInputChange={(value) => setSearchPlace(value)}
                  
                  name="place"
                  options={places?.results?.map((place) => {
                      return { value: place.id, label: `${place?.name}` };
                  })}
                />
              </div>
            </div>
          </div>} */}
          {<div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingPlaceInput">Transférer à <span className="text-danger">*</span></label>
              <div>
                <Select
                  className="basic-single-place"
                  classNamePrefix="select"
                  isLoading={isLoadingAgencies}
                  value={selectedAgency}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(newValue) => setSelectedAgency(newValue)}
                  onInputChange={(value) => setSearchAgency(value)}
                  
                  name="place"
                  options={agencies?.results?.map((place) => {
                      return { value: place.id, label: `${place?.name}` };
                  })}
                />
              </div>
            </div>
          </div>}

          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingemailInput">Quantité <span className="text-danger">*</span></label>
              <input
                type="number"
                className="form-control"
                id="phoneNumber"
                {...register('quantity')}
                placeholder="20"
              />
            </div>
          </div>
        </div>

        
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm1" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Enregistrer</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default StockTransfertForm;
