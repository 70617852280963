import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Barcode from 'react-barcode';
import useSWR from 'swr';
import { URLS } from '../../../services/urls';
import _ from 'lodash'
import { formatNumber } from '../../../components/utils/functions';

const SupplyTicket = ({ invoice, isSelledHistory }) => {
  const [toShow, setToShow] = useState();
  const [dimensions, setDimensions] = useState({ width: 100, height: 500 });
  const { data: inv, isLoading, mutate } = useSWR(URLS.INVOICES.details(invoice?.id));

  const ticketRef = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
            @page {
              size: ${dimensions.width}mm ${dimensions.height}mm;
              margin: 0;
            }
          }`,
    content: () => ticketRef?.current,
    documentTitle: 'Facture ' + inv?.reference,
    // onBeforeGetContent: () => setHideButton(true),
    // onAfterPrint: () => setHideButton(true)
  });

  useEffect(() => {
    var elem = document.getElementById("invoiceTicket");
    if(elem) {
    var rect = elem.getBoundingClientRect();
    setDimensions(prev => {
        return {
            "height": rect.width * 0.4645833,
            "width": 90,
        }
    })
    }
  }, []);
  return (
    <div>
     {invoice && <div className="row">
        <div className="col-md-1 col-2"></div>
        <div className="col-md-10 col-8 text-center">
          <div className="d-flex">
            <div className="m-auto" style={{width: '80%'}}>
              <div className="mb-3 border-md-3 border p-3" ref={ticketRef} id='invoiceTicket'>
                <div className="row">
                  <div className="col-12">
                    <div>
                      <img
                        src="/assets/images/logo-dark.webp"
                        alt=""
                        height="70"
                        className=" mx-auto"
                      /> <br />
                      <p className="">INSTITUT DE BIEN - ETRE - COIFFURES - SOINS CORPORELS - MASSAGE - ESPACE DETENTE</p>
                      <strong>RCCM</strong>: RC/DLN/2021/B/652 {" "} <strong>NIU</strong>: M022118505410B 
                      
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="">
                      {
                        <p className="mb-1 text-center">
                            <strong>N Facture: </strong> {Object.keys(invoice)[0]} {" "} <strong>Date:</strong> {moment(invoice[Object.keys(invoice)[0]][0]?.date).format('DD/MM/YYYY')}
                        </p>
                      }
                    </div>
                  </div>
                  <div className="col-12  text-center">
                    <div>
                      {/* <p className="mb-1 text-center">
                        <strong>Code Facture: </strong> {inv?.reference}
                      </p> */}
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} />
                    <div className='teble-responsive'>
                        
                        <table className="table align-middle table-hover table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="text-uppercase">Désignation</th>
                      <th className="text-uppercase">Qté</th>
                      <th className="text-uppercase">CMUP</th>
                      {
                        isSelledHistory ? <>
                        <th className="text-uppercase">P.V</th>
                        <th className="text-uppercase">Marge</th>
                        </> :
                        <th className="text-uppercase">Date D'ajout</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                  {
                            invoice[Object.keys(invoice)[0]]?.map((item) => (
                                <tr>
                                    <td>{item?.product?.title}</td>
                                    <td>{item?.quantity}</td>
                                    <td>{Number(item?.buy_price).toFixed(2)}</td>
                                    {
                                        isSelledHistory ? <>
                                        <td>{formatNumber(item?.sell_price)}</td>
                                        <td>{formatNumber(Number(item?.sell_price - item?.buy_price).toFixed(2))}</td>
                                        </>
                                        : 
                                        <td>
                                            {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                                            <span className="ms-3">{moment(item.created_at).format('HH:MM')}</span>{' '}
                                        </td>
                                    }
                                </tr>
                            ))
                        }
                  </tbody>
                  </table>
                    </div>
                    <hr style={{borderTop: "dotted 2px"}} />
                    <div>
                        <Barcode height={50} value={Object.keys(invoice)[0]} width={1} />
                    </div>
                    <div className="d-flex flex-column">
                      <div className="ms-auto">
                        <span className="small">Imprimé le: {moment().format("DD/MM/YYYY HH:MM")}</span>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          <div>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={handlePrint}
            >
              {' '}
              Imprimer la Facture
            </button>
          </div>
        </div>
        <div className="col-md-2 col-2"></div>
      </div>}
    </div>
  );
};

export default SupplyTicket;
