import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewInvoiceForm from './components/NewInvoiceForm';
import InvoiceTicket from './components/InnvoiceTicket';
import Select from 'react-select';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import PageTitle from '../../components/PageTitle';
import LoaderPage from '../../components/LoaderPage';
import PaginationComponent from '../../components/Pagination';
import { formatNumber } from '../../components/utils/functions';
import { API } from '../../services/axios';

const PendingInvoices = ({ type }) => {
  const [search, setSearch] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [searchCustomers, setSearchCustomers] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const { data: customers, isLoading: loadingCustomers } = useSWR(
    URLS.SEARCH.customers(active_agency?.id, searchCustomers, 1, '')
  );

  const {
    data: invoices,
    isLoading,
    mutate,
  } = useSWR(
    URLS.SEARCH.invoices(
      active_agency?.id,
      search,
      page,
      `&status=PENDING&payments_contains=${paymentMethod}&customer_id=${
        selectedCustomer?.value ?? ''
      }`
    )
  );

  const { t } = useTranslation();

  const handleClose = () => {
    setToEdit();
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    API.get(URLS.INVOICES.details(obj.id))
      .then((res) => {
        setToEdit(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePage = (pg) => {
    // event.preventDefault();
    setPage(pg);
    // alert(pg)
  };

  useEffect(() => {
    window?.$(".new-product-modal2").on("hidden.bs.modal", () => {
      // put your default event here
      // setToEdit();
    });
  }, [toEdit]);

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Factures en Attente'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Factures en attente
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-12">
        <div className="card mb-0">
          <div className="card-body">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">Factures en Attente </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <div className="">
                    <div className="row gy-2 gx-3 align-items-center">
                      <div className="col">
                        <div>
                          {/* <label htmlFor="autoSizingInput">Rechercher</label> */}
                          <div className="form search-form">
                            <i className="fa fa-search"></i>
                            <input
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              class="form-control form-input py-2"
                              placeholder="Rechercher"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div>
                          {/* <label htmlFor="customers">Client</label> */}
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder="Client"
                            isLoading={isLoading}
                            value={selectedCustomer}
                            isClearable={true}
                            inputValue={searchCustomers}
                            isSearchable={true}
                            onChange={(newValue) => setSelectedCustomer(newValue)}
                            onInputChange={(value) => setSearchCustomers(value)}
                            name="customers"
                            options={customers?.results?.map((customer) => {
                              return {
                                value: customer?.id,
                                label: customer?.full_name,
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-auto">
                        <div className="">
                          <div>
                            <ExportExcel
                              label={'Exporter'}
                              link={URLS.INVOICES.export(
                                active_agency?.id,
                                search,
                                page,
                                `&status=PENDING&payment_method=${paymentMethod}&order__customer__id=${
                                  selectedCustomer?.value ?? ''
                                }`
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div></div>
              <div>
                <div
                  className="modal fade new-product-modal2"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit ? `Facture ${toEdit?.reference}` : 'Nouvelle facture'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewInvoiceForm
                          type={type === 'SERVICES' ? 'SERVICE' : 'PRODUCT'}
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className='mb-0'/>
          <div>
            {
              isLoading ? <LoaderPage />
              :
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0 table-hover">
                {/* <table className="table align-middle table-nowrap mb-0 table-bordered table-hover"> */}
                  <thead className="">
                    <tr>
                      <th>Référence</th>
                      <th>Nom du client</th>
                      {/* <th>Désignation</th> */}
                      <th>Montant</th>
                      {/* <th>Mode de Règlement</th> */}
                      {/* <th>Statut</th> */}
                      <th>Désignation</th>
                      <th>Date de création</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.results?.map((row) => (
                      <tr
                        key={'tr-' + row.id}
                        className="appointment-line"
                        onClick={() => handleEdit(row)}
                        title="Modifier"
                        data-bs-toggle="modal"
                        data-bs-target=".new-product-modal2"
                      >
                        <td>#{row.reference ?? ''}</td>
                        <td>
                          {row.customer}
                        </td>
                        <td>{formatNumber(row.amount) + ' FCFA' ?? '0'}</td>
                        {/* <td>
                          {row?.payments?.map(
                            (pm, idx) =>
                              String(pm.payment_method).replace('_', ' ') +
                              (idx + 1 == row?.payments?.length ? '' : ', ')
                          )}
                        </td> */}
                        {/* <td>
                          <span
                            className={`badge badge-soft-${
                              row.status === 'PENDING'
                                ? 'warning'
                                : row.status === 'COMPLETED'
                                ? 'success'
                                : 'danger'
                            } p-2`}
                          >
                            {row.status === 'PENDING'
                              ? 'En attente'
                              : row.status === 'COMPLETED'
                              ? 'Payée'
                              : 'Annulée'}
                          </span>
                        </td> */}
                        <td>
                          {row?.order}
                        </td>
                        <td>{moment(row.created_at).format('DD/MM/YYYY HH:MM')}</td>
                        {/* <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-light btn-sm dropdown"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-vertical align-middle font-size-16"></i>
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target=".details-invoice-modal"
                                    onClick={() => setSelectedInvoice(row)}
                                  >
                                    <i className="mdi mdi-cloud-print-outline font-size-16 align-middle me-2 text-muted"></i>
                                    Imprimer
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to={`/invoices/${row.id}`}
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <i className="mdi mdi-eye-outline font-size-16 align-middle me-2 text-muted"></i>
                                    Afficher
                                  </Link>
                                </li>
                                {row?.status === "PENDING" && <li>
                                  <button
                                    onClick={() => handleEdit(row)}
                                    title="Modifier"
                                    data-bs-toggle="modal"
                                    data-bs-target=".new-product-modal2"
                                    className="dropdown-item"
                                    href="javascript:void(0);"
                                  >
                                    <i className="mdi mdi-pencil-outline font-size-16 align-middle me-2 text-muted"></i>
                                    Modifier
                                  </button>
                                </li>}
                              </ul>
                            </div>
                          </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
            <div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="ms-auto">
        <div className="pt-3">
            {invoices?.count > 0 && (
              <PaginationComponent
                currentPage={page}
                handlePageChange={handleChangePage}
                totalPages={Math.ceil(invoices.count / DEFAULT_PAGE_COUNT) ?? 0}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + toEdit?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={toEdit} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingInvoices;
