import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { API } from '../../../services/axios';
import CLoader from '../../../components/Loader';
import { URLS } from '../../../services/urls';
import { MOBILE_PAYMENT_METHODS } from '../../../components/utils/consts';
import _ from "lodash";

const ManageMobileCheckoutForm = ({ toEdit, onSuccess, handleClose, wallets }) => {
  const [afterAction, setAfterAction] = useState('');
  const [loading, setLoading] = useState('');

  const { auth } = useSelector((state) => state.auth);

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data) => {
    let toSend = {
      mobile: data.method,
      agency: active_agency?.id,
      amount: data.initiaSold,
    };
    

    if (toEdit) {
        setLoading(true);
      API.patch(URLS.WALLET.MOBILE.update((toEdit.id)), toSend)
        .then((resp) => {
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('La caisse a bien été modifiée');
          setLoading(false);
          reset();
          if (handleClose) {
            handleClose();
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
            error?.response?.data ??
              "Une erreur s'est produite lors de l'ajout de la caisse, veuiller reésayer !"
          );
          setLoading(false);
        });
    } else {
      setLoading(true);
      API.post(URLS.WALLET.MOBILE.create, toSend)
        .then((resp) => {
          reset();
          if (onSuccess) {
            onSuccess(resp.data);
          }
          toast.success('La caisse mobile a bien été rajoutée');
          setLoading(false);
          if (handleClose) {
            handleClose();
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.detail ??
            error?.response?.data ??
              "Une erreur s'est produite lors de l'ajout de la caisse, veuiller reésayer !"
          );
          setLoading(false);
        });
    }
  };

  const handleSetAfterAction = (actionn) => {
    setAfterAction(actionn);

    window.$('#submitCustomerForm')?.click();
  };

  useEffect(() => {
    if(toEdit){
        reset({
            name: toEdit.name,
            email: toEdit.email,
            phoneNumber: toEdit.phone_number,
            address: toEdit.address,
        })
    }
  }, [toEdit]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingFirstnameInput">Nom <span className="text-danger">*</span></label>
              <select className="form-control" {...register("method", {required: true})} id="">
                <option value=""> Choisir </option>
                {
                    MOBILE_PAYMENT_METHODS.map(meth => (
                        <option value={meth} disabled={_.filter(wallets, {mobile: meth})?.length > 0}> {String(meth).replaceAll("_", " ")} </option>
                    ))
                }
              </select>
              {/* <div className="invalid-feedback">
                    Please select a valid state.
                </div> */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="floatingLastnameInput">Solde Initial <span className="text-danger">*</span> </label>
              <input
                type="number"
                className="form-control"
                id="floatingLastnameInput"
                {...register('initiaSold', {required: true})}
                placeholder="1000"
              />
            </div>
          </div>
        </div>
        
        <div className="button-items  d-inline-flex">
          <button hidden className="hidden" id="submitCustomerForm" type="submit"></button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('CLOSE')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'CLOSE' && loading} />{' '}
            </span>
          </button>
          {/* <button
            type="button"
            onClick={() => handleSetAfterAction('DETAILS')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et afficher les détails</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'DETAILS' && loading} />{' '}
            </span>
          </button>
          <button
            type="button"
            onClick={() => handleSetAfterAction('ADD')}
            className="btn btn-primary w-md waves-effect waves-light d-flex"
          >
            <span className="my-auto">Soumettre et en ajouter un autre</span>{' '}
            <span className="ms-2 d-inline-flex">
              {' '}
              <CLoader size={20} show={afterAction === 'ADD' && loading} />{' '}
            </span>
          </button> */}
        </div>
      </form>
    </div>
  );
};

export default ManageMobileCheckoutForm;
