import { API } from "../../services/axios"
import { URLS } from "../../services/urls"
import { loadingFailed, loadingRequest, loadingSuccess, updateAgencyWallet } from "../slices/agencies"






export const loadAgencies = () => async (dispatch) =>{
    dispatch(loadingRequest(true))

    API.get(URLS.AGENCIES.list("", 1)).then( response => {

        const {data } = response;
        dispatch(
            loadingSuccess(data?.results)
        )
    }).catch((error) => {
        dispatch(loadingFailed())
    })
}


export const loadAgency = (agencyId) => async (dispatch) =>{
    dispatch(loadingRequest(true))

    API.get(URLS.AGENCIES.details(agencyId)).then( response => {

        const {data} = response;
        dispatch(
            updateAgencyWallet(data)
        )
    }).catch((error) => {
        dispatch(loadingFailed())
    })
}
