import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import { useSelector } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import { getPaymentMethodIcon } from '../../components/utils/functions';
import Select from 'react-select';

const getUrl = (type, isAll, agency) => {

    switch (type) {
        case "TODAY":
            if(isAll){
                return URLS.STATS.CA.AGENCIES.today("9999-01-01", "9999-01-01" )
            } else {
                return URLS.STATS.CA.AGENCY.today(agency, "9999-01-01", "9999-01-01" )
            }
        case "WEEKLY":
            if(isAll){
                return URLS.STATS.CA.AGENCIES.weekly("9999-01-01", "9999-01-01" )
            } else {
                return URLS.STATS.CA.AGENCY.weekly(agency, "9999-01-01", "9999-01-01" )
            }
        case "MONTHLY":
            if(isAll){
                return URLS.STATS.CA.AGENCIES.monthly("9999-01-01", "9999-01-01" )
            } else {
                return URLS.STATS.CA.AGENCY.monthly(agency, "9999-01-01", "9999-01-01" )
            }
        default:
            if(isAll){
                return URLS.STATS.CA.AGENCIES.yearly("9999-01-01", "9999-01-01" )
            } else {
                return URLS.STATS.CA.AGENCY.yearly(agency, "9999-01-01", "9999-01-01" )
            }
    }

}

const isNogLabel = (label) => {

    if(label === "services_ca" || label === "products_ca" || label === "ca" || label === "payment_methods" || label === "commission" || label === "series" || label === "payment_status"){
        return false
    } else {
        return true;
    }
}

function randomColor() {
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    console.log(randomColor)
    return String(randomColor);
  }

const opts = {
    chart: {
        type: "bar",
        height: 350,
        stacked: !0,
        toolbar: {
            show: !1
        },
        zoom: {
            enabled: !0
        }
    },
    plotOptions: {
        bar: {
            horizontal: !1,
            columnWidth: "42%"
        }
    },
    dataLabels: {
        enabled: !1
    },
    legend: {
        show: !1
    },
    xaxis: {
        categories: ["NOT_DEFINED", "OM", "MOMO", "PG", "VISA_CARD", "SOLDE_DISPONIBLE", "CASH", "OFFERT"]
    },
    colors: ["#0c768a", "#daeaee"],
    fill: {
        opacity: 1
    },
    
        stroke: {
            curve: 'smooth',
          }
};

const doughnutOpts = {
    labels: ["En cours", "Complete", "Annule"],
    chart: {
        type: "donut",
        height: 350
    },
    plotOptions: {
        pie: {
            size: 100,
            offsetX: 0,
            offsetY: 0,
            donut: {
                size: "77%",
                labels: {
                    show: !0,
                    name: {
                        show: !0,
                        fontSize: "18px",
                        offsetY: -5
                    },
                    value: {
                        show: !0,
                        fontSize: "24px",
                        color: "#343a40",
                        fontWeight: 500,
                        offsetY: 10,
                        formatter: function(e) {
                            return e + " FCFA"
                        }
                    },
                    total: {
                        show: !0,
                        fontSize: "16px",
                        label: "Total value",
                        color: "#9599ad",
                        fontWeight: 400,
                        formatter: function(e) {
                            return e.globals.seriesTotals.reduce(function(e, o) {
                                return e + o
                            }, 0) + " FCFA"
                        }
                    }
                }
            }
        }
    },
    dataLabels: {
        enabled: !1
    },
    legend: {
        show: !0,
        position: "bottom"
    },
    yaxis: {
        labels: {
            formatter: function(e) {
                return "$" + e
            }
        }
    },
    stroke: {
        lineCap: "round",
        width: 2
    },
    colors: ["#0c768a", "#38c786", "#ed5e49"]
};

const Dashboard = () => {

    const [caType, setCaType] = useState("MONTHLY");
    const [searchProducts, setSearchProducts] = useState("");
    const [selectedProduct, setSelectedProduct] = useState({label: "Tous les produits", value: "ALL"});
    const [page, setPage] = useState(1);

    // For payment status
    const [doughnutSeries, setDoughnutSeries] = useState([]);
    const [doughnutOptions, setDoughnutOptions] = useState(doughnutOpts);
    // For payment methods
    const [doughnutSeriespm, setDoughnutSeriespm] = useState([]);
    const [doughnutOptionspm, setDoughnutOptionspm] = useState(doughnutOpts);
    // For products
    const [series, setSeries] = useState([]);
    const [options, setOptions] = useState(opts);

    const [showAllAgencies, setShowAllAgencies] = useState(false);
    const { active_agency, agencies } = useSelector((state) => state.agencies);


    const hasRigths = true;

    const { data: ca, isLoading: loadingCa } = useSWR(
        getUrl(caType, showAllAgencies, active_agency?.id ?? "")
    );

    const { data: checkouts, isLoading: loadingCheckouts } = useSWR(
        showAllAgencies ?  URLS.WALLET.allTrannsactions("", 1, `&sortie_caisse=true`) : URLS.WALLET.trannsactions(active_agency?.id, "", 1, `&sortie_caisse=true`)
    );

    const { data: transactions, isLoading: loadingTransactions } = useSWR(
        showAllAgencies ?  URLS.WALLET.allTrannsactions("", 1, ``) : URLS.WALLET.trannsactions(active_agency?.id, "", 1, ``)
    );

    const { data: products, isLoading } = useSWR(URLS.PRODUCTS.list(active_agency?.id, searchProducts, 1, "ALL"));


    // const doughnutSeries = [40405, 15552, 19824];

    
    const handleChangeChartView = (productLabel) => {}
    useEffect(() => {
        if (ca) {
            if(selectedProduct && selectedProduct?.value) {

                if(selectedProduct.value === "ALL"){
                    let labels = [];
                    let series = [
                        {
                            name: "Chiffre d'affaires Global",
                            data: []
                        },
                    ];
                    Object.keys(ca).forEach(function(key, value) {
                        if(isNogLabel(key)) {
                            labels.concat(key);
                            const products = ca[key]?.products;
                            for (let [k, v] of Object.entries(products)) {
        
                                if (k ==="total"){
                                    if(_.filter(series, {name: k}).length > 0){
            
                                    }
                                    series = series.map((serie, index) => {
                                        // if (serie.name === k){
        
                                        // }
                                        console.log(v, index)
                                        if (serie){
                                            serie.data = serie.data.concat(v);
                                        }
                                        return serie;
                                    })
                                }
                            }
                        }
                    })
                    setSeries(series);
                    setOptions(prev => {return {...prev, xaxis: {categories: labels}}})
                } else {
                    let labels = [];
                    let series = [
                        {
                            name: selectedProduct.label,
                            data: []
                        },
                    ];
                    Object.keys(ca).forEach(function(key, value) {
                        if(isNogLabel(key)) {
                            labels = labels.concat(key);
                            const products = ca[key]?.products;
                            for (let [k, v] of Object.entries(products)) {
        
                                if (k === selectedProduct.label){
                                    if(_.filter(series, {name: k}).length > 0){
            
                                    }
                                    series = series.map((serie, index) => {
                                        // if (serie.name === k){
        
                                        // }
                                        console.log(v, index)
                                        if (serie){
                                            serie.data = serie.data.concat(v);
                                        }
                                        return serie;
                                    })
                                }
                            }
                        }
                    });
                    console.log(series);
                    setSeries(series);
                    setOptions(prev => {return {...prev, xaxis: {categories: labels}}})
                }
            }
            // Payment status series data
            let labels2 = [];
            let series2 = [];
            Object.keys(ca).forEach(function(key, value) {
                if(isNogLabel(key)) {
                    labels2.concat(key);
                    const paymentsStatus = ca[key]?.payment_status;
                    for (let [k, v] of Object.entries(paymentsStatus)) {

                        if (_.filter(series2, {name: k}).length > 0){
                            const lastValue = _.filter(series2, {name: k})[0].value

                            series2 = series2.map((serie) => {
                                if (serie.name === k) {
                                    serie.value = lastValue + v;
                                }
                                return serie
                            })
                        } else {
                            series2 = series2.concat(({"name": k, "value": v}))
                        }
                    }
                }
            })
            console.log("series2", series2)
            setDoughnutOptions((prev) => {
                return {
                    ...prev,
                    labels: series2.map(serie => String(serie.name).replaceAll("_", " "))
                }
            })
            setDoughnutSeries(series2.map(serie => serie.value));

            // Pour les methodes de paiement
            // Payment status series data
            let labels3 = [];
            let series3 = [];
            Object.keys(ca).forEach(function(key, value) {
                if(isNogLabel(key)) {
                    labels2.concat(key);
                    const paymentMethods = ca[key]?.payment_methods;
                    for (let [k, v] of Object.entries(paymentMethods)) {

                        if (_.filter(series3, {name: k}).length > 0){
                            const lastValue = _.filter(series3, {name: k})[0].value

                            series3 = series3.map((serie) => {
                                if (serie.name === k) {
                                    serie.value = lastValue + v;
                                }
                                return serie
                            })
                        } else {
                            series3 = series3.concat(({"name": k, "value": v}))
                        }
                    }
                }
            })
            console.log("series3", series3)
            setDoughnutOptionspm((prev) => {
                return {
                    ...prev,
                    labels: series3.map(serie => String(serie.name).replaceAll("_", " ")),
                    colors: series3.map(serie => String(randomColor()))
                }
            })
            setDoughnutSeriespm(series3.map(serie => serie.value))
            console.log("series2.map(serie => serie.value", [series3.map(serie => randomColor())])
        }
    }, [ca, selectedProduct]);
    
  return (
    <>
      <div className="row">
        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="avatar-md flex-shrink-0">
                  <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                    <span className="uim-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                        <path
                          className="uim-primary"
                          d="M10,6V5h4V6h2V5a2.00229,2.00229,0,0,0-2-2H10A2.00229,2.00229,0,0,0,8,5V6Z"
                        ></path>
                        <path
                          className="uim-quaternary"
                          d="M9 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 9 15zM15 15a.99974.99974 0 0 1-1-1V12a1 1 0 0 1 2 0v2A.99974.99974 0 0 1 15 15z"
                        ></path>
                        <path
                          className="uim-tertiary"
                          d="M20,6H4A2,2,0,0,0,2,8v3a2,2,0,0,0,2,2H8V12a1,1,0,0,1,2,0v1h4V12a1,1,0,0,1,2,0v1h4a2,2,0,0,0,2-2V8A2,2,0,0,0,20,6Z"
                        ></path>
                        <path
                          className="uim-primary"
                          d="M20,13H16v1a1,1,0,0,1-2,0V13H10v1a1,1,0,0,1-2,0V13H4a2,2,0,0,1-2-2v8a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V11A2,2,0,0,1,20,13Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden ms-4">
                  <p className="text-muted text-truncate font-size-15 mb-2">Chiffre d'affaires</p>
                  <h3 className="fs-4 flex-grow-1 mb-3">
                    {ca?.ca} <span className="text-muted font-size-16">FCFA</span>
                  </h3>
                  {/* <p className="text-muted mb-0 text-truncate">
                    <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                      <i className="mdi mdi-arrow-top-right"></i> 2.8% Increase
                    </span>{' '}
                    vs last month
                  </p> */}
                </div>
                <div className="flex-shrink-0 align-self-start">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle btn-icon border rounded-circle"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-more-2-fill text-muted font-size-16"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end card-ca">
                      <Link className={`dropdown-item ${caType === "TODAY" ? 'active' : ''}`} to="#" onClick={() => setCaType("TODAY")}>
                        Aujourd'hui
                      </Link>
                      <Link className={`dropdown-item ${caType === "WEEKLY" ? 'active' : ''}`} to="#" onClick={() => setCaType("WEEKLY")}>
                        Semaine
                      </Link>
                      <Link className={`dropdown-item ${caType === "MONTHLY" ? 'active' : ''}`} to="#" onClick={() => setCaType("MONTHLY")}>
                        Mois
                      </Link>
                      <Link className={`dropdown-item ${caType === "YEARLY" ? 'active' : ''}`} to="#" onClick={() => setCaType("YEARLY")}>
                        Annee
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="avatar-md flex-shrink-0">
                  <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                    <span className="uim-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                        <path
                          className="uim-quaternary"
                          d="M12,14.19531a1.00211,1.00211,0,0,1-.5-.13379l-9-5.19726a1.00032,1.00032,0,0,1,0-1.73242l9-5.19336a1.00435,1.00435,0,0,1,1,0l9,5.19336a1.00032,1.00032,0,0,1,0,1.73242l-9,5.19726A1.00211,1.00211,0,0,1,12,14.19531Z"
                        ></path>
                        <path
                          className="uim-tertiary"
                          d="M21.5,11.13184,19.53589,9.99847,12.5,14.06152a1.0012,1.0012,0,0,1-1,0L4.46411,9.99847,2.5,11.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                        ></path>
                        <path
                          className="uim-primary"
                          d="M21.5,15.13184l-1.96411-1.13337L12.5,18.06152a1.0012,1.0012,0,0,1-1,0L4.46411,13.99847,2.5,15.13184a1.00032,1.00032,0,0,0,0,1.73242l9,5.19726a1.0012,1.0012,0,0,0,1,0l9-5.19726a1.00032,1.00032,0,0,0,0-1.73242Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden ms-4">
                  <p className="text-muted text-truncate font-size-15 mb-2"> Solde de caisse</p>
                  <h3 className="fs-4 flex-grow-1 mb-3">
                    {_?.filter(agencies, {"id": active_agency?.id})[0]?.wallet?.amount} <span className="text-muted font-size-16">FCFA</span>
                  </h3>
                  {/* <p className="text-muted mb-0 text-truncate">
                    <span className="badge bg-subtle-danger text-danger font-size-12 fw-normal me-1">
                      <i className="mdi mdi-arrow-bottom-left"></i> 7.8% Loss
                    </span>{' '}
                    vs last month
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="avatar-md flex-shrink-0">
                  <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                    <span className="uim-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                        <path
                          className="uim-quinary"
                          d="M13.5,9A1.5,1.5,0,1,1,15,7.5,1.50164,1.50164,0,0,1,13.5,9Z"
                        ></path>
                        <path
                          className="uim-tertiary"
                          d="M19,2H5A3.00879,3.00879,0,0,0,2,5v8.86L5.88,9.98a3.07531,3.07531,0,0,1,4.24,0l2.87139,2.887.88752-.88751a3.00846,3.00846,0,0,1,4.24218,0L22,15.8584V5A3.00879,3.00879,0,0,0,19,2ZM13.5,9A1.5,1.5,0,1,1,15,7.5,1.50164,1.50164,0,0,1,13.5,9Z"
                        ></path>
                        <path
                          className="uim-primary"
                          d="M10.12,9.98a3.07531,3.07531,0,0,0-4.24,0L2,13.86V19a3.00882,3.00882,0,0,0,3,3H19a2.9986,2.9986,0,0,0,2.16-.92Z"
                        ></path>
                        <path
                          className="uim-quaternary"
                          d="M22,15.8584l-3.87891-3.87891a3.00849,3.00849,0,0,0-4.24218,0l-.88752.88751,8.16425,8.20856A2.96485,2.96485,0,0,0,22,19Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden ms-4">
                  <p className="text-muted text-truncate font-size-15 mb-2">Global de produits</p>
                  <h3 className="fs-4 flex-grow-1 mb-3">
                    {ca?.products_ca} <span className="text-muted font-size-16">FCFA</span>
                  </h3>
                  {/* <p className="text-muted mb-0 text-truncate">
                    <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                      <i className="mdi mdi-arrow-top-right"></i> 4.6% Growth
                    </span>{' '}
                    vs last month
                  </p> */}
                </div>
                {/* <div className="flex-shrink-0 align-self-start">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle btn-icon border rounded-circle"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-more-2-fill text-muted font-size-16"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">
                        Yearly
                      </a>
                      <a className="dropdown-item" href="#">
                        Monthly
                      </a>
                      <a className="dropdown-item" href="#">
                        Weekly
                      </a>
                      <a className="dropdown-item" href="#">
                        Today
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="avatar-md flex-shrink-0">
                  <span className="avatar-title bg-subtle-primary text-primary rounded fs-2">
                    <span className="uim-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em">
                        <rect width="20" height="15" x="2" y="3" className="uim-tertiary" rx="3"></rect>
                        <path
                          className="uim-primary"
                          d="M16,21H8a.99992.99992,0,0,1-.832-1.55469l4-6a1.03785,1.03785,0,0,1,1.66406,0l4,6A.99992.99992,0,0,1,16,21Z"
                        ></path>
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden ms-4">
                  <p className="text-muted text-truncate font-size-15 mb-2"> Global des prestations</p>
                  <h3 className="fs-4 flex-grow-1 mb-3">
                    {ca?.services_ca} <span className="text-muted font-size-16">FCFA</span>
                  </h3>
                  {/* <p className="text-muted mb-0 text-truncate">
                    <span className="badge bg-subtle-success text-success font-size-12 fw-normal me-1">
                      <i className="mdi mdi-arrow-top-right"></i> 23% Increase
                    </span>{' '}
                    vs last month
                  </p> */}
                </div>
                {/* <div className="flex-shrink-0 align-self-start">
                  <div className="dropdown">
                    <a
                      className="dropdown-toggle btn-icon border rounded-circle"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="ri-more-2-fill text-muted font-size-16"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">
                        Yearly
                      </a>
                      <a className="dropdown-item" href="#">
                        Monthly
                      </a>
                      <a className="dropdown-item" href="#">
                        Weekly
                      </a>
                      <a className="dropdown-item" href="#">
                        Today
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8">
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex pb-0">
              <h3 className="card-titl mb-0 flex-grow-1">Ventes journaliere</h3>
              <div>
                <Select
                  className="basic-single-product"
                  classNamePrefix="select"
                  isLoading={isLoading}
                  value={selectedProduct}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(newValue) => setSelectedProduct(newValue)}
                  onInputChange={(value) => setSearchProducts(value)}
                  
                  name="customer"
                  options={[{label: "Tous les produits", value: "ALL"}].concat(products?.results?.map((product) => {
                    return { value: product.id, label: `${product?.title}` };
                  }))}
                />
              </div>
            </div>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-xl-12 audiences-border">
                    
                    <div>
                        <Chart
                            options={options}
                            series={series}
                            type="line"
                        />
                    </div>
                </div>
                {/* <div className="col-xl-4">
                    
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex pb-0">
              <h3 className="card-title mb-0 flex-grow-1">Dernieres Transactions</h3>
              <div>
                {/* <div className="dropdown">
                  <a
                    className="dropdown-toggle text-reset"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span className="fw-semibold">Sort By:</span>
                    <span className="text-muted">
                      Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                      Yearly
                    </a>
                    <a className="dropdown-item" href="#">
                      Monthly
                    </a>
                    <a className="dropdown-item" href="#">
                      Weekly
                    </a>
                    <a className="dropdown-item" href="#">
                      Today
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card-body pt-2">
            <div className="">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheck1"
                              />
                              <label className="form-check-label" for="customCheck1">
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>ID</th>
                          <th>Type</th>
                          <th>Montant</th>
                          <th>Methode de paiement</th>
                          <th>Motif</th>
                          <th>Bénéficiaire</th>
                          {/* <th>Mode de paiement</th> */}
                          <th>Date</th>
                          <th>Statut</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {transactions?.results?.map((transfert) => (
                          <tr key={'trsc-' + transfert.id}>
                            <td>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheck2"
                                />
                                <label className="form-check-label" for="customCheck2">
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>
                              <a href="javascript: void(0);" className="text-body">
                                #{transfert.reference}
                              </a>{' '}
                            </td>
                            <td>
                              {transfert?.type === 'DEPOSIT' ? (
                                <span className="badge rounded badge-soft-success font-size-12">
                                  Entrée
                                </span>
                              ) : (
                                <span className="badge rounded badge-soft-danger font-size-12">
                                  Sortie
                                </span>
                              )}
                            </td>
                            <td>
                              <p className="mb-0"> 
                                {transfert?.amount + parseFloat(transfert.trannsport_amount ?? '0')}{' '}
                                FCFA
                              </p>
                            </td>
                            <td>
                              <p className="mb-0">
                                {getPaymentMethodIcon(transfert?.payment_method)}
                              </p>
                            </td>
                            <td>{String(transfert.reason).replaceAll('_', ' ')}</td>
                            <td>
                              {transfert.transfert_to_secured_box
                                ? 'Coffre'
                                : transfert.type === 'DEPOSIT'
                                ? transfert?.agency?.name || 'Agence'
                                : transfert.supplier
                                ? transfert.supplier?.name
                                : transfert?.beneficiary ? `${transfert?.beneficiary?.last_name} ${transfert.beneficiary?.last_name}`
                            : "Agence"
                            // `${transfert?.wallet?.customer?.last_name} ${transfert?.wallet?.customer?.last_name}`
                            }
                            </td>
                            {/* <td>{transfert.payment_method}</td> */}
                            <td>{moment(transfert?.created_at).format('lll')}</td>
                            {/* <td>
                            <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                          </td> */}
                            <td>
                              <span className="badge rounded badge-soft-success font-size-12">
                                Complété
                              </span>
                            </td>
                            {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
        </div>

        <div className="col-xl-4">
            <div className="card">
                <div className="card-body">
                    <div>
                        <h3>Statuts de paiements</h3>
                    </div>
                <div>
                        <Chart
                            options={doughnutOptions}
                            series={doughnutSeries}
                            type='donut'
                            height={300}
                        />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div>
                        <h3>Modes de règlement</h3>
                    </div>
                <div>
                        <Chart
                            options={doughnutOptionspm}
                            series={doughnutSeriespm}
                            type='donut'
                            height={300}
                        />
                    </div>
                </div>
            </div>
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex pb-1">
              <h4 className="card-title mb-0 flex-grow-1">Sorties de caisse</h4>
              <div>
                {/* <button type="button" className="btn btn-soft-primary btn-sm">
                  Export Report
                </button> */}
              </div>
            </div>
            <div className="card-body">
            <div className="">
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: '20px' }}>
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheck1"
                              />
                              <label className="form-check-label" for="customCheck1">
                                &nbsp;
                              </label>
                            </div>
                          </th>
                          <th>ID</th>
                          <th>Type</th>
                          <th>Montant</th>
                          <th>Motif</th>
                          <th>Bénéficiaire</th>
                          {/* <th>Mode de paiement</th> */}
                          <th>Date</th>
                          <th>Statut</th>
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {checkouts?.results?.map((transfert) => (
                          <tr key={'trsc-' + transfert.id}>
                            <td>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customCheck2"
                                />
                                <label className="form-check-label" for="customCheck2">
                                  &nbsp;
                                </label>
                              </div>
                            </td>
                            <td>
                              <a href="javascript: void(0);" className="text-body">
                                #{transfert.reference}
                              </a>{' '}
                            </td>
                            <td>
                              {transfert?.type === 'DEPOSIT' ? (
                                <span className="badge rounded badge-soft-success font-size-12">
                                  Entrée
                                </span>
                              ) : (
                                <span className="badge rounded badge-soft-danger font-size-12">
                                  Sortie
                                </span>
                              )}
                            </td>
                            <td>
                              <p className="mb-0">
                                {transfert?.amount + parseFloat(transfert.trannsport_amount ?? '0')}{' '}
                                FCFA
                              </p>
                            </td>
                            <td>{String(transfert.reason).replaceAll('_', ' ')}</td>
                            <td>
                              {transfert.transfert_to_secured_box
                                ? 'Coffre'
                                : transfert.type === 'DEPOSIT'
                                ? transfert?.agency?.name || 'Agence'
                                : transfert.supplier
                                ? transfert.supplier?.name
                                : `${transfert?.beneficiary?.last_name} ${transfert.beneficiary?.last_name}`}
                            </td>
                            {/* <td>{transfert.payment_method}</td> */}
                            <td>{moment(transfert?.created_at).format('lll')}</td>
                            {/* <td>
                            <i className="fab fa-cc-mastercard me-1"></i> Mastercard
                          </td> */}
                            <td>
                              <span className="badge rounded badge-soft-success font-size-12">
                                Complété
                              </span>
                            </td>
                            {/* <td>
                            <div className="d-flex gap-3">
                              <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                <i className="mdi mdi-pencil"></i>
                              </a>
                              <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                <i className="mdi mdi-delete"></i>
                              </a>
                            </div>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Dashboard;
