import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import Select from 'react-select';
import moment from 'moment';
import { URLS } from '../../../services/urls';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { TYPES_OF_BUY, TYPES_OF_BUY_COFFRE } from '../../../components/utils/consts';
import { API } from '../../../services/axios';
import CLoader from '../../../components/Loader';

const expenseType = [{ label: '', value: '' }];
const securedBoxTypes = ["TRANSFERT_DE_FONDS", "TRANSFERT_ET_DEPLACEMENT"]

const DepositForm = ({ wallet, onSuccess, handleClose, }) => {
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchUsers, setSearchUsers] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [searchSupplier, setSearchSupplier] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState();

  const { auth } = useSelector((state) => state.auth);
  const { active_agency } = useSelector((state) => state.agencies);
  const { data: users, isLoading: loadingUsers } = useSWR(
    URLS.USERS.list(active_agency?.id, searchUsers, 1)
  );
  const { data: suppliers, isLoading: loadingSuppliers } = useSWR(
    URLS.SUPPLIER.list(active_agency?.id, searchSupplier, 1)
  );

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onSumbit = (data) => {

    const toSend = {
        "wallet": wallet?.id,
        "amount": data?.amount,
        "payment_method": data?.paymentMethod,
        "type": "DEPOSIT",
        "sortie_caisse": false,
        "transfert_to_secured_box": false,
        "trannsport_amount": 0.0,
        "reason": "APPROVISIONNEMENT_DE_COMPTE",
    }
    // if(!securedBoxTypes.includes(data?.reason) && data?.isSupplier && !selectedSupplier){
    //     toast.error("Veuiller selectionner un fournisseur");
    // } else if(!securedBoxTypes.includes(data?.reason) && !data?.isSupplier && !selectedUser){
    //     toast.error("Veuiller selectionner un bénéficiaire");
    // } else {
        setLoading(true)
        API.post(URLS.TRANSFERT.create, toSend).then((resp) => {
            toast.success("Le dépôt en compte a bien été éffectué")
            if(onSuccess){
                onSuccess()
            }
            if(handleClose){
                handleClose()
            }
            setSearchSupplier()
            setSearchUsers()
            setSearchSupplier("")
            setSearchUsers("")
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            toast.error(error?.response?.data?.detail ?? "Une erreur s'est produite, veuiller reessayer!")
        })
    // }

  } 
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Approvisionnement du compte client ({wallet?.amount} FCFA)</h4>

          <div className="tab-content text-muted">
            <div className="tab-pane active show" id="home1" role="tabpanel">
              <form onSubmit={handleSubmit(onSumbit)}>
                <div className="row">
                  
                  <div className="col-md-6 col-12">
                    <div className="mb-3">
                      <label htmlFor="#">Montant <span className='text-danger'>*</span></label>
                      <input className="form-control" type="number" {...register("amount", {required: true,})} placeholder="Entrer un Montant" />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="paymentMethod">Mode de reglement <span className='text-danger'>*</span></label>
                    <select className="form-select" id='paymentMethod' {...register("paymentMethod", {required: true})} aria-label="Default select example">
                      <option value="">Choisir</option>
                      {/* <option value="NOT_DEFINED">Non specifie</option> */}
                      <option value="OM">Orange money</option>
                      <option value="MOMO">Mobile money</option>
                      <option value="PG">PG</option>
                      <option value="VISA_CARD">Cart de bancaire</option>
                      {/* <option value="SOLDE_DISPONIBLE">Portfeuille {`(${wallet?.amount} XAF)`  }</option> */}
                      <option value="CASH">Cash</option>
                      <option value="OFFERT">Offrir</option>
                    </select>
                  </div>
                </div>
                  <div className="col-12">
                    <div className="mb-3">
                        <div>
                            <button className="btn-primary btn px-4 waves-effect waves-light d-flex">
                                
                                <span className="my-auto">Valider</span>{' '}
                                <span className="ms-2 d-inline-flex">
                                {' '}
                                <CLoader size={20} show={loading} />{' '}
                                </span>
                            </button>
                        </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="tab-pane" id="profile1" role="tabpanel">
              <p className="mb-0">
                Food truck fixie locavore, accusamus mcsweeney's marfa nulla single-origin coffee
                squid. Exercitation +1 labore velit, blog sartorial PBR leggings next level wes
                anderson artisan four loko farm-to-table craft beer twee. Qui photo booth
                letterpress, commodo enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl
                cillum PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositForm;
