import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading_agencies_request: false,
    loading_agencies_success: false,
    loading_agencies_failed: false,
    agencies: [],
    active_agency: null,
}

export const agencySlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    loadingRequest: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.loading_agencies_request = true;
      state.loading_agencies_success = false;
      state.loading_agencies_failed = false;
    },
    loadingSuccess: (state, action) => {
        state.loading_agencies_request = false;
        state.loading_agencies_success = true;
        state.loading_agencies_failed = false;
        state.agencies = action.payload
    },
    loadingFailed: (state) => {
        state.loading_agencies_request = false;
        state.loading_agencies_success = false;
        state.loading_agencies_failed = true;
    },
    selectAnAgency: (state, action) => {
      state.active_agency = action.payload;
    },
    updateAgencyWallet: (state, action) => {
      state.agencies = state.agencies.map((agency) => {
        if (agency?.id === action?.payload?.id){
          agency.wallet = action?.payload?.wallet;
        }
        return agency
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const { loadingRequest, loadingSuccess, loadingFailed, selectAnAgency, updateAgencyWallet } = agencySlice.actions

export default agencySlice.reducer