import { Outlet } from "react-router-dom"
import Navbar from "../../components/Navbar"
import Sidebar from "../../components/Sidebar"
import PrivateRoute from "../../store/components/PrivateRoute"



const DashboardLayout = () =>{


    return (
        <PrivateRoute>
            <div id="layout-wrapper">
                <Navbar />
                <Sidebar />
                <div className="main-content">
                    <div className="page-content">
                        <Outlet/>
                    </div>
                </div>
            </div>
        </PrivateRoute>
    )
}

export default DashboardLayout